import axios from '../../shared/http';
import { transformObjectFromString } from '../shared/transform';
import { IAgency, ISubscription } from './types';
import { getAgencyIdFromToken } from '../../shared/auth';
import { AxiosResponse } from 'axios';
import { loadImageData } from '../images/actions';

const getMyAgencyLoading = () => ({ type: 'agencies/get-my-agency-loading' });
const getMyAgencySuccess = (payload: any) => ({ type: 'agencies/get-my-agency-success', payload});
const getMyAgencyError = (payload: any) => ({ type: 'agencies/get-my-agency-error', payload });

const getMySubscriptionLoading = () => ({ type: 'agencies/get-my-subscription-loading' });
const getMySubscriptionSuccess = (payload: any) => ({ type: 'agencies/get-my-subscription-success', payload});
const getMySubscriptionError = (payload: any) => ({ type: 'agencies/get-my-subscription-error', payload });

export const getMyAgency = async (dispatch: any) => {

  dispatch(getMyAgencyLoading());

  let agencyId = getAgencyIdFromToken();
  
  if(!agencyId) {
    dispatch(getMyAgencyError("Unable to determine agency ID"));
    return;
  }

  axios.get<IAgency>(
    `${process.env.REACT_APP_AGENCIES_PATH}/${agencyId['value']}`,
    { transformResponse: (data: string) => transformObjectFromString<IAgency>(data) })
    .then((response: AxiosResponse<IAgency>) => {

      loadImageData(dispatch, response.data.logo);

      dispatch(getMyAgencySuccess(response.data));
    })
    .catch((error: any) => {
      // console.log(error);
      dispatch(getMyAgencyError(error));
    });
};


export const getMySubscription = async (dispatch: any) => {

  dispatch(getMySubscriptionLoading());

  let agencyId = getAgencyIdFromToken();
  
  if(!agencyId) {
    dispatch(getMyAgencyError("Unable to determine agency ID"));
    return;
  }

  axios.get<IAgency>(
    `${process.env.REACT_APP_AGENCIES_PATH}/${agencyId['value']}/subscription`,
    { transformResponse: (data: string) => transformObjectFromString<ISubscription>(data) })
    .then((response: AxiosResponse<IAgency>) => {

      dispatch(getMySubscriptionSuccess(response.data));
    })
    .catch((error: any) => {
      // console.log(error);
      dispatch(getMySubscriptionError(error));
    });
};