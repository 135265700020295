import * as React from "react";
import { Trans, useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";
import { getQueryString } from "../../shared/queryString";
import { toggleAddClient } from "../../store/clients/actions";

type Props = {
  example: boolean;
};

export const DashboardClients: React.FC<Props> = ({ example }) => {

  const { t } = useTranslation();

  const dispatch = useDispatch();
  const history = useHistory();
  
  const addClient = () => dispatch(toggleAddClient());
  const goToProjects = () => history.push({ pathname: '/projects', search: getQueryString()});

  return example ? (
    <div className="call-to-action orange-rocket" onClick={addClient}>
      <h2>{t('dashboard.clientHeading')}</h2>
      <h3>{t('dashboard.clientSubHeading')}</h3>
      <p>
        <Trans i18nKey="dashboard.clientBody">
          Click the <strong>+ Client button</strong> to add your first client. One done, your can click <strong>+ Add a Project</strong> and edit your project settings
        </Trans>
      </p>
    </div>
  ) : (
    <div className="call-to-action small orange-rocket" onClick={goToProjects}>
      <h2>{t('dashboard.clientHeadingExisting')}</h2>
      <h3>{t('dashboard.clientSubHeadingExisting')}</h3>
    </div>
  );
};

export default DashboardClients;
