import { Guid } from "guid-typescript";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";
import { getQueryString, setValueInQueryString } from "../../shared/queryString";
import { IProject } from "../../store/projects/types";
import { State } from "../../store/shared/state";

type Props = {
  projects: any[];
  example: boolean;
};

export const DashboardLastProject: React.FC<Props> = ({ projects, example }) => {

  const { t } = useTranslation();

  const history = useHistory();

  const goToProject = (id: Guid) => {
    setValueInQueryString('project', id);
    history.push({ pathname: '/projects', search: getQueryString() });
  }

  const selectedClient = useSelector((state: State) => state.clients.selected);

  return projects?.length > 0 && !example ? (
    <div className="call-to-action small">
      <h2>{t('dashboard.recentProjectsHeading')}</h2>
      
      {projects?.slice(0, 1).map((project: IProject) => (
        <div className="last" key={project.uuid['value']} onClick={() => goToProject(project.uuid)}>
          {selectedClient != null && <div>{selectedClient.name}</div>}
          <strong>{project.name}</strong>
        </div>
      ))}
    </div>
  ) : null;
};

export default DashboardLastProject;
