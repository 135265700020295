import React from "react";
import { useTranslation } from "react-i18next";
import './Footer.scss';
import { ToastContainer } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";

export const Footer: React.FC = () => {

  const { t } = useTranslation();

  return (

    <footer>

      <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        theme="dark"
        draggable
        pauseOnHover
        />
      <section className="container container-xxxl container-footer">
        {t('footer.copyright')} {new Date().getFullYear()} Brandvas <a href="https://brandvas.io">{t('footer.visit')}</a>
      </section>
    </footer>
  );
};
export default Footer;