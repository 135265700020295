import React from "react";
import { Modal } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { useDispatch } from "react-redux";
import { State } from "../../store/shared/state";
import { Status } from "../../store/shared/status";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { editProjectNotes, toggleEditProjectNotes } from "../../store/projects/actions";

type FormData = {
  projectNotes: string;
};

export const EditProjectNotes: React.FC = () => {

  const { t } = useTranslation();
  const { register, handleSubmit, formState: { errors }, reset } = useForm<FormData>();

  const selectedProject = useSelector((state: State) => state.projects.selected);

  const editProjectNotesOpened = useSelector((state: State) => state.projects.editProjectNotesOpened);
  const editProjectNotesStatus = useSelector((state: State) => state.projects.editProjectNotesStatus);
  const editProjectNotesError = useSelector((state: State) => state.projects.editProjectNotesError);

  const defaultValues = { projectNotes: selectedProject?.projectNotes};

  const submitButtonText = editProjectNotesStatus !== Status.LOADING 
    ? ( <span>{t('editProjectNotes.submit')} <i className="fas d-none d-sm-inline fa-chevron-right"></i></span> )
    : ( <span>{t('editProjectNotes.processing')} <i className="fas d-none d-sm-inline fa-spin fa-circle-notch"></i></span> );

  const dispatch = useDispatch();

  const onSubmit = (data: any, errors: any) => {
    editProjectNotes(
      dispatch, 
      selectedProject, 
      data.projectNotes
    );
  }

  const onErrors = (errors: any) => console.error(errors);

  return (

    <Modal className="modal form-modal grey-modal"
      show={editProjectNotesOpened}
      animation={false}
      onShow={() => reset(defaultValues)}
      onHide={() => dispatch(toggleEditProjectNotes())}
      centered
    >

      <Modal.Header>
        <Modal.Title>{t('editProjectNotes.title')}</Modal.Title>
        <button type="button" data-bs-dismiss="modal" aria-label="Close" onClick={() => { dispatch(toggleEditProjectNotes()) }}><i className="fas fa-times"></i></button>
      </Modal.Header>

      <Modal.Body>
        <form onSubmit={handleSubmit(onSubmit, onErrors)}>
          <fieldset>
            <label>
              <div className="row">
                <div className="col-12 col-lg-6">{t('editProjectNotes.projectNotes')}</div>
                <div className="col-12 col-lg-6 error">{errors.projectNotes?.type === 'required' && t('form.required', { field: t('editProjectNotes.projectNotes') })}</div>
              </div>
            </label>

            <textarea className="mb-4" {...register("projectNotes", { required: true, maxLength: 255 })}></textarea>

            <div className="server-error">{t(editProjectNotesError)}</div>

          </fieldset>
          <div className="actions">
            <button type="submit" disabled={editProjectNotesStatus === Status.LOADING}>{submitButtonText}</button>
          </div>
        </form>

      </Modal.Body>
    </Modal>

  );
};
export default EditProjectNotes;