import { Guid } from "guid-typescript";
import { Status } from "../shared/status";
import { TemplatesAction, TemplatesState } from "./types";

const initialState: TemplatesState = {
  templates: [],
  totalTemplates: 0,
  selected : null,
  listTemplatesStatus: Status.IDLE,
  listTemplatesError: null,
  addTemplateOpened: false,
  addTemplateStatus: Status.IDLE,
  addTemplateError: null,
  templateMenuOpened: null,
  editTemplateOpened: false,
  editTemplateStatus: Status.IDLE,
  editTemplateError: null
};

const templatesReducer = (state: TemplatesState = initialState, action: TemplatesAction): TemplatesState => {

  switch (action.type) {

    case 'templates/list-templates-loading':

      return {
        ...state,
        listTemplatesStatus: Status.LOADING,
        listTemplatesError: null
      };

    case 'templates/list-templates-error':

      return {
        ...state,
        listTemplatesStatus: Status.FAILED,
        listTemplatesError: action.payload
      };

    case 'templates/list-templates-success':

      return {
        ...state,
        templates: action.payload.templates,
        totalTemplates: action.payload.total,
        listTemplatesStatus: Status.SUCCESS,
        listTemplatesError: null
      };

    case 'templates/toggle-add-template':

      return {
        ...state,
        addTemplateOpened: !state.addTemplateOpened,
        addTemplateError: state.addTemplateOpened ? state.addTemplateError : null
      };

    case 'templates/add-template-loading':

      return {
        ...state,
        addTemplateStatus: Status.LOADING,
        addTemplateError: null
      };

    case 'templates/add-template-error':

      return {
        ...state,
        addTemplateStatus: Status.FAILED,
        addTemplateError: action.payload
      };

    case 'templates/add-template-success':

      let templates = state.templates.slice();
      let totalTemplates = state.totalTemplates + 1;

      templates.unshift(action.payload);

      return {
        ...state,
        templates,
        totalTemplates,
        addTemplateStatus: Status.SUCCESS,
        addTemplateError: null
      };

    case 'templates/toggle-template-menu':

      let existing = state.templateMenuOpened;
      let opened: Guid | null = null;
      
      if(action.payload && (!existing || existing['value'] !== action.payload['value'])) {
        opened = action.payload
      }

      return {
        ...state,
        templateMenuOpened: opened
      };

    case 'templates/toggle-edit-template':

      return {
        ...state,
        editTemplateOpened: !state.editTemplateOpened,
        editTemplateError: state.editTemplateOpened ? state.editTemplateError : null
      };  
  
    case 'templates/edit-template-loading':

      return {
        ...state,
        editTemplateStatus: Status.LOADING,
        editTemplateError: null
      };

    case 'templates/edit-template-error':

      return {
        ...state,
        editTemplateStatus: Status.FAILED,
        editTemplateError: action.payload
      };

    case 'templates/edit-template-success':

      let templatesToUpdate = state.templates.slice();

      for(var i = 0; i < templatesToUpdate.length; i++) {
        if(templatesToUpdate[i].uuid['value'] === action.payload['uuid']['value']) {
          templatesToUpdate[i] = action.payload;
          break;
        }
      }

      return {
        ...state,
        editTemplateStatus: Status.SUCCESS,
        editTemplateError: null,
        templates: templatesToUpdate
      };
  
    case 'templates/select-template':

      return {
        ...state,
        selected: action.payload
      };

  }

  return state;
};

export default templatesReducer;
