import { IClient, IClientsResponse } from './types';

import axios, { AxiosResponse } from 'axios';
import { normaliseErrorCode, transformList, transformObjectFromString } from '../shared/transform';
import { getValueFromQueryString, redirectToUrl, setValueInQueryString } from '../../shared/queryString';
import { listArchivedProjects, listExampleProjects, listProjects } from '../projects/actions';
import { IProject } from '../projects/types';
import { Guid } from 'guid-typescript';

// Action creators
const listClientsLoading = () => ({ type: 'clients/list-clients-loading' });
const listClientsSuccess = (payload: any) => ({ type: 'clients/list-clients-success', payload});
const listClientsError = (payload: any) => ({ type: 'clients/list-clients-error', payload });
const addClientLoading = () => ({ type: 'clients/add-client-loading' });
const addClientSuccess = (payload: any) => ({ type: 'clients/add-client-success', payload});
const addClientError = (payload: any) => ({ type: 'clients/add-client-error', payload });

export const toggleAddClient = () => ({ type: 'clients/toggle-add-client' });

export const selectClient = (payload: any) => {

  let query = getValueFromQueryString('client');

  if(!query || query['value'] !== payload['value']) {
    setValueInQueryString('client', payload);
    setValueInQueryString('project', null);
  }

  return { type: 'clients/select-client', payload }
};

export const toggleSelectClient = () => ({ type: 'clients/toggle-select-client' });

export const listClients = async (dispatch: any) => {

  dispatch(listClientsLoading());

  axios.get<IClientsResponse>(
    `${process.env.REACT_APP_CLIENTS_PATH}?sort=dateLastActivity&order=desc&active=true`,
    { transformResponse: (data: string) => transformList<IClient>(data, 'clients') })
    .then((response: AxiosResponse<IClientsResponse>) => {
      dispatch(listClientsSuccess(response.data));

      let clientId = getValueFromQueryString('client') ? getValueFromQueryString('client') : response.data.clients[0].uuid;

      dispatch(selectClient(clientId));

      if(clientId) {
        listProjects(dispatch, clientId!);
        listArchivedProjects(dispatch, clientId!);
        if(!isExampleClient(clientId!, response.data.clients)) {
          listExampleProjects(dispatch, response.data.clients.find(x => x.example === true)?.uuid);
        }
      }

    })
    .catch((error: any) => {
      // console.log(error);
      dispatch(listClientsError(error));
    });
};

export const addClient = async (dispatch: any, client: IClient, project: IProject) => {

  dispatch(addClientLoading());

  axios.post(
    `${process.env.REACT_APP_CLIENTS_PATH}`, 
    { 
      agencyId: client.agencyId['value'], 
      name: client.name, 
      active: true, 
      example: false,
      projectName: project.name, 
      leaderId: project.leaderId, 
      jobNumber: project.jobNumber 
    },
    { transformResponse: (data: string) => transformObjectFromString<IClient>(data) })
    .then((response: any) => {

      dispatch(addClientSuccess(response.data));
      dispatch(toggleAddClient());
      dispatch(selectClient(response.data.uuid));

      listProjects(dispatch, response.data.uuid);

      redirectToUrl('/app/projects');

    })
    .catch((error: any) => {
      // console.log(error);
      dispatch(addClientError(normaliseErrorCode(error, 'clients.client.create')));
    });
};


const isExampleClient = (clientId: Guid, clients: IClient[]): boolean => {

  return false;
}