import * as React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { State } from '../../store/shared/state';

export const ReportingSummary: React.FC = () => {

  const { t } = useTranslation();

  const summary = useSelector((state: State) => state.reporting.summary);

  return (
      <React.Fragment>
        <div className="row reporting">
          <div className="col-12 col-xl-6">
            <div className="call-to-action">
              <h2>{t('reporting.agencies.heading')}</h2>
              <p>
                <span className="metric">
                  <span>{t('reporting.agencies.total')}</span>
                  <span className="number">{summary.agencies['total']}</span>
                </span>
                <span className="metric">
                  <span>{t('reporting.agencies.free')}</span>
                  <span className="number">{summary.agencies['free']}</span>
                </span>
                <span className="metric">
                  <span>{t('reporting.agencies.subscriptionPaid')}</span>
                  <span className="number">{summary.agencies['subscription']}</span>
                </span>
                <span className="metric">
                  <span>{t('reporting.agencies.subscriptionFree')}</span>
                  <span className="number">{summary.agencies['subscriptionFree']}</span>
                </span>
              </p>
            </div>
          </div>
          <div className="col-12 col-xl-6">
            <div className="call-to-action">
              <h2>{t('reporting.users.heading')}</h2>
              <p>
                <span className="metric">
                  <span>{t('reporting.users.total')}</span>
                  <span className="number">{summary.users['total']}</span>
                </span>
                <span className="metric">
                  <span>{t('reporting.users.average')}</span>
                  <span className="number">{summary.users['average'].toFixed(2)}</span>
                </span>
              </p>
            </div>
          </div>
        </div>
        <div className="row reporting">
        <div className="col-12">
          <div className="call-to-action">
            <h2>{t('reporting.projects.heading')}</h2>
            <p>
              <span className="metric heading">
                <span className="number">{t('reporting.total')}</span>
                <span className="number">{t('reporting.commenced')}</span>
                <span className="number">{t('reporting.completed')}</span>
              </span>
              <span className="metric">
                <span>{t('reporting.projects.projects')}</span>
                <span className="number">{summary.projects['total']}</span>
                <span className="number">{summary.projects['commenced']}</span>
                <span className="number"></span>
              </span>
              <span className="metric">
                <span>{t('reporting.projects.brandStrategies')}</span>
                <span className="number">{summary.projects['bsTotal']}</span>
                <span className="number">{summary.projects['bsCommenced']}</span>
                <span className="number">{summary.projects['bsCompleted']}</span>
              </span>
              <span className="metric">
                <span>{t('reporting.projects.scriptTemplates')}</span>
                <span className="number">{summary.projects['stTotal']}</span>
                <span className="number">{summary.projects['stCommenced']}</span>
                <span className="number">{summary.projects['stCompleted']}</span>
              </span>
              <span className="metric">
                <span>{t('reporting.projects.creativeCompetitiveOverviews')}</span>
                <span className="number">{summary.projects['ccoTotal']}</span>
                <span className="number">{summary.projects['ccoCommenced']}</span>
                <span className="number">{summary.projects['ccoCompleted']}</span>
              </span>
              <span className="metric">
                <span>{t('reporting.projects.competitiveMappings')}</span>
                <span className="number">{summary.projects['cmTotal']}</span>
                <span className="number">{summary.projects['cmCommenced']}</span>
                <span className="number">{summary.projects['cmCompleted']}</span>
              </span>
              <span className="metric">
                <span>{t('reporting.projects.creativeBriefs')}</span>
                <span className="number">{summary.projects['cbTotal']}</span>
                <span className="number">{summary.projects['cbCommenced']}</span>
                <span className="number">{summary.projects['cbCompleted']}</span>
              </span>
              <span className="metric">
                <span>{t('reporting.projects.mediaBriefs')}</span>
                <span className="number">{summary.projects['mbTotal']}</span>
                <span className="number">{summary.projects['mbCommenced']}</span>
                <span className="number">{summary.projects['mbCompleted']}</span>
              </span>

            </p>
          </div>
        </div>
      </div>
    </React.Fragment>
  )
};

export default ReportingSummary;
