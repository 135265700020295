import { Guid } from 'guid-typescript';

export const getQueryString = (): string => {

    let searchParams = new URLSearchParams(window.location.search); 

    return '?' + searchParams.toString();
}

export const getValueFromQueryString = (key: string): Guid | null => {

    let searchParams = new URLSearchParams(window.location.search); 
    return searchParams.get(key) ? Guid.parse(searchParams.get(key)!) : null;
}

export const getQueryParams = () : any => {
    return new URLSearchParams(window.location.search); 
}
    
export const setValueInQueryString = (key: string, id: Guid | null) => {

    let searchParams = new URLSearchParams(window.location.search); 

    if(id !== null) {
        searchParams.set(key, id ? id['value'].toString() : null);
    } else {
        searchParams.delete(key);
    }

    var url = window.location.protocol + "//" + window.location.host + window.location.pathname + '?' + searchParams.toString();
    window.history.pushState({path:url},'',url);
}
  
export const gotoLegacyUrl = (path: string, toolId: Guid) => {

    let params = getQueryParams();

    let url = `${process.env.REACT_APP_LEGACY_BASE_URL}${path}/${params.get('project')}/${toolId.toString()}`;
    window.location.href = url;
}
  
export const redirectToUrl = (path: string) => {

    let params = getQueryParams();

    var url = `${window.location.protocol}//${window.location.host}${path}/?${params.toString()}`;

    window.location.href = url;
}
  