import React from "react"
import ContentLoader from 'react-content-loader'

const IntroductionLoader: React.FC = () => {
  return (
    <ContentLoader width={"100%"} height={180}>
      <rect x="5" y="0" rx="5" ry="5" width="60%" height="50" />
      <rect x="5" y="90" rx="5" ry="5" width="95%" height="20" />
      <rect x="5" y="125" rx="5" ry="5" width="85%" height="20" />
      <rect x="5" y="160" rx="5" ry="5" width="65%" height="20" />
    </ContentLoader>
  );
};

export default IntroductionLoader;
  