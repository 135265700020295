import * as React from "react";
import { useTranslation } from "react-i18next";

export const ReportingTitle: React.FC = () => {

  const { t } = useTranslation();

  return (
    <div className="row justify-content-end">
      <div className="col-12 col-xl">
        <h1 className="reporting-title">{t('reporting.title')}</h1>
      </div>
    </div>
  );
};

export default ReportingTitle;
