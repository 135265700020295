import { Guid } from "guid-typescript";
import { Status } from "../shared/status";
import { ToolsAction, ToolsState } from "./types";

const initialState: ToolsState = {
  addToolType: null,
  addToolOpened: false,
  addToolStatus: Status.IDLE,
  addToolError: null,
  selectedResearchType: null,
  selectResearchTypeOpened: false,
  sendNielsenEnquiryOpened: false,
  sendNielsenEnquiryStatus: Status.IDLE,
  sendNielsenEnquiryError: null,
  sendPureSpectrumEnquiryOpened: false,
  sendPureSpectrumEnquiryStatus: Status.IDLE,
  sendPureSpectrumEnquiryError: null,
  toolMenuOpened: null,
  editToolOpened: false,
  editToolStatus: Status.IDLE,
  editToolError: null,
  cloneToolOpened: false,
  cloneToolStatus: Status.IDLE,
  cloneToolError: null
};

const toolsReducer = (state: ToolsState = initialState, action: ToolsAction): ToolsState => {

  switch (action.type) {

    case 'tools/toggle-send-nielsen-enquiry':

      return {
        ...state,
        sendNielsenEnquiryOpened: !state.sendNielsenEnquiryOpened
      };  

    case 'tools/send-nielsen-enquiry-loading':

      return {
        ...state,
        sendNielsenEnquiryStatus: Status.LOADING,
        sendNielsenEnquiryError: null
      };

    case 'tools/send-nielsen-enquiry-error':

      return {
        ...state,
        sendNielsenEnquiryStatus: Status.FAILED,
        sendNielsenEnquiryError: action.payload
      };

    case 'tools/send-nielsen-enquiry-success':

      return {
        ...state,
        sendNielsenEnquiryStatus: Status.SUCCESS,
        sendNielsenEnquiryError: null
      };

    case 'tools/toggle-send-pure-spectrum-enquiry':

      return {
        ...state,
        sendPureSpectrumEnquiryOpened: !state.sendPureSpectrumEnquiryOpened
      };  

    case 'tools/send-pure-spectrum-enquiry-loading':

      return {
        ...state,
        sendPureSpectrumEnquiryStatus: Status.LOADING,
        sendPureSpectrumEnquiryError: null
      };

    case 'tools/send-pure-spectrum-enquiry-error':

      return {
        ...state,
        sendPureSpectrumEnquiryStatus: Status.FAILED,
        sendPureSpectrumEnquiryError: action.payload
      };

    case 'tools/send-pure-spectrum-enquiry-success':

      return {
        ...state,
        sendPureSpectrumEnquiryStatus: Status.SUCCESS,
        sendPureSpectrumEnquiryError: null
      };
  
    case 'tools/toggle-select-research-type':

      return {
        ...state,
        selectResearchTypeOpened: !state.selectResearchTypeOpened
      };  

    case 'tools/select-research-type':

      return {
        ...state,
        selectedResearchType: action.payload
      };  

    case 'tools/toggle-add-tool':

      return {
        ...state,
        addToolOpened: !state.addToolOpened,
        addToolType: (!state.addToolOpened && action.payload) ? action.payload : null,
        addToolError: state.addToolOpened ? state.addToolError : null
      };  
  
    case 'tools/add-tool-loading':

      return {
        ...state,
        addToolStatus: Status.LOADING,
        addToolError: null
      };

    case 'tools/add-tool-error':

      return {
        ...state,
        addToolStatus: Status.FAILED,
        addToolError: action.payload
      };

    case 'tools/add-tool-success':

      return {
        ...state,
        addToolStatus: Status.SUCCESS,
        addToolError: null
      };

    case 'tools/toggle-tool-menu':

      let existing = state.toolMenuOpened;
      let opened: Guid | null = null;
      
      if(action.payload && (!existing || existing['value'] !== action.payload['value'])) {
        opened = action.payload
      }

      return {
        ...state,
        toolMenuOpened: opened
      };

    case 'tools/toggle-edit-tool':

      return {
        ...state,
        editToolOpened: !state.editToolOpened,
        editToolError: state.editToolOpened ? state.editToolError : null
      };  
  
    case 'tools/edit-tool-loading':

      return {
        ...state,
        editToolStatus: Status.LOADING,
        editToolError: null
      };

    case 'tools/edit-tool-error':

      return {
        ...state,
        editToolStatus: Status.FAILED,
        editToolError: action.payload
      };

    case 'tools/edit-tool-success':

      return {
        ...state,
        editToolStatus: Status.SUCCESS,
        editToolError: null
      };

    case 'tools/toggle-clone-tool':

      return {
        ...state,
        cloneToolOpened: !state.cloneToolOpened,
        cloneToolError: state.cloneToolOpened ? state.cloneToolError : null
      };  
  
    case 'tools/clone-tool-loading':

      return {
        ...state,
        cloneToolStatus: Status.LOADING,
        cloneToolError: null
      };

    case 'tools/clone-tool-error':

      return {
        ...state,
        cloneToolStatus: Status.FAILED,
        cloneToolError: action.payload
      };

    case 'tools/clone-tool-success':

      return {
        ...state,
        cloneToolStatus: Status.SUCCESS,
        cloneToolError: null
      };
  }

  return state;
};

export default toolsReducer;
