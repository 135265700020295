import * as React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { toggleGenerateReport } from "../../store/reports/actions";
import { State } from "../../store/shared/state";

export const DownloadsTitle: React.FC = () => {

  const { t } = useTranslation();
  const dispatch = useDispatch();

  const selectedTool = useSelector((state: State) => state.projects.selectedTool);

  return (
    <div className="row justify-content-end">
      <div className="col-12 col-xl">
        <h1>{selectedTool?.name} {t('downloads.title')}</h1>
      </div>
      <div className="col-12 col-xl-auto buttons">
        <button className="add" onClick={() => {dispatch(toggleGenerateReport())}}>
          <i className="fas fa-plus-circle"></i>
          {t('downloads.generate')}
        </button>
      </div>
    </div>
  );
};

export default DownloadsTitle;
