import * as React from "react";
import { useTranslation } from "react-i18next";

export const TemplatesResources: React.FC = () => {

  const { t } = useTranslation();

  return (
    <div className="call-to-action standalone">
      <h2>{t('templates.resourcesHeading')}</h2>
      <h3>{t('templates.resourcesSubHeading')}</h3>
      <a href="https://www.brandvas.io/how-to-use-brand-strategy-tools" rel="noreferrer" target="_blank"><span><i className="fas fa-play"></i></span></a>
      <p>{t('templates.resourcesBody')}</p>
    </div>
  );
};

export default TemplatesResources;
