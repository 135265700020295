import React from "react"
import ContentLoader from 'react-content-loader'

const SummaryLoader: React.FC = () => {
  return (
    <div className="tools-loader">
      <ContentLoader width={"100%"} height={700}>

        <rect x="0" y="40" rx="5" ry="5" width="49%" height="300" />
        <rect x="51%" y="40" rx="5" ry="5" width="49%" height="300" />
        <rect x="0" y="370" rx="5" ry="5" width="100%" height="300" />
        
      </ContentLoader>
    </div>
  );
};

export default SummaryLoader;
  