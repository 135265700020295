import * as React from "react";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { formatDate } from "../../shared/dates";
import { getQueryParams } from "../../shared/queryString";
import { State } from '../../store/shared/state';
import { hideToolMenu, toggleToolMenu } from "../../store/tools/actions";
import { ResearchType, ToolType } from '../../store/tools/types';
import { ITool } from "../../store/tools/types";
import CloneTool from "./CloneTool";
import EditTool from "./EditTool";
import ToolMenu from "./ToolMenu";

export const ToolsList: React.FC = () => {

  const { t } = useTranslation();

  const dispatch = useDispatch();
  const history = useHistory();

  const selectedClient = useSelector((state: State) => state.clients.selected);
  const selectedProject = useSelector((state: State) => state.projects.selected);
  const subscription = useSelector((state: State) => state.agencies.mySubscription);

  useEffect(() => {
    return () => {
      dispatch(hideToolMenu());
    }
    // eslint-disable-next-line 
  }, [])

  const goToTemplates = () => {
    let params = getQueryParams();

    params['client'] = selectedClient ? selectedClient!.uuid : null;
    params['project'] = selectedProject ? selectedProject!.uuid : null;
    history.push({ pathname: '/templates', search: '?' + params.toString() });
  }

  const goToToolkit = () => {
    let params = getQueryParams();

    params['client'] = selectedClient ? selectedClient!.uuid : null;
    params['project'] = selectedProject ? selectedProject!.uuid : null;
    history.push({ pathname: '/toolkit', search: '?' + params.toString() });
  }
  

  const locked = (type: ToolType) => {
    if(type === ToolType.RESEARCH || type === ToolType.MEDIA_BRIEF || type === ToolType.CREATIVE_BRIEF) {
      return false;
    }
    return !selectedClient?.example && subscription && !subscription.active && !subscription.freeTrial;
  }

  const unlocked = (type: ToolType) => {
    if(type === ToolType.RESEARCH || type === ToolType.MEDIA_BRIEF || type === ToolType.CREATIVE_BRIEF) {
      return true;
    }
    return selectedClient?.example || !subscription || subscription.active || subscription.freeTrial;
  }

  const goToTool = (tool: ITool) => {
    var url = `${process.env.REACT_APP_LEGACY_BASE_URL}`;

    switch(tool.type) {

      case ToolType.RESEARCH:
        url += getResearchToolUrl(tool);
        break;

        case ToolType.BRAND_STRATEGY:
        url += '/discovery/';
        break;

      case ToolType.MEDIA_BRIEF:
        url += '/media-brief/';
        break;

      case ToolType.COMPETITIVE_MAPPING:
        url += '/competitive-mapping/competitive-mapping-competitors/';
        break;

      case ToolType.CREATIVE_BRIEF:
        url += '/creative-brief/';
        break;

      case ToolType.CREATIVE_COMPETITIVE_OVERVIEW:
        url += '/creative-competitive-overview/';
        break;

        case ToolType.SCRIPT_TEMPLATE:
        url += '/script-template/';
        break;

      default:
        break;
    }
    
    url += selectedProject!.uuid['value'] + '/' + tool.uuid['value'];

    window.location.href = url;
  }

  const openToolMenu = (tool: ITool) => {
    dispatch(toggleToolMenu(tool.uuid));
  }

  const getResearchToolUrl = (tool: ITool) : string => {

    if(!tool.data || !tool.data['researchType']) {
      return '';
    }

    switch(tool.data['researchType']) {
      case ResearchType.ROY_MORGAN: 
        return '/roy-morgan-research/';
      case ResearchType.AUSTRALIA_POST: 
        return '/australia-post-research/';
      case ResearchType.CUSTOM: 
        return '/custom-research/';
      default:
        return '';
    }
  }

  const getResearchToolTypeName = (tool: ITool) : string => {

    if(!tool.data || !tool.data['researchType']) {
      return '';
    }

    switch(tool.data['researchType']) {
      case ResearchType.ROY_MORGAN: 
        return 'Roy Morgan';
      case ResearchType.AUSTRALIA_POST: 
        return 'Australia Post';
      case ResearchType.CUSTOM: 
        return t('research.custom');
      default:
        return '';
    }
  }

  return selectedProject != null && selectedProject.tools.length > 0 ? (
      <div className="table-container compact">
        <EditTool />
        <CloneTool />
        <table>
          <thead>
            <tr>
              <th className="d-xl-none">
              {t('projects.project')}
              </th>
              <th className="d-none d-xl-table-cell">
                {t('projects.name')}
              </th>
              <th className="d-none d-xl-table-cell">
                {t('projects.tool')}
              </th>
              <th className="d-none d-xl-table-cell">
                {t('projects.template')}
              </th>
              <th className="actions">
                {t('projects.actions')}
              </th>
            </tr>
          </thead>
          <tbody>
      
            {selectedProject.tools.map((tool: ITool) => (
              <tr key={tool.uuid['value'].toString()}>

                <td className="d-xl-none">
                  {unlocked(tool.type) && tool.type === ToolType.RESEARCH && <a href={`${process.env.REACT_APP_LEGACY_BASE_URL}${getResearchToolUrl(tool)}${selectedProject.uuid['value']}/${tool.uuid['value']}`}>{tool.name}</a>}
                  {unlocked(tool.type) && tool.type === ToolType.BRAND_STRATEGY && <a href={`${process.env.REACT_APP_LEGACY_BASE_URL}/discovery/${selectedProject.uuid['value']}/${tool.uuid['value']}`}>{tool.name}</a>}
                  {unlocked(tool.type) && tool.type === ToolType.CREATIVE_BRIEF && <a href={`${process.env.REACT_APP_LEGACY_BASE_URL}/creative-brief/${selectedProject.uuid['value']}/${tool.uuid['value']}`}>{tool.name}</a>}
                  {unlocked(tool.type) && tool.type === ToolType.MEDIA_BRIEF && <a href={`${process.env.REACT_APP_LEGACY_BASE_URL}/media-brief/${selectedProject.uuid['value']}/${tool.uuid['value']}`}>{tool.name}</a>}
                  {unlocked(tool.type) && tool.type === ToolType.COMPETITIVE_MAPPING && <a href={`${process.env.REACT_APP_LEGACY_BASE_URL}/competitive-mapping/competitive-mapping-competitors/${selectedProject.uuid['value']}/${tool.uuid['value']}`}>{tool.name}</a>}
                  {unlocked(tool.type) && tool.type === ToolType.CREATIVE_COMPETITIVE_OVERVIEW && <a href={`${process.env.REACT_APP_LEGACY_BASE_URL}/creative-competitive-overview/${selectedProject.uuid['value']}/${tool.uuid['value']}`}>{tool.name}</a>}
                  {unlocked(tool.type) && tool.type === ToolType.SCRIPT_TEMPLATE && <a href={`${process.env.REACT_APP_LEGACY_BASE_URL}/script-template/${selectedProject.uuid['value']}/${tool.uuid['value']}`}>{tool.name}</a>}
                  {locked(tool.type) && <span className="name">{tool.name}</span>}
                  
                  {tool.type === ToolType.BRAND_STRATEGY && <span className="sub-item mt-2">{t('projects.tool')}: {t('projects.brandStrategy')}</span>}
                  {tool.type === ToolType.COMPETITIVE_MAPPING && <span className="sub-item mt-2">{t('projects.tool')}: {t('projects.competitiveMapping')}</span>}
                  {tool.type === ToolType.CREATIVE_BRIEF && <span className="sub-item mt-2">{t('projects.tool')}: {t('projects.creativeBrief')}</span>}
                  {tool.type === ToolType.CREATIVE_COMPETITIVE_OVERVIEW && <span className="sub-item mt-2">{t('projects.tool')}: {t('projects.creativeCompetitiveOverview')}</span>}
                  {tool.type === ToolType.MEDIA_BRIEF && <span className="sub-item mt-2">{t('projects.tool')}: {t('projects.mediaBrief')}</span>}
                  {tool.type === ToolType.SCRIPT_TEMPLATE && <span className="sub-item mt-2">{t('projects.tool')}: {t('projects.scriptTemplate')}</span>}
                  {tool.type === ToolType.RESEARCH && <span className="sub-item mt-2">{t('projects.tool')}: {t('projects.research')}</span>}

                  {(tool.type !== ToolType.RESEARCH && tool.template) && <span className="sub-item">{t('projects.template')}: {tool.template}</span>}
                  {tool.type === ToolType.RESEARCH && <span className="sub-item">{t('projects.template')}: {getResearchToolTypeName(tool)}</span>}
                  <span className="sub-item mt-4">{t('general.lastEdited', { dateModified: formatDate(tool.dateModified), lastModifiedBy: tool.lastModifiedBy })}</span>
                </td>


                <td className="d-none d-xl-table-cell">
                  {unlocked(tool.type) && tool.type === ToolType.RESEARCH && <a href={`${process.env.REACT_APP_LEGACY_BASE_URL}${getResearchToolUrl(tool)}${selectedProject.uuid['value']}/${tool.uuid['value']}`}>{tool.name}</a>}
                  {unlocked(tool.type) && tool.type === ToolType.BRAND_STRATEGY && <a href={`${process.env.REACT_APP_LEGACY_BASE_URL}/discovery/${selectedProject.uuid['value']}/${tool.uuid['value']}`}>{tool.name}</a>}
                  {unlocked(tool.type) && tool.type === ToolType.CREATIVE_BRIEF && <a href={`${process.env.REACT_APP_LEGACY_BASE_URL}/creative-brief/${selectedProject.uuid['value']}/${tool.uuid['value']}`}>{tool.name}</a>}
                  {unlocked(tool.type) && tool.type === ToolType.MEDIA_BRIEF && <a href={`${process.env.REACT_APP_LEGACY_BASE_URL}/media-brief/${selectedProject.uuid['value']}/${tool.uuid['value']}`}>{tool.name}</a>}
                  {unlocked(tool.type) && tool.type === ToolType.COMPETITIVE_MAPPING && <a href={`${process.env.REACT_APP_LEGACY_BASE_URL}/competitive-mapping/competitive-mapping-competitors/${selectedProject.uuid['value']}/${tool.uuid['value']}`}>{tool.name}</a>}
                  {unlocked(tool.type) && tool.type === ToolType.CREATIVE_COMPETITIVE_OVERVIEW && <a href={`${process.env.REACT_APP_LEGACY_BASE_URL}/creative-competitive-overview/${selectedProject.uuid['value']}/${tool.uuid['value']}`}>{tool.name}</a>}
                  {unlocked(tool.type) && tool.type === ToolType.SCRIPT_TEMPLATE && <a href={`${process.env.REACT_APP_LEGACY_BASE_URL}/script-template/${selectedProject.uuid['value']}/${tool.uuid['value']}`}>{tool.name}</a>}
                  {locked(tool.type) && <span className="name">{tool.name}</span>}
                  {t('general.lastEdited', { dateModified: formatDate(tool.dateModified), lastModifiedBy: tool.lastModifiedBy })}
                </td>
                <td className="d-none d-xl-table-cell fixed name">
                  {tool.type === ToolType.BRAND_STRATEGY && <span>{t('projects.brandStrategy')}</span>}
                  {tool.type === ToolType.COMPETITIVE_MAPPING && <span>{t('projects.competitiveMapping')}</span>}
                  {tool.type === ToolType.CREATIVE_BRIEF && <span>{t('projects.creativeBrief')}</span>}
                  {tool.type === ToolType.CREATIVE_COMPETITIVE_OVERVIEW && <span>{t('projects.creativeCompetitiveOverview')}</span>}
                  {tool.type === ToolType.MEDIA_BRIEF && <span>{t('projects.mediaBrief')}</span>}
                  {tool.type === ToolType.SCRIPT_TEMPLATE && <span>{t('projects.scriptTemplate')}</span>}
                  {tool.type === ToolType.RESEARCH && <span>{t('projects.research')}</span>}
                </td>
                <td className="d-none d-xl-table-cell dark">
                  {tool.type !== ToolType.RESEARCH && tool.template}
                  {tool.type === ToolType.RESEARCH && getResearchToolTypeName(tool)}
                </td>

                <td className="actions">
                  {unlocked(tool.type) && <button className="d-none d-sm-inline-block open" onClick={() => goToTool(tool)}>{t('general.open')}</button>}
                  {unlocked(tool.type) && <button className="toggle" onClick={() => { openToolMenu(tool) } } id={`tool-menu-toggle-${tool.uuid.toString()}`}><i className="fas fa-chevron-down" /></button>}
                  {unlocked(tool.type) && <ToolMenu tool={tool} />}
                  {locked(tool.type) && <button className="d-none d-sm-inline-block open locked">{t('general.locked')}</button>}
                  {locked(tool.type) && <button className="toggle locked"><i className="fas fa-lock"></i></button>}
                </td>
              </tr>
            ))}

          </tbody>
        </table>
      </div>
  ) : (
    <React.Fragment>
      <p>{t('projects.introduction')}</p>

      <div className="row">
        <div className="col-12 col-xl-6">
          <div className="call-to-action medium yellow-spacemen" onClick={goToToolkit}>
            <h2>{t('projects.toolkit')}</h2>
            <h3>{t('projects.toolkitSubHeading')}</h3>
          </div>
        </div>
        <div className="col-12 col-xl-6">
          <div className="call-to-action medium red-computer" onClick={goToTemplates}>
            <h2>{t('projects.templates')}</h2>
            <h3>{t('projects.templatesSubHeading')}</h3>
          </div>
        </div>
      </div>

    </React.Fragment>
  );
};

export default ToolsList;
