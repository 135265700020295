import * as React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { State } from '../../store/shared/state';
import { Status } from '../../store/shared/status';
import { hideTemplateMenu, toggleAddTemplate } from "../../store/templates/actions";
import AddTemplate from "./AddTemplate";

export const TemplatesTitle: React.FC = () => {

  const { t } = useTranslation();

  const dispatch = useDispatch();

  const clientsStatus = useSelector((state: State) => state.clients.listClientsStatus);

  const addTemplate = () => {
    if(clientsStatus === Status.SUCCESS) {
      dispatch(hideTemplateMenu());
      dispatch(toggleAddTemplate());
    }
  }

  return (
    <div className="row justify-content-end">
      <div className="col-12 col-xl">
        <h1>{t('templates.title')}</h1>
      </div>
      <div className="col-12 col-xl-auto buttons">
        <button className="add" onClick={addTemplate}>
          <i className="fas fa-plus-circle"></i>
          {t('templates.template')}
        </button>
        <AddTemplate />
      </div>
    </div>
  );
};

export default TemplatesTitle;
