import * as React from "react";
import { useSelector } from "react-redux";
import { State } from "../../store/shared/state";
import { Status } from "../../store/shared/status";
import { edgeClass, filterPersonalitySetsByScore, getCompetitorMappingData, getReseachTools, processBrandProofPoints, processBrandProofPointsForCanvas, processBrandValues, processBusinessData, processCoreCustomerDataForCanvas, processKeyAudienceData, processSecondaryKeyAudienceData, writeLeftMargin, writeTopMargin } from './BrandStrategyFunctions';

type Props = {
  forwardedRef: any;
};

export const BrandStrategyDesigned: React.FC<Props> = ({forwardedRef}) => {

  const selectedTool = useSelector((state: State) => state.projects.selectedTool);
  const selectedProject = useSelector((state: State) => state.projects.selected);
  const projectsStatus = useSelector((state: State) => state.projects.listProjectsStatus);
  const myAgency = useSelector((state: State) => state.agencies.myAgency);
  const images = useSelector((state: State) => state.images.images);

  let researchTools = getReseachTools(selectedProject);
  let competitorMappingData = getCompetitorMappingData(selectedTool);
  
  let primaryAttributes = filterPersonalitySetsByScore(selectedTool, 5);
  let secondaryAttributes = filterPersonalitySetsByScore(selectedTool, 4);
  let brandValues = processBrandValues(selectedTool);
  let brandProofPoints = processBrandProofPoints(selectedTool);
  let keyAudienceData = processKeyAudienceData(selectedTool);
  let secondaryKeyAudienceData = processSecondaryKeyAudienceData(selectedTool);

  let canvasCoreCustomerData = processCoreCustomerDataForCanvas(selectedTool);
  let canvasBrandProofPoints = processBrandProofPointsForCanvas(selectedTool);

  let year = new Date().getFullYear();

  return (
    <div ref={forwardedRef} id="brandStrategyDesigned">
      <div className="brandStrategyDesigned">
        
        {/* Start page */}
        <div className="page start-page">
          <div className="inner">
            <img className="blue-background-image" alt="" src="/app/images/reports/background-blue.png"/>
            <h1>{selectedProject?.name}</h1>
            <h2>Brand Strategy</h2>
            <footer className="footer">
              <span>© {myAgency?.name} {year}. Powered by Brandvas.</span>
              {myAgency && images && <img src={images[myAgency!.logo]} alt={myAgency?.name} /> }
            </footer>
          </div>
        </div>
        {/* END State page */}

        {/* Your Brand Report */}
        <div className="page coloured-page yellow-page">
          <div className="inner">
            <h1>Your Brand Report</h1>
            <h2>Congratulations</h2>
            <h2>By completing your brand strategy you've joined the ranks of leading organisations across every category and market who have similarly invested in this critical business discipline.</h2>
            <h2>The purpose of this report is to provide a coherent branding strategy for {selectedProject?.name} Private Equity. This report provides clear direction for the positioning of {selectedProject?.name} in your market.</h2>
            <footer className="footer">
              <span>© {myAgency?.name} {year}. Powered by Brandvas.</span>
            </footer>
          </div>
        </div>
        {/* END Your Brand Report */}

        {/* Business data */}
        {projectsStatus === Status.SUCCESS && (selectedProject?.business || selectedProject?.businessMetrics) && (
          <div className="page-group">
            {processBusinessData(selectedProject)?.map(function(object, i){
              return (
                <div key={i} className="page">
                  <div className="inner content-page-inner">
                    {myAgency && images && <img className="agency-logo-top" src={images[myAgency!.logo]} alt={myAgency?.name} /> }
                    <div className="d-flex heading">
                        <h3>Understanding Your Business</h3>
                    </div>
                    <div className="d-flex">
                       <div className="column">
                        {object['first'].map(function(dimension:any, j:number) {
                          return (
                            <p key={j}><span>{dimension['headline']}</span>: {dimension['description']}</p>
                          )
                        })}
                       </div>
                       <div className="column">
                        {object['second'].map(function(dimension:any, j:number) {
                          return (
                            <p key={j}><span>{dimension['headline']}</span>: {dimension['description']}</p>
                          )
                        })}
                       </div>
                    </div>
                    <footer className="footer">
                      <span>© {myAgency?.name} {year}. Powered by Brandvas.</span>
                    </footer>
                  </div>
                </div>
              )
            })}
          </div>
        )}
        {/* END Business data */}

        {/* Market data */}

        {/* TODO Heading page for ROY MORGAN AND AUSTRALIA POST MARKET DATA */}

        {projectsStatus === Status.SUCCESS && researchTools?.length > 0 && (
          <div className="page-group">
            {researchTools?.map(function(object, i) {
              return (

                <div key={i} className="page">

                  {/* TODO ROY MORGAN AND AUSTRALIA POST MARKET DATA */}

                  {object['data']['researchType'] === 'CUSTOM' && (

                    <div className="inner content-page-inner">
                      {myAgency && images && <img className="agency-logo-top" src={images[myAgency!.logo]} alt={myAgency?.name} /> }
                      <div className="d-flex heading">
                          <h3>Understanding Your Market</h3>
                      </div>
                      <div className="d-flex">
                        <div className="column">
                          <p><span>Market size:</span> {object['data']['marketSize']}</p>
                          <p><span>Number of competitors:</span> {object['data']['numberOfCompetitors']}</p>
                          <p><span>Client / Market trends:</span> {object['data']['marketTrends']}</p>
                        </div>
                        <div className="column">
                          <p><span>Technology trends:</span> {object['data']['technologyTrends']}</p>
                          <p><span>Projected growth:</span> {object['data']['projectedGrowth']}</p>
                        </div>
                      </div>
                      <footer className="footer">
                        <span>© {myAgency?.name} {year}. Powered by Brandvas.</span>
                      </footer>
                    </div>
                  )}
                </div>
              )
            })}
          </div>
        )}
        {/* END Market data */}

        {/* Your Audience */} 

        {/* TODO HELIX DATA INTRODUCTION */}

        {/* TODO HELIX DATA */}

        {keyAudienceData && (
          <div className="page-group">

            {keyAudienceData.map(function(keyAudience: any, i: number) {
              return (
                <div className="page">
                  <div className="inner content-page-inner">
                    {myAgency && images && <img className="agency-logo-top" src={images[myAgency!.logo]} alt={myAgency?.name} /> }
                    <div className="d-flex heading">
                        <h3>Defining your key audience</h3>
                    </div>
                    <div className="d-flex">
                      <div className="column">
                        {keyAudience['first'].map(function(object: any, j: number) {
                          return (
                            <div key={j}>
                                {!object['question'] && (
                                  <p>
                                    {object['headline'] && <span>{object['headline']}: </span>} {object['description']}
                                  </p>
                                )}
                                {object['question'] && (
                                  <div>
                                    <p><span>{object['headline'] && <span>{object['headline']}</span>}</span></p>
                                    <p>
                                      {object['description']}
                                    </p>
                                  </div>
                                )}
                            </div>
                          )
                        })}
                      </div>
                      <div className="column">
                        {keyAudience['second'] && (
                          <div>
                            {keyAudience['second'].map(function(object: any, j: number) {
                              return (
                                <div key={j}>
                                  {!object['question'] && (
                                    <p>
                                      {object['headline'] && <span>{object['headline']}: </span>} {object['description']}
                                    </p>
                                  )}
                                  {object['question'] && (
                                    <div>
                                      <p><span>{object['headline'] && <span>{object['headline']}</span>}</span></p>
                                      <p>
                                        {object['description']}
                                      </p>
                                    </div>
                                  )}
                                </div>
                              )
                            })}
                          </div>
                        )}
                      </div>
                    </div>
                    <footer className="footer">
                      <span>© {myAgency?.name} {year}. Powered by Brandvas.</span>
                    </footer>
                  </div>
                </div>
              )
            })}
          </div>
        )}

        {secondaryKeyAudienceData && (
          <div className="page-group">

            {secondaryKeyAudienceData.map(function(keyAudience: any, i: number) {
              return (
                <div className="page">
                  <div className="inner content-page-inner">
                    {myAgency && images && <img className="agency-logo-top" src={images[myAgency!.logo]} alt={myAgency?.name} /> }
                    <div className="d-flex heading">
                        <h3>Your Secondary Customer / Client</h3>
                    </div>
                    <div className="d-flex">
                      <div className="column">
                        {keyAudience['first'].map(function(object: any, j: number) {
                          return (
                            <div key={j}>
                                {!object['question'] && (
                                  <p>
                                    {object['headline'] && <span>{object['headline']}: </span>} {object['description']}
                                  </p>
                                )}
                                {object['question'] && (
                                  <div>
                                    <p><span>{object['headline'] && <span>{object['headline']}</span>}</span></p>
                                    <p>
                                      {object['description']}
                                    </p>
                                  </div>
                                )}
                            </div>
                          )
                        })}
                      </div>
                      <div className="column">
                        {keyAudience['second'] && (
                          <div>
                            {keyAudience['second'].map(function(object: any, j: number) {
                              return (
                                <div key={j}>
                                  {!object['question'] && (
                                    <p>
                                      {object['headline'] && <span>{object['headline']}: </span>} {object['description']}
                                    </p>
                                  )}
                                  {object['question'] && (
                                    <div>
                                      <p><span>{object['headline'] && <span>{object['headline']}</span>}</span></p>
                                      <p>
                                        {object['description']}
                                      </p>
                                    </div>
                                  )}
                                </div>
                              )
                            })}
                          </div>
                        )}
                      </div>
                    </div>
                    <footer className="footer">
                      <span>© {myAgency?.name} {year}. Powered by Brandvas.</span>
                    </footer>
                  </div>
                </div>
              )
            })}
          </div>
        )}
        {/* END Your Audience */}

        {/* Competitors data */}
        {selectedTool?.data['selectedCompetitors']?.length > 0 && (
          <div className="page-group">

            <div className="page coloured-page yellow-page">
              <div className="inner">
                <h1>Understanding Your Competitors</h1>
                <h2>All brands live in the context of their competitors. The way a customers (client) understands a brand’s positioning is relative to how it’s biggest competitors are positioned.</h2>
                <h2>For example a prestige car may be priced at $70,000 based on competitor set, where the equivalent size and spec’d Korean car may be priced at only $30,000. Price is just one dimension of brand positioning that sets the competitive context. Other dimensions include quality, design, performance, reliability, etc.</h2>
                <footer className="footer">
                  <span>© {myAgency?.name} {year}. Powered by Brandvas</span>
                </footer>
              </div>
            </div>

            <div className="page">
              <div className="inner content-page-inner">
                {myAgency && images && <img className="agency-logo-top" src={images[myAgency!.logo]} alt={myAgency?.name} /> }
                <div className="d-flex heading">
                    <h3>Understanding Your Competitors</h3>
                </div>
                <div className="d-flex">

                  <div className="competitors-list">
                    {selectedTool?.data['selectedCompetitors']?.map(function(object: any, i: number) {
                      return (
                        <div key={i} className="competitor">
                          <img src={images[object['logo']]} alt={object['name']} />
                          <p>{object['name']}</p>
                        </div>
                      )
                    })}
                  </div>
                </div>
                <footer className="footer">
                  <span>© {myAgency?.name} {year}. Powered by Brandvas.</span>
                </footer>
              </div>
            </div>
          </div>
        )}
        {/* END Competitors data */}


        {/* Brand positioning */}
        {selectedTool?.data['customerNeeds'] && selectedTool?.data['customerNeeds']['selected'] && selectedTool?.data['customerNeeds']['selected']?.length > 0 && (
          <div className="page-group">
            <div className="page coloured-page blue-page">
              <div className="inner">
                <h1>Competitive Brand Positioning</h1>
                <h2>Achieving alignment between your brand and your customers requires an understanding of what your customers value, and what your customers are looking for.</h2>
                <h2>You must also differentiate yourself from your competitors and their brand positioning.</h2>
                <footer className="footer">
                  <span>© {myAgency?.name} {year}. Powered by Brandvas</span>
                </footer>
              </div>
            </div>

            <div className="page">
              <div className="inner content-page-inner">
                {myAgency && images && <img className="agency-logo-top" src={images[myAgency!.logo]} alt={myAgency?.name} /> }
                <div className="d-flex heading">
                    <h3>How you meet your customers/clients' needs?</h3>
                </div>
                <div className="d-flex">
                  <div className="column">
                    {selectedTool?.data['customerNeeds']['selected']?.map(function(object: any, i: number) {
                      return (
                        <p key={i}><span>{object['name']}</span></p>
                      )
                    })}
                  </div>
                  <div className="column">
                  </div>
                </div>
                <footer className="footer">
                  <span>© {myAgency?.name} {year}. Powered by Brandvas.</span>
                </footer>
              </div>
            </div>

            {selectedTool?.data['brandStrengths'] && selectedTool?.data['brandStrengths']['selected'] && selectedTool?.data['brandStrengths']['selected']?.length > 0 && (

              <div className="page">
                <div className="inner content-page-inner">
                  {myAgency && images && <img className="agency-logo-top" src={images[myAgency!.logo]} alt={myAgency?.name} /> }
                  <div className="d-flex heading">
                      <h3>Your brand Strengths<br />(what you do better than anyone else)?</h3>
                  </div>
                  <div className="d-flex">
                    <div className="column">
                      {selectedTool?.data['brandStrengths']['selected']?.map(function(object: any, i: number) {
                        return (
                          <p key={i}><span>{object['name']}</span></p>
                        )
                      })}
                    </div>
                    <div className="column">
                    </div>
                  </div>
                  <footer className="footer">
                    <span>© {myAgency?.name} {year}. Powered by Brandvas.</span>
                  </footer>
                </div>
              </div>
            )}

            {selectedTool?.data['competitorStrengths'] && selectedTool?.data['competitorStrengths']['selected'] && selectedTool?.data['competitorStrengths']['selected']?.length > 0 && (

              <div className="page">
                <div className="inner content-page-inner">
                  {myAgency && images && <img className="agency-logo-top" src={images[myAgency!.logo]} alt={myAgency?.name} /> }
                  <div className="d-flex heading">
                      <h3>Your competitors' brand strengths<br />(what they do better than anyone else)?</h3>
                  </div>
                  <div className="d-flex">
                    <div className="column">
                      {selectedTool?.data['competitorStrengths']['selected']?.map(function(object: any, i: number) {
                        return (
                          <p key={i}><span>{object['name']}</span></p>
                        )
                      })}
                    </div>
                    <div className="column">
                    </div>
                  </div>
                  <footer className="footer">
                    <span>© {myAgency?.name} {year}. Powered by Brandvas.</span>
                  </footer>
                </div>
              </div>
            )}

            {selectedTool?.data['competitorPositioningAxes'] && selectedTool?.data['competitorPositioningAxes']['rankings'] && selectedTool?.data['competitorPositioningAxes']['rankings']?.length > 0 && (

              <div className="page">
                <div className="inner content-page-inner">
                  {myAgency && images && <img className="agency-logo-top" src={images[myAgency!.logo]} alt={myAgency?.name} /> }
                  <div className="d-flex heading">
                      <h3>Selected Competitor Mapping Criteria</h3>
                  </div>
                  <div className="d-flex">
                    <div className="column">
                      {selectedTool?.data['competitorPositioningAxes']['rankings']?.map(function(object: any, i: number) {
                        return (
                          <p key={i}><span>{object['name']}</span></p>
                        )
                      })}
                    </div>
                    <div className="column">
                    </div>
                  </div>
                  <footer className="footer">
                    <span>© {myAgency?.name} {year}. Powered by Brandvas.</span>
                  </footer>
                </div>
              </div>
            )}
          </div>
        )}

        {/* END Brand positioning */}

        {/* Competitor maps */}

        {competitorMappingData != null && competitorMappingData!['matrixData'] && competitorMappingData!['matrixData']?.length > 0 && (
          <div className="page-group">
            <div className="page coloured-page blue-page">
              <div className="inner">
                <h1>Competitor maps</h1>
                <h2>Competitor maps show how brands are different from each other and allow you to identify a unique space for your brand. It will also show areas of risk of vulnerability</h2>
                <footer className="footer">
                  <span>© {myAgency?.name} {year}. Powered by Brandvas</span>
                </footer>
              </div>
            </div>


            {competitorMappingData['axes'] && competitorMappingData['axes']['firstY'] && competitorMappingData['axes']['firstY'][0] && (
              <div className="page">
                <div className="inner content-page-inner">
                  {myAgency && images && <img className="agency-logo-top" src={images[myAgency!.logo]} alt={myAgency?.name} /> }
                  <div className="d-flex heading">
                      <h3>Competitor maps</h3>
                  </div>
                  <div className="d-flex" >
                    <div className="col-12 col-lg-12">
                      <div className="y-axis-label">{competitorMappingData['axes']['firstY'][0]['name']}</div>
                      <div className="matrix-wrapper">
                        <div className="matrix">
                          <div className="y-axis"></div>
                          <div className="x-axis">
                            <span>{competitorMappingData['axes']['firstX'][0]['name']}</span>
                          </div>

                          {competitorMappingData['matrixData']?.map(function(object: any, i: number) {
                            return (
                              <span 
                                className={edgeClass(competitorMappingData?.marginData, 'first', object, i)}
                                key={i}
                                style={{
                                  top: writeTopMargin(competitorMappingData?.marginData, 'first', object, i),
                                  left: writeLeftMargin(competitorMappingData?.marginData, 'first', object, i)
                                }}
                              >
                                {(!object['logo'] || !images[object['logo']]) && <span>{object['name']}</span>}
                                {(object['logo'] && images[object['logo']]) && <img src={images[object['logo']]} alt={object['name']} />}
                              </span>
                            )
                          })}
                        </div>
                      </div>
                    </div>
                  </div>
                  <footer className="footer">
                    <span>© {myAgency?.name} {year}. Powered by Brandvas.</span>
                  </footer>
                </div>
              </div>
            )}
            
            {competitorMappingData['axes'] && competitorMappingData['axes']['secondY'] && competitorMappingData['axes']['secondY'][0] && (
              <div className="page">
                <div className="inner content-page-inner">
                  {myAgency && images && <img className="agency-logo-top" src={images[myAgency!.logo]} alt={myAgency?.name} /> }
                  <div className="d-flex heading">
                      <h3>Competitor maps</h3>
                  </div>
                  <div className="d-flex" >
                    <div className="col-12 col-lg-12">
                      <div className="y-axis-label">{competitorMappingData['axes']['secondY'][0]['name']}</div>
                      <div className="matrix-wrapper">
                        <div className="matrix">
                          <div className="y-axis"></div>
                          <div className="x-axis">
                            <span>{competitorMappingData['axes']['secondX'][0]['name']}</span>
                          </div>

                          {competitorMappingData['matrixData']?.map(function(object: any, i: number) {
                            return (
                              <span 
                                className={edgeClass(competitorMappingData?.marginData, 'second', object, i)}
                                key={i}
                                style={{
                                  top: writeTopMargin(competitorMappingData?.marginData, 'second', object, i),
                                  left: writeLeftMargin(competitorMappingData?.marginData, 'second', object, i)
                                }}
                              >
                                {(!object['logo'] || !images[object['logo']]) && <span>{object['name']}</span>}
                                {(object['logo'] && images[object['logo']]) && <img src={images[object['logo']]} alt={object['name']} />}
                              </span>
                            )
                          })}
                        </div>
                      </div>
                    </div>
                  </div>
                  <footer className="footer">
                    <span>© {myAgency?.name} {year}. Powered by Brandvas.</span>
                  </footer>
                </div>
              </div>
            )}

            {competitorMappingData['axes'] && competitorMappingData['axes']['thirdY'] && competitorMappingData['axes']['thirdY'][0] && (
              <div className="page">
                <div className="inner content-page-inner">
                  {myAgency && images && <img className="agency-logo-top" src={images[myAgency!.logo]} alt={myAgency?.name} /> }
                  <div className="d-flex heading">
                      <h3>Competitor maps</h3>
                  </div>
                  <div className="d-flex" >
                    <div className="col-12 col-lg-12">
                      <div className="y-axis-label">{competitorMappingData['axes']['thirdY'][0]['name']}</div>
                      <div className="matrix-wrapper">
                        <div className="matrix">
                          <div className="y-axis"></div>
                          <div className="x-axis">
                            <span>{competitorMappingData['axes']['thirdX'][0]['name']}</span>
                          </div>

                          {competitorMappingData['matrixData']?.map(function(object: any, i: number) {
                            return (
                              <span 
                                className={edgeClass(competitorMappingData?.marginData, 'third', object, i)}
                                key={i}
                                style={{
                                  top: writeTopMargin(competitorMappingData?.marginData, 'third', object, i),
                                  left: writeLeftMargin(competitorMappingData?.marginData, 'third', object, i)
                                }}
                              >
                                {(!object['logo'] || !images[object['logo']]) && <span>{object['name']}</span>}
                                {(object['logo'] && images[object['logo']]) && <img src={images[object['logo']]} alt={object['name']} />}
                              </span>
                            )
                          })}
                        </div>
                      </div>
                    </div>
                  </div>
                  <footer className="footer">
                    <span>© {myAgency?.name} {year}. Powered by Brandvas.</span>
                  </footer>
                </div>
              </div>
            )}

            {competitorMappingData['axes'] && competitorMappingData['axes']['fourthY'] && competitorMappingData['axes']['fourthY'][0] && (
              <div className="page">
                <div className="inner content-page-inner">
                  {myAgency && images && <img className="agency-logo-top" src={images[myAgency!.logo]} alt={myAgency?.name} /> }
                  <div className="d-flex heading">
                      <h3>Competitor maps</h3>
                  </div>
                  <div className="d-flex" >
                    <div className="col-12 col-lg-12">
                      <div className="y-axis-label">{competitorMappingData['axes']['fourthY'][0]['name']}</div>
                      <div className="matrix-wrapper">
                        <div className="matrix">
                          <div className="y-axis"></div>
                          <div className="x-axis">
                            <span>{competitorMappingData['axes']['fourthX'][0]['name']}</span>
                          </div>

                          {competitorMappingData['matrixData']?.map(function(object: any, i: number) {
                            return (
                              <span 
                                className={edgeClass(competitorMappingData?.marginData, 'fourth', object, i)}
                                key={i}
                                style={{
                                  top: writeTopMargin(competitorMappingData?.marginData, 'fourth', object, i),
                                  left: writeLeftMargin(competitorMappingData?.marginData, 'fourth', object, i)
                                }}
                              >
                                {(!object['logo'] || !images[object['logo']]) && <span>{object['name']}</span>}
                                {(object['logo'] && images[object['logo']]) && <img src={images[object['logo']]} alt={object['name']} />}
                              </span>
                            )
                          })}
                        </div>
                      </div>
                    </div>
                  </div>
                  <footer className="footer">
                    <span>© {myAgency?.name} {year}. Powered by Brandvas.</span>
                  </footer>
                </div>
              </div>
            )}

          </div>
        )}
        {/* END Competitor maps */}

        {/* Brand archetypes */}
        {selectedTool?.data['brandArchetypes'] && selectedTool?.data['brandArchetypes']?.selected && (
          <div className="page">
            <div className="inner content-page-inner">
              {myAgency && images && <img className="agency-logo-top" src={images[myAgency!.logo]} alt={myAgency?.name} /> }
              <div className="d-flex heading">
                  <h3>Brand Archetype</h3>
              </div>
              <div className="d-flex">
                
                {selectedTool?.data['brandArchetypes']['brandArchetypes']?.filter((x: any) => x.key === selectedTool?.data['brandArchetypes']['selected']).map(function(object: any, i: number) {
                  return (
                    <div className="group" key={i}>
                      <div className="sub-heading">{object.group}</div>
                      <div className="ng-container">
                        <div className="ng-container">
                          <div className="archetype">
                            <img src={`/app/images/archetypes/${object.image}`} alt={object.name} />
                            <div className="sub-heading">{object.name}</div>
                            <p>Motto: {object.motto}</p>
                            <p className="approach">Brand Approach: {object.broadApproach}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  )
                })}
                
              </div>
              <footer className="footer">
                <span>© {myAgency?.name} {year}. Powered by Brandvas.</span>
              </footer>
            </div>
          </div>
        )}
        {/* END Brand archetypes */}

        {/* Brand strengths */}
        {selectedTool?.data['brandStrengths'] && selectedTool?.data['brandStrengths']?.selected && (
          <div className="page">
            <div className="inner content-page-inner">
              {myAgency && images && <img className="agency-logo-top" src={images[myAgency!.logo]} alt={myAgency?.name} /> }
              <div className="d-flex heading">
                  <h3>Brand Strengths and Vulnerabilities</h3>
              </div>
              <div className="d-flex">
                <div className="brand-strengths">
                  <p>Your Differentiated Brand Value Proposition is found at the intersection of what you do better than anyone else, that your customer cares about deeply, and is not a strength of your competitors</p>
                  <img src="/app/images/strengths-and-weaknesses/brand-strengths.png" alt="Brand Strengths" />
                </div>
              </div>
              <footer className="footer">
                <span>© {myAgency?.name} {year}. Powered by Brandvas.</span>
              </footer>
            </div>
          </div>
        )}
        {selectedTool?.data['categoryTableStakes'] && selectedTool?.data['categoryTableStakes']?.length > 0 && (
          <div className="page">
            <div className="inner content-page-inner">
              {myAgency && images && <img className="agency-logo-top" src={images[myAgency!.logo]} alt={myAgency?.name} /> }
              <div className="d-flex heading">
                  <h3>Your Category Table Stakes</h3>
              </div>
              <div className="d-flex">
                <div className="column">
                  <p>The overlap between your brand strengths (what you excel at), your customers' most important needs and your competitors’ greatest strengths.</p>
                  <p className="break">Category table stakes are the things everyone is competing on. Even though they may be your strengths, these are the things your customers will expect you to deliver. Whilst you must deliver on these customer expectations, these will not provide a competitive advantage to your brand.</p>

                  {selectedTool?.data['categoryTableStakes'].map(function(object: any, i: number) {
                    return (
                      <p key={i}>
                        <span>{object.name}</span>
                      </p>
                    )
                  })}

                </div>
                <div className="column hero-image">
                  <img src="/app/images/strengths-and-weaknesses/category-table-stakes.png" alt="Category table stakes" />
                </div>
              </div>
              <footer className="footer">
                <span>© {myAgency?.name} {year}. Powered by Brandvas.</span>
              </footer>
            </div>
          </div>
        )}
        {selectedTool?.data['brandVulnerabilities'] && selectedTool?.data['brandVulnerabilities']?.length > 0 && (
          <div className="page">
            <div className="inner content-page-inner">
              {myAgency && images && <img className="agency-logo-top" src={images[myAgency!.logo]} alt={myAgency?.name} /> }
              <div className="d-flex heading">
                  <h3>Your Brand Vulnerabilities</h3>
              </div>
              <div className="d-flex">
                <div className="column">
                  <p>The overlap between your customers’ most important needs and your competitors' greatest strengths. These are things you do not excel at.</p>
                  <p className="break">By identifying these vulnerabilities for your brand we can understand how our competitors may best position their brands, and how we should accordingly position ours to compete on our own strengths. We should not be drawn into competing on the strengths of our competitors (even if the rest of the market is), especially when these are our vulnerabilities.</p>

                  {selectedTool?.data['brandVulnerabilities'].map(function(object: any, i: number) {
                    return (
                      <p key={i}>
                        <span>{object.name}</span>
                      </p>
                    )
                  })}

                </div>
                <div className="column hero-image">
                  <img src="/app/images/strengths-and-weaknesses/brand-vulnerabilities.png" alt="Brand vulnerabilities" />
                </div>
              </div>
              <footer className="footer">
                <span>© {myAgency?.name} {year}. Powered by Brandvas.</span>
              </footer>
            </div>
          </div>
        )}
        {selectedTool?.data['noWinZone'] && selectedTool?.data['noWinZone']?.length > 0 && (
          <div className="page">
            <div className="inner content-page-inner">
              {myAgency && images && <img className="agency-logo-top" src={images[myAgency!.logo]} alt={myAgency?.name} /> }
              <div className="d-flex heading">
                  <h3>No Win Zone</h3>
              </div>
              <div className="d-flex">
                <div className="column">
                  <p>The overlap between your brand strengths and your competitors brand strengths that doesn’t overlap with your customers’ needs is the No Win Zone.</p>
                  <p>Often categories will form habits of building products and services in this area simply because they are good at it.</p>
                  <p className="break">However, there is nothing to be gained in investing in the No Win Zone and your brand positioning should avoid it at all costs.</p>

                  {selectedTool?.data['noWinZone'].map(function(object: any, i: number) {
                    return (
                      <p key={i}>
                        <span>{object.name}</span>
                      </p>
                    )
                  })}

                </div>
                <div className="column hero-image">
                  <img src="/app/images/strengths-and-weaknesses/no-win-zone.png" alt="No win zone" />
                </div>
              </div>
              <footer className="footer">
                <span>© {myAgency?.name} {year}. Powered by Brandvas.</span>
              </footer>
            </div>
          </div>
        )}
        {selectedTool?.data['brandDifferentiation'] && selectedTool?.data['brandDifferentiation']?.selected && selectedTool?.data['brandDifferentiation']?.selected.length > 0 && (
          <div className="page">
            <div className="inner content-page-inner">
              {myAgency && images && <img className="agency-logo-top" src={images[myAgency!.logo]} alt={myAgency?.name} /> }
              <div className="d-flex heading">
                  <h3>Key Brand Differentiators</h3>
              </div>
              <div className="d-flex">
                <div className="column">

                  <p>Where your brand strengths overlap with your customers’ greatest needs, and that don’t overlap with your competitors’ strengths.</p>
                  <p className="break">These provide the basis for your Differentiated Brand Value Proposition. By understanding these, and which ones reflect the passions and natural strengths of the organisation you can focus in on what will become the foundation for a powerfully differentiated brand.</p>

                  {selectedTool?.data['brandDifferentiation']['selected'].map(function(object: any, i: number) {
                    return (
                      <p key={i}>
                        <span>{object.name}</span>
                      </p>
                    )
                  })}

                </div>
                <div className="column hero-image">
                  <img src="/app/images/strengths-and-weaknesses/brand-differentiation.png" alt="Brand differentiation" />
                </div>
              </div>
              <footer className="footer">
                <span>© {myAgency?.name} {year}. Powered by Brandvas.</span>
              </footer>
            </div>
          </div>
        )}
        {selectedTool?.data['differentiatedBrandValueProposition'] && selectedTool?.data['differentiatedBrandValueProposition']?.valueProposition && (
          <div className="page">
            <div className="inner content-page-inner">
              {myAgency && images && <img className="agency-logo-top" src={images[myAgency!.logo]} alt={myAgency?.name} /> }
              <div className="d-flex heading">
                  <h3>Differentiated Brand Value Proposition</h3>
              </div>
              <div className="d-flex">
                <div className="column">
                  <div className="sub-heading">Differentiated value proposition.</div>
                  <p>{selectedTool?.data['differentiatedBrandValueProposition']?.valueProposition}</p>
                </div>
              </div>
              <footer className="footer">
                <span>© {myAgency?.name} {year}. Powered by Brandvas.</span>
              </footer>
            </div>
          </div>
        )}
        {/* END Brand strengths */}

        {/* Personality attributes */}
        {(primaryAttributes.length > 0 || secondaryAttributes.length > 0)  && (
          <div className="page">
            <div className="inner content-page-inner">
              {myAgency && images && <img className="agency-logo-top" src={images[myAgency!.logo]} alt={myAgency?.name} /> }
              <div className="d-flex heading">
                  <h3>Personality attributes</h3>
              </div>
              <div className="d-flex">
                {primaryAttributes.length > 0 && (
                  <div className="column">
                    <h5>Primary Personality attributes</h5>
                    {primaryAttributes.map(function(object: any, i: number) {
                      return (
                        <div key={i}>
                          <p><span>{object.summary}</span>: {object.personalities.join(', ')}</p>
                        </div>
                      )
                    })}
                  </div>
                )}
                {secondaryAttributes.length > 0 && (
                  <div className="column">
                    <h5>Secondary Personality attributes</h5>
                    {secondaryAttributes.map(function(object: any, i: number) {
                      return (
                        <div key={i}>
                          <p><span>{object.summary}</span>: {object.personalities.join(', ')}</p>
                        </div>
                      )
                    })}
                  </div>
                )}
              </div>
              <footer className="footer">
                <span>© {myAgency?.name} {year}. Powered by Brandvas.</span>
              </footer>
            </div>
          </div>
        )}
        {/* END Personality attributes */}


        {/* Brand Values */}
        {brandValues && (
          <div className="page">
            <div className="inner content-page-inner">
              {myAgency && images && <img className="agency-logo-top" src={images[myAgency!.logo]} alt={myAgency?.name} /> }
              <div className="d-flex heading">
                  <h3>Brand Values</h3>
              </div>
              <div className="d-flex">
                <div className="column">
                  {brandValues['first'].map(function(object: any, j: number) {
                    return (
                      <div key={j}>
                        <p><span>{object.headline}</span>: {object.description}</p>
                      </div>
                    )
                  })}
                </div>
                <div className="column">
                  {brandValues['second'] && (
                    <div>
                      {brandValues['second'].map(function(object: any, j: number) {
                        return (
                          <div key={j}>
                            <p><span>{object.headline}</span>: {object.description}</p>
                          </div>
                        )
                      })}
                    </div>
                  )}
                </div>
              </div>
              <footer className="footer">
                <span>© {myAgency?.name} {year}. Powered by Brandvas.</span>
              </footer>
            </div>
          </div>
        )}
        {/* END Brand Values */}

        {/* Brand Essence */}
        {brandValues && selectedTool?.data['brandEssenceRefinement'] && selectedTool?.data['brandEssenceRefinement'].final  && (
          <div className="page">
            <div className="inner content-page-inner">
              {myAgency && images && <img className="agency-logo-top" src={images[myAgency!.logo]} alt={myAgency?.name} /> }
              <div className="d-flex heading">
                  <h3>Brand Essence</h3>
              </div>
              <div className="d-flex">
                <div className="essence-container">
                  <div className="essence">{selectedTool?.data['brandEssenceRefinement'].final[0].name}</div>
                    {brandValues['first'].map(function(object: any, i: number) {
                      return (
                        <div key={i} className={`value ${i % 2 === 0 ? i >= 2 ? 'left bottom' : 'left' : i >= 2 ? 'bottom' : ''}`}>
                          {object['headline']}
                        </div>
                      )
                    })}
                </div>
              </div>
              <footer className="footer">
                <span>© {myAgency?.name} {year}. Powered by Brandvas.</span>
              </footer>
            </div>
          </div>
        )}
        {/* END Brand Essence */}

        {/* Brand Proof Points */}
        {brandProofPoints && brandProofPoints.length > 0 && (
          <div className="page-group">
            {brandProofPoints.map(function(proof: any, i: number) {
              return (
                <div key={i} className="page">
                  <div className="inner content-page-inner">
                    {myAgency && images && <img className="agency-logo-top" src={images[myAgency!.logo]} alt={myAgency?.name} /> }
                    <div className="d-flex heading">
                        <h3>Brand Proof Points</h3>
                    </div>
                    <div className="d-flex">
                      <div className="column">
                        {proof['first'].map(function(object: any, j: number) {
                          return (
                            <div key={j}>
                              {!object['question'] && (
                                <p>
                                  {object['headline'] && <span>{object['headline']}: </span>} {object['description']}
                                </p>
                              )}
                              {object['question'] && (
                                <div>
                                  <p><span>{object['headline'] && <span>{object['headline']}</span>}</span></p>
                                  <p>
                                    {object['description']}
                                  </p>
                                </div>
                              )}
                            </div>
                          )
                        })}
                      </div>
                      {proof['second'] && (
                        <div className="column">
                          {proof['second'].map(function(object: any, j: number) {
                            return (
                              <div key={j}>
                                {!object['question'] && (
                                  <p>
                                    {object['headline'] && <span>{object['headline']}: </span>} {object['description']}
                                  </p>
                                )}
                                {object['question'] && (
                                  <div>
                                    <p><span>{object['headline'] && <span>{object['headline']}</span>}</span></p>
                                    <p>
                                      {object['description']}
                                    </p>
                                  </div>
                                )}
                              </div>
                            )
                          })}
                        </div>
                      )}
                    </div>
                    <footer className="footer">
                      <span>© {myAgency?.name} {year}. Powered by Brandvas.</span>
                    </footer>
                  </div>
                </div>
              )
            })}
          </div>
        )}
        {/* END Brand Proof Points */}

        {/* Elevator Pitch */}
        {selectedTool?.data['elevatorPitch'] && (
          <div className="page">
            <div className="inner content-page-inner">
              {myAgency && images && <img className="agency-logo-top" src={images[myAgency!.logo]} alt={myAgency?.name} /> }
              <div className="d-flex heading">
                  <h3>Brand Positioning Statement (Elevator Pitch)</h3>
              </div>
              <div className="d-flex">
                <p className="pitch">
                  <span>We are the only</span> {selectedTool?.data['elevatorPitch']['category']} <span>brand in</span> {selectedTool?.data['elevatorPitch']['region']} <span>that provides </span>{selectedTool?.data['elevatorPitch']['product']} <span>to</span> {selectedTool?.data['elevatorPitch']['customer']}.
                </p>
              </div>
              <footer className="footer">
                <span>© {myAgency?.name} {year}. Powered by Brandvas.</span>
              </footer>
            </div>
          </div>
        )}
        {/* END Elevator Pitch */}

        {/* Custom exercises */}
        {selectedTool?.data['exercises'] && selectedTool?.data['custom'] && (
          <div className="page-group">
            {selectedTool?.data['exercises'].filter((x: any) => x['custom'] === true).map(function(exercise: any, j: number) {
              return (
                <div className="page">
                  <div className="inner content-page-inner">
                    {myAgency && images && <img className="agency-logo-top" src={images[myAgency!.logo]} alt={myAgency?.name} /> }
                    <div className="d-flex heading">
                      <h3>{exercise['name']}</h3>
                    </div>
                    <div className="d-flex">

                      {selectedTool?.data['custom'][exercise['key']] && (

                        <div>
                          {selectedTool?.data['custom'][exercise['key']]['questions'].map(function(object: any, j: number) {
                            return (
                              <div key={j}>
                                <p className="break">
                                  <span>{object['question']}</span>
                                  <br />
                                  {object['answer']}
                                </p>
                              </div>
                            )
                          })}
                        </div>
                      )}
                    </div>
                    <footer className="footer">
                      <span>© {myAgency?.name} {year}. Powered by Brandvas.</span>
                    </footer>
                  </div>
                </div>
              )
            })}
          </div>
        )}
        {/* END Custom exercises */}

        {/* Brand canvas */}
        <div className="page">
          <div className="inner content-page-inner canvas">
            <div className="d-flex heading">
              <h3>Brand Canvas</h3>
            </div>
            <div className="main-box">
              <div className="bar-left">
                <div className="inner-bar">
                  {canvasCoreCustomerData && (
                    <div>
                      <h6>CORE CUSTOMER / CLIENT</h6>
                      {canvasCoreCustomerData.map(function(object, i) {
                        return (
                          <p>
                            <span>{object['headline']}</span> {object['description']}
                          </p>
                        )
                      })}
                    </div>
                  )}
                </div>
              </div>
              <div className="centre-box">
                <div className="centre-inner">
  
                  <h6>BRAND PROOF POINTS</h6>

                  <div className="centre-content">
                    <div className="centre-text">

                      {canvasBrandProofPoints && canvasBrandProofPoints.length > 0 && (
                        <div className="left">
                          {canvasBrandProofPoints[0].map(function(object: any, i: number) {
                            return (
                              <p key={i}>
                                {object}
                              </p>
                            )
                          })}
                        </div>
                      )}
                      {canvasBrandProofPoints && canvasBrandProofPoints.length > 1 && (
                        <div className="right">
                          {canvasBrandProofPoints[1].map(function(object: any, i: number) {
                            return (
                              <p key={i}>
                                {object}
                              </p>
                            )
                          })}
                        </div>
                      )}
                    </div>
                  </div>

                </div>
                <div className="centre-inner bottom">
                  <h6>BRAND MESSAGES – TBC</h6>
                </div>

                <div className="bar">
                  <div className="inner-bar">
                      <h6>BRAND STRENGTHS</h6>
                      {selectedTool?.data['brandStrengths'] && selectedTool?.data['brandStrengths']['selected'] && selectedTool?.data['brandStrengths']['selected']?.length > 0 && (
                        <div>
                          {selectedTool?.data['brandStrengths']['selected']?.map(function(object: any, i: number) {
                            return (
                              <p key={i}>{object['name']}</p>
                            )
                          })}
                        </div>
                      )}
                  </div>
                </div>

                <div className="middle-box">
                  <div className="centre-inner yellow-box">
                    <div className="centre-content">

                      {brandValues && brandValues['first'] && (
                        <div className="centre-text left">
                          <ul>
                            {brandValues['first'][0] && <li><span>{brandValues['first'][0]['headline']}</span></li>}
                            {brandValues['first'][1] && <li><span>{brandValues['first'][1]['headline']}</span></li>}
                          </ul>
                        </div>
                      )}
                      {brandValues && brandValues['first'] && brandValues['first'][2] && (
                        <div className="centre-text right">
                          <ul>
                            {brandValues['first'][2] && <li><span>{brandValues['first'][2]['headline']}</span></li>}
                            {brandValues['first'][3] && <li><span>{brandValues['first'][3]['headline']}</span></li>}
                          </ul>
                        </div>
                      )}

                    </div>
                    <h6 className="bottom">BRAND VALUES</h6>
                  </div>
                  <div className="round">
                    <div>
                      <h6>ESSENCE</h6>
                      <p>{selectedTool?.data.brandProofPoints['brandEssence']}</p>
                    </div>
                  </div>
                  <div className="centre-inner centre-bottom yellow-box">
                    <h6>PERSONALITY</h6>
                    <div className="centre-content">


                      {primaryAttributes.length > 0 && (
                        <div className="centre-text left">
                          {primaryAttributes.map(function(object: any, i: number) {
                            return (
                              <div key={i}>
                                <p><span>{object.summary}</span></p>
                              </div>
                            )
                          })}
                        </div>
                      )}
                      {secondaryAttributes.length > 0 && (
                        <div className={`centre-text ${primaryAttributes.length > 0 ? 'right' : 'left'}`}>
                          {secondaryAttributes.map(function(object: any, i: number) {
                            return (
                              <div key={i}>
                                <p><span>{object.summary}</span></p>
                              </div>
                            )
                          })}
                        </div>
                      )}

                    </div>
                  </div>
                </div>

                <div className="bar">
                  <div className="inner-bar">
                    <h6>BRAND DIFFERENTIATORS</h6>

                    {selectedTool?.data['brandDifferentiation'] && selectedTool?.data['brandDifferentiation']?.selected && selectedTool?.data['brandDifferentiation']?.selected.length > 0 && (
                      <div>
                        {selectedTool?.data['brandDifferentiation']['selected'].map(function(object: any, i: number) {
                          return (
                            <p key={i}>
                              {object.name}
                            </p>
                          )
                        })}
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className="bar-right">
                <div className="inner-bar top">
                  <h6>DIFFERENTIATED BRAND<br />VALUE PROPOSITION</h6>
                  {selectedTool?.data['differentiatedBrandValueProposition'] && selectedTool?.data['differentiatedBrandValueProposition']?.valueProposition && (
                    <p>{selectedTool?.data['differentiatedBrandValueProposition'].valueProposition}</p>
                  )}
                </div>
                <div className="inner-bar bottom">
                  <h6>BRAND POSITIONING<br /> STATEMENT</h6>
                  {selectedTool?.data['elevatorPitch'] && (
                    <p>
                      <span>We are the only</span> {selectedTool?.data['elevatorPitch']['category']} <span>brand in</span> {selectedTool?.data['elevatorPitch']['region']} <span>that provides </span>{selectedTool?.data['elevatorPitch']['product']} <span>to</span> {selectedTool?.data['elevatorPitch']['customer']}.
                    </p>
                  )}
                </div>
              </div>

            </div>
          </div>
        </div>
        {/* END Brand canvas */}

        {/* What's Next */}
        <div className="page coloured-page blue-page">
          <div className="inner">
            <h1>What's Next</h1>
            <img src="/app/images/reports/rocket.png" />
            <footer className="footer">
              <span>© {myAgency?.name} {year}. Powered by Brandvas.</span>
            </footer>
          </div>
        </div>
        {/* END What's Next */}

        {/* Implementation */}
        <div className="page coloured-page blue-page">
          <div className="inner">
            <h1>Implementation</h1>
            <p>This brand strategy is a valuable business tool, but by itself will not produce results. This is the foundation for you to build an engaging and powerful new brand through.</p>
            <ul>
              <li>01. Brand Presence &amp; Communication</li>
              <li>02. Brand Engagement</li>
              <li>03. Brand Application</li>
            </ul>
            <footer className="footer">
              <span>© {myAgency?.name} {year}. Powered by Brandvas.</span>
            </footer>
          </div>
        </div>
        {/* END Implementation */}

        {/* Brand Presence */}
        <div className="page coloured-page red-page">
          <div className="inner">
            <h1>Brand Presence</h1>
            <p>Your brand presence is the way your brand connects with all of your external audiences including; clients or customers and other stakeholders. All brands have a presence, but most have not identified and influenced what it is they are communicating through these many touchpoints.</p>
            <p>Your brand touchpoints include all of your above and below the line advertising and marketing, social marketing, digital assets including your web site, product packaging, signage and environmental design, brochures and other brand communications.</p>
            <p>The most powerful and effective brands are those that understand who they are and communicate that proposition clearly and consistently through everything they do. Your Brand Report (summarized in your Brand Canvas) provides all the layers of definition you need to direct the implementation of your brand across every one of your touchpoints.</p>
            <footer className="footer">
              <span>© {myAgency?.name} {year}. Powered by Brandvas.</span>
            </footer>
          </div>
        </div>
        {/* END Brand Presence */}


        {/* On-Brand Mindset */}
        <div className="page coloured-page yellow-page">
          <div className="inner">
            <h1>On-Brand Mindset</h1>
            <p>Nothing undermines a brand as quickly as when it promises one thing and delivers another through its words and deeds. This Brand Report provides the layers of direction required so that every part of your business can have clarity on what and how they should be doing their job. This clarity will provide a critical, consistent thread of brand positioning.</p>
            <p>We can help you with this by working with each level of the business to define a set of proof points specifically to direct their actions.</p>
            <footer className="footer">
              <span>© {myAgency?.name} {year}. Powered by Brandvas.</span>
            </footer>
          </div>
        </div>
        {/* END On-Brand Mindset */}

        {/* Internal Brand Engagement */}
        <div className="page coloured-page blue-page">
          <div className="inner">
            <h1>Internal Brand Engagement</h1>
            <p>Your people are your most important brand asset. An organization filled with staff who understand and are invested in its brand strategy has a powerful advantage over their competitors.</p>
            <p>Whilst staff operating in concert to present your brand positioning to your audience adds value to all a business does, a workforce without clear direction and understanding of your brand will have an equally negative impact.</p>
            <p>Engaging your internal stakeholders in the brand journey is an important step in transforming your brand. Your staff should have a clear understanding of the strategy behind your brand and be inspired by the purpose that sits behind it. The vast majority of staff are naturally motivated to do a good job, the role of your brand strategy is to provide clear direction and a framework of understanding to allow them to get-on and do a great job of building your brand and business.</p>
            <footer className="footer">
              <span>© {myAgency?.name} {year}. Powered by Brandvas.</span>
            </footer>
          </div>
        </div>
        {/* END Internal Brand Engagement */}

        {/* Brand Messaging */}
        <div className="page coloured-page red-page">
          <div className="inner">
            <h1>Brand Messaging</h1>
            <p>What a brand chooses to say (and not say) as well as the language with which it speaks are another important layer of the way a brand comes to life. These brand messages appear on web site, in marketing materials, on packaging, sales materials, internal and external newsletter, on bills and invoices, training manuals as well as through customer support teams.</p>
            <p>In most organisations the responsibility for brand communication is shared by many staff across multiple teams. It is important for a brand once it has a clear strategy to establish a unique and consistent tone of voice and communications strategy.</p>
            <p>We can help by translating your brand personality into a unique brand voice and your brand proof points into key brand messages to work across each and every part of your business.</p>
            <footer className="footer">
              <span>© {myAgency?.name} {year}. Powered by Brandvas.</span>
            </footer>
          </div>
        </div>
        {/* END Brand Messaging */}

        {/* Background */}
        <div className="page end-page">
          <div className="inner">
            <img className="blue-background-image" alt="" src="/app/images/reports/background-blue.png"/>
            <img className="logo" src="/app/images/reports/brandvas-logo.png" alt="Brandvas logo" />
            <footer className="footer">
              <span>© {myAgency?.name} {year}. Powered by Brandvas.</span>
              {myAgency && images && <img className="agency" src={images[myAgency!.logo]} alt={myAgency?.name} /> }
            </footer>
          </div>
        </div>
        {/* END Background */}
        
      </div>
    </div>
  );
};

export default BrandStrategyDesigned;
