import React from "react"
import ContentLoader from 'react-content-loader'

const ResourcesLoader: React.FC = () => {
  return (
    <div className="call-to-action-loader">
      <ContentLoader width={"100%"} height={376}>
        <rect x="5" y="50" rx="5" ry="5" width="75%" height="30" />
        <rect x="5" y="90" rx="5" ry="5" width="50%" height="40" />
        <rect x="5" y="150" rx="5" ry="5" width="50%" height="15" />
        <rect x="5" y="175" rx="5" ry="5" width="55%" height="15" />
        <rect x="5" y="200" rx="5" ry="5" width="60%" height="15" />
        <rect x="5" y="225" rx="5" ry="5" width="50%" height="15" />
        <rect x="5" y="250" rx="5" ry="5" width="55%" height="15" />
        <rect x="5" y="275" rx="5" ry="5" width="50%" height="15" />
        <rect x="5" y="300" rx="5" ry="5" width="55%" height="15" />
        <rect x="5" y="325" rx="5" ry="5" width="60%" height="15" />
        <rect x="70%" y="40%" rx="5" ry="5" width="25%" height="50%" />
      </ContentLoader>
    </div>
  );
};

export default ResourcesLoader;
  