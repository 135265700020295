import React from "react";
import { useSelector } from 'react-redux';
import { useDispatch } from "react-redux";
import { State } from "../../store/shared/state";
import { ToolType } from "../../store/tools/types";
import { useTranslation } from "react-i18next";
import { ITemplate } from "../../store/templates/types";
import { hideTemplateMenu, selectTemplate, toggleEditTemplate, toggleTemplateMenu } from "../../store/templates/actions";

type Props = {
  template: ITemplate
};

export const ToolMenu: React.FC<Props> = ({template}) => {

  const { t } = useTranslation();
  const dispatch = useDispatch();

  const templateMenuOpened = useSelector((state: State) => state.templates.templateMenuOpened);
  const selectedProject = useSelector((state: State) => state.projects.selected);
  const typeName = t('tools.types.' + template.type.toString());

  const goToTemplate = () => {
    var url = `${process.env.REACT_APP_LEGACY_BASE_URL}/templates`;

    switch(template.type) {

      case ToolType.BRAND_STRATEGY:
        url += '/brand-strategy/';
        break;

      case ToolType.MEDIA_BRIEF:
        url += '/media-brief/';
        break;

      case ToolType.CREATIVE_BRIEF:
        url += '/creative-brief/';
        break;

      default:
        break;
    }
    
    url += selectedProject!.uuid['value'] + '/' + template.uuid['value'];

    window.location.href = url;
  }

  return (
   
    <div className={templateMenuOpened !== null && template.uuid['value'] === templateMenuOpened['value'] ? "context-menu red active" : "context-menu red"}>
        <div className="header">
          <span>Actions</span><span className="action" onClick={() => { dispatch(toggleTemplateMenu(template.uuid)) }}><i className="fas fa-chevron-up"></i></span>
        </div>

        <div className="content">
          <div className="selectable-row" key="edit-template" onClick={goToTemplate}>
            {t('templateMenu.edit', { type: typeName })}
          </div>
          <div className="selectable-row" key="edit-tool" onClick={() => { dispatch(hideTemplateMenu()); dispatch(selectTemplate(template)); dispatch(toggleEditTemplate());}}>
            {t('templateMenu.rename')}
          </div>
        </div>

    </div>
  );
};
export default ToolMenu;