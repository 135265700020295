import React, { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { listClients, toggleSelectClient } from '../../store/clients/actions';
import { State } from '../../store/shared/state';
import './Header.scss';
import SelectClient from './SelectClient';
import { getQueryString } from '../../shared/queryString';
import AddClient from './AddClient';
import { allowSuperuser, getInitialsFromToken, logout } from '../../shared/auth';
import { getMyAgency } from '../../store/agencies/actions';
import { toggleAccountMenu, toggleMobileMenu } from '../../store/general/actions';

export const Header: React.FC = () => {

  const { t } = useTranslation();
  const dispatch = useDispatch();

  const selectedClient = useSelector((state: State) => state.clients.selected);
  const mobileMenuOpened = useSelector((state: State) => state.general.mobileMenuOpened);
  const accountMenuOpened = useSelector((state: State) => state.general.accountMenuOpened);
  const selectClientToggle = useRef(null);

  const myAgency = useSelector((state: State) => state.agencies.myAgency);

  useEffect(() => {
    listClients(dispatch); 
    getMyAgency(dispatch); 
    // eslint-disable-next-line 
  }, []);

  const doToggleSelectClient = () => dispatch(toggleSelectClient());
  const doToggleMobileMenu = () => dispatch(toggleMobileMenu());
  const doToggleAccountMenu = () => dispatch(toggleAccountMenu());
  const doLogout = () => {
    dispatch(toggleMobileMenu());
    logout();
  }

  return (
    <header>
      <section className="container container-xxxl container-header">
        <div className="row">
          <div className="col-12 col-lg-3 left-column">
              <NavLink title={t('header.dashboard')} to={'/'} >
                <img id="logo" className="d-none d-lg-block" alt={t('header.logo')} src="/app/images/brandvas-logo.png" />
                <img id="logo" className="d-lg-none" alt={t('header.logo')} src="/app/images/brandvas-logo-small.png" />
              </NavLink>
              <i className={mobileMenuOpened !== true ? "fas fa-bars d-lg-none active" : "fas fa-bars d-lg-none"}  id="mobile-menu-trigger" onClick={doToggleMobileMenu}></i>
              <i className={mobileMenuOpened === true ? "fas fa-times d-lg-none active" : "fas fa-times d-lg-none"} id="mobile-menu-close" onClick={doToggleMobileMenu}></i>
              <div className="d-xl-none mobile-select">
                <button className="headerSelectClientToggle" ref={selectClientToggle} onClick={doToggleSelectClient}><span>{selectedClient?.name}</span><i className="fas fa-chevron-down" /></button>
              </div>
            </div>
            <div className="col-auto d-none d-xl-block">
              <button className="headerSelectClientToggle" id="headerSelectClientToggle" ref={selectClientToggle} onClick={doToggleSelectClient}><span>{selectedClient?.example && <span>{t('header.selectClient')}</span>}{!selectedClient?.example && selectedClient?.name}</span><i className="fas fa-chevron-down" /></button>
              <SelectClient selectedClient={selectedClient} />
            </div>
            <div className="d-none d-lg-block col-12 col-sm">
              <div className="row justify-content-end">
                <span className="nav col-12 col-sm-auto"><a title={t('header.agency')} href={process.env.REACT_APP_LEGACY_BASE_URL + '/agency'}>{myAgency?.name}</a></span>
                {allowSuperuser() && <span className="nav col-12 col-sm-auto"><NavLink title={t('header.reporting')} to={'/reporting' + getQueryString()}>{t('header.reporting')}</NavLink></span>}
                <span className="nav col-12 col-sm-auto"><NavLink title={t('header.projects')} to={'/projects' + getQueryString()}>{t('header.projects')}</NavLink></span>
                {!selectedClient?.example && <span className="nav col-12 col-sm-auto"><NavLink title={t('header.toolkit')} to={'/toolkit' + getQueryString()}>{t('header.toolkit')}</NavLink></span>}
                <span className="nav col-12 col-sm-auto"><a title={t('header.resources')} rel="noreferrer" target="_blank" href="https://www.brandvas.io/how-to-use-brand-strategy-tools">{t('header.resources')}</a></span>
                <span className="nav avatar col-12 col-sm-auto d-none d-sm-block">
                  <button onClick={doToggleAccountMenu}>{getInitialsFromToken()}</button>
                  <div className={accountMenuOpened === true ? "active" : ""}>
                    <span title={t('header.logout')} onClick={doLogout}>{t('header.logout')}</span>
                  </div>
                </span>
              </div>
            </div>
          </div>   
          <div id="mobile-menu" className={mobileMenuOpened !== true ? "d-lg-none" : "d-lg-none active"}>
            <span className="mobile-nav"><a title={t('header.agency')} onClick={doToggleMobileMenu} href={process.env.REACT_APP_LEGACY_BASE_URL + '/agency'}>{myAgency?.name}</a></span>
            {allowSuperuser() && <span className="mobile-nav"><NavLink onClick={doToggleMobileMenu} title={t('header.reporting')} to={'/reporting' + getQueryString()}>{t('header.reporting')}</NavLink></span>}
            <span className="mobile-nav"><NavLink onClick={doToggleMobileMenu} title={t('header.projects')} to={'/projects' + getQueryString()}>{t('header.projects')}</NavLink></span>
            {!selectedClient?.example && <span className="mobile-nav"><NavLink onClick={doToggleMobileMenu} title={t('header.toolkit')} to={'/toolkit' + getQueryString()}>{t('header.toolkit')}</NavLink></span>}
            <span className="mobile-nav"><a title={t('header.resources')} rel="noreferrer" target="_blank" href="https://www.brandvas.io/how-to-use-brand-strategy-tools">{t('header.resources')}</a></span>
            <span className="mobile-nav"><span title={t('header.logout')} onClick={doLogout}>{t('header.logout')}</span></span>
          </div>
      </section>
      <AddClient />
    </header>
  );
}

export default Header;