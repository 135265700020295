import React from "react";
import { Modal } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import { State } from "../../store/shared/state";
import { Status } from "../../store/shared/status";
import { useForm, Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { addProject, toggleAddProject } from "../../store/projects/actions";
import { IUser } from "../../store/users/types";
import Select from 'react-select'
import { Guid } from "guid-typescript";

type FormData = {
  name: string;
  leaderId: string | undefined;
  jobNumber: string;
};

export const AddProject: React.FC = () => {

  const { t } = useTranslation();
  const { register, handleSubmit, control, formState: { errors }, reset, setValue } = useForm<FormData>();

  const selectedClient = useSelector((state: State) => state.clients.selected);

  const addProjectOpened = useSelector((state: State) => state.projects.addProjectOpened);
  const addProjectStatus = useSelector((state: State) => state.projects.addProjectStatus);
  const addProjectError = useSelector((state: State) => state.projects.addProjectError);

  const users = useSelector((state: State) => state.users.users.map((x: IUser) => {
    return {'value': x.uuid['value'], 'label': x.firstName + ' ' + x.lastName}
  }));

  const defaultValues = { name: '', jobNumber: '' , leader: undefined};
   
  const submitButtonText = addProjectStatus !== Status.LOADING 
    ? ( <span>{t('addProject.submit')} <i className="fas fa-chevron-right"></i></span> )
    : ( <span>{t('addProject.processing')} <i className="fas fa-spin fa-circle-notch"></i></span> );

  const dispatch = useDispatch();

  const onSubmit = (data: any, errors: any) => {
    addProject(dispatch, {
       uuid: Guid.create(), 
       dateCreated: new Date(),
       dateModified: new Date(),
       dateCommenced: new Date(),
       agencyId: selectedClient!.agencyId, 
       clientId: selectedClient!.uuid!, 
       name: data.name, 
       leaderId: data.leaderId, 
       jobNumber: data.jobNumber, 
       projectNotes: '',
       archived: false,
       tools: [],
       competitors: [],
       business: null,
       businessMetrics: null
      });
  }
  
  const onErrors = (errors: any) => console.error(errors);

  return (

    <Modal className="modal form-modal grey-modal"
      show={addProjectOpened}
      animation={false}
      onShow={() => reset(defaultValues)}
      onHide={() => dispatch(toggleAddProject())}
      centered
    >

      <Modal.Header>
        <Modal.Title>
          {t('addProject.title')}
          <span>{t('addProject.client')}: {selectedClient?.name}</span>
          </Modal.Title>
        <button type="button" data-bs-dismiss="modal" aria-label="Close" onClick={() => { dispatch(toggleAddProject()) }}><i className="fas fa-times"></i></button>
      </Modal.Header>

      <Modal.Body>
        <form onSubmit={handleSubmit(onSubmit, onErrors)}>
          <fieldset>
            <label>
              <div className="row">
                <div className="col-12 col-lg-6">{t('addProject.projectName')}</div>
                <div className="col-12 col-lg-6 error">{errors.name?.type === 'required' && t('form.required', { field: t('form.name') })}</div>
              </div>
            </label>
            <input type="text" {...register("name", { required: true, maxLength: 255 })} /> 

            <div className="row">
              <div className="col-12 col-xl-6">
                <label>
                  <div className="row">
                    <div className="col-12 col-lg-6">{t('addProject.leader')}</div>
                    <div className="col-12 col-lg-6 error">{errors.leaderId?.type === 'required' && t('form.required', { field: t('addProject.leader') })}</div>
                  </div>
                </label>
                
                <Controller
                    control={control}
                    name="leaderId"
                    rules={{ required: true }}
                    render={({ field }) => (
                        <Select
                          key={`leader-id-${field.value}`}
                            classNamePrefix="select"
                            options={users}
                            value={users.find(x => x.value === field.value)}
                            onChange={value => {
                              setValue("leaderId", value ? value.value : '', {
                                shouldDirty: true
                              });
                            }}
                        />
                    )}
                />

              </div>
              <div className="col-12 col-xl-6">
                <label>
                  <div className="row">
                    {(errors.jobNumber === null || errors.jobNumber === undefined) && <div className="col-12">{t('addProject.jobNumber')} {t('addProject.jobNumberNote')}</div>}

                    {errors.jobNumber?.type === 'unique' &&
                      <>
                        <div className="col-12 col-lg-5">{t('addProject.jobNumber')}</div>
                        <div className="col-12 col-lg-7 error">{errors.jobNumber?.type === 'unique' && t('form.uniqueGeneric') }</div>
                      </>
                    }

                  </div>
                </label>
                <input type="text" {...register("jobNumber", { required: false, maxLength: 255 })} /> 
              </div>
            </div>
            <p className="mb-5">{t('addProject.note')}</p>

            <div className="server-error">{t(addProjectError)}</div>

          </fieldset>
          <div className="actions">
            <button type="submit" disabled={addProjectStatus === Status.LOADING}>{submitButtonText}</button>
          </div>
        </form>

      </Modal.Body>
    </Modal>

  );
};
export default AddProject;