import React from "react"
import ContentLoader from 'react-content-loader'

const ToolsLoader: React.FC = () => {
  return (
    <div className="tools-loader">
      <ContentLoader width={"100%"} height={250}>

        <rect x="5" y="0" rx="5" ry="5" width="20%" height="25" />
        <rect x="28%" y="0" rx="5" ry="5" width="23%" height="25" />
        <rect x="60%" y="0" rx="5" ry="5" width="18%" height="25" />
        <rect x="90%" y="0" rx="5" ry="5" width="12%" height="25" />

        <rect x="5" y="50" rx="5" ry="5" width="20%" height="50" />
        <rect x="28%" y="50" rx="5" ry="5" width="23%" height="50" />
        <rect x="60%" y="50" rx="5" ry="5" width="18%" height="50" />
        <rect x="90%" y="50" rx="5" ry="5" width="12%" height="50" />

        <rect x="5" y="130" rx="5" ry="5" width="20%" height="50" />
        <rect x="28%" y="130" rx="5" ry="5" width="23%" height="50" />
        <rect x="60%" y="130" rx="5" ry="5" width="18%" height="50" />
        <rect x="90%" y="130" rx="5" ry="5" width="12%" height="50" />

        <rect x="5" y="210" rx="5" ry="5" width="20%" height="50" />
        <rect x="28%" y="210" rx="5" ry="5" width="23%" height="50" />
        <rect x="60%" y="210" rx="5" ry="5" width="18%" height="50" />
        <rect x="90%" y="210" rx="5" ry="5" width="12%" height="50" />

        
      </ContentLoader>
    </div>
  );
};

export default ToolsLoader;
  