import React from "react";
import { Modal } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import { State } from "../../store/shared/state";
import { Status } from "../../store/shared/status";
import { useForm, Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { addTool, hideAddTool } from "../../store/tools/actions";
import { ToolType } from "../../store/tools/types";
import Select from 'react-select'
import { Guid } from "guid-typescript";
import { ITemplate } from "../../store/templates/types";

type FormData = {
  name: string;
  templateId: string | undefined;
};

export const AddTool: React.FC = () => {

  const { t } = useTranslation();
  const { register, handleSubmit, control, formState: { errors }, reset, setValue } = useForm<FormData>();

  const selectedClient = useSelector((state: State) => state.clients.selected);
  const selectedProject = useSelector((state: State) => state.projects.selected);

  const selectedResearchType = useSelector((state: State) => state.tools.selectedResearchType);

  const addToolOpened = useSelector((state: State) => state.tools.addToolOpened);
  const addToolType = useSelector((state: State) => state.tools.addToolType);
  const addToolStatus = useSelector((state: State) => state.tools.addToolStatus);
  const addToolError = useSelector((state: State) => state.tools.addToolError);

  const templates = useSelector((state: State) => state.templates.templates.filter(x => x.type === addToolType).map((x: ITemplate) => {
    return {'value': x.uuid['value'], 'label': x.name}
  }));

  const defaultValues = { name: '', template: undefined};
   
  const submitButtonText = addToolStatus !== Status.LOADING 
    ? ( <span>{t('addTool.submit')} <i className="fas fa-chevron-right"></i></span> )
    : ( <span>{t('addTool.processing')} <i className="fas fa-spin fa-circle-notch"></i></span> );

  const dispatch = useDispatch();

  const typeName = t('tools.types.' + addToolType?.toString());

  const onSubmit = (data: any, errors: any) => {

    addTool(dispatch, {
      uuid: Guid.create(), 
      projectId: selectedProject!.uuid,
      dateCreated: new Date(),
      dateModified: new Date(),
      dateCommenced: new Date(),
      lastModifiedBy: "User",
      lastModifiedById: Guid.create(),
      type: addToolType!,
      name: data.name, 
      template: "",
      templateId: data.templateId,
      data: { researchType: selectedResearchType }
    });
  }
  
  const onErrors = (errors: any) => console.error(errors);

  return (

    <Modal className="modal form-modal yellow-modal"
      show={addToolOpened}
      animation={false}
      onShow={() => reset(defaultValues)}
      onHide={() => dispatch(hideAddTool())}
      centered
    >

      <Modal.Header>
        <Modal.Title>
          {t('addTool.title', { type: typeName })}
          <span>{t('addTool.client')}: {selectedClient?.name}</span>
          <span>{t('addTool.project')}: {selectedProject?.name}</span>
          </Modal.Title>
        <button type="button" data-bs-dismiss="modal" aria-label="Close" onClick={() => { dispatch(hideAddTool()) }}><i className="fas fa-times"></i></button>
      </Modal.Header>

      <Modal.Body>
        <form onSubmit={handleSubmit(onSubmit, onErrors)}>
          <fieldset>
            <label>
              <div className="row">
                <div className="col-12 col-lg-6">{t('addTool.name', { type: typeName })}</div>
                <div className="col-12 col-lg-6 error">{errors.name?.type === 'required' && t('form.required', { field: t('form.name') })}</div>
              </div>
            </label>
            <input type="text" {...register("name", { required: true, maxLength: 255 })} /> 

            {(addToolType === ToolType.BRAND_STRATEGY || addToolType === ToolType.MEDIA_BRIEF || addToolType === ToolType.CREATIVE_BRIEF) && 
              <div className="row">
                <div className="col-12">
                  <label>
                    <div className="row">
                      <div className="col-12 col-lg-6">{t('addTool.template')}</div>
                      <div className="col-12 col-lg-6 error">{errors.templateId?.type === 'required' && t('form.required', { field: t('addTool.template') })}</div>
                    </div>
                  </label>
                
                  <Controller
                      control={control}
                      name="templateId"
                      rules={{ required: true }}
                      render={({ field }) => (
                          <Select
                            key={`template-id-${field.value}`}
                              classNamePrefix="select"
                              options={templates}
                              value={templates.find(x => x.value === field.value)}
                              onChange={value => {
                                setValue("templateId", value ? value.value : '', {
                                  shouldDirty: true
                                });
                              }}
                          />
                      )}
                  />
              </div>
            </div>
            }
            <div className="server-error">{t(addToolError)}</div>

          </fieldset>
          <div className="actions">
            <button type="submit" disabled={addToolStatus === Status.LOADING}>{submitButtonText}</button>
          </div>

        </form>

      </Modal.Body>
    </Modal>

  );
};
export default AddTool;