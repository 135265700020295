import { ImagesState, ImagesAction } from "./types";

const initialState: ImagesState = {
  images: {}
}

const agenciesReducer = (state: ImagesState = initialState, action: ImagesAction): ImagesState => {

  switch (action.type) {

    case 'images/load-image-data-success':

      const images = JSON.parse(JSON.stringify(state.images));
      images[action.payload['uuid']] = action.payload['data'];

      return {
        ...state,
        images: images
      };
  }
  
  return state;
};

export default agenciesReducer;
