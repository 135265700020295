import React, { useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import Header from '../shared/Header';
import { State } from '../../store/shared/state';
import { Status } from '../../store/shared/status';
import Footer from '../shared/Footer';
import { useTranslation } from 'react-i18next';
import AddProject from './AddProject';
import { toggleAddProject } from '../../store/projects/actions';
import { listUsers } from '../../store/users/actions';
import { getAgencyIdFromToken } from '../../shared/auth';
import SubscriptionBanner from '../shared/SubscriptionBanner';
import { hideToolMenu } from '../../store/tools/actions';
import { useHistory } from 'react-router-dom';
import { getQueryString } from '../../shared/queryString';
import CompetitorsTitleLoader from './CompetitorsTitleLoader';
import CompetitorsTitle from './CompetitorsTitle';
import CompetitorsForm from './CompetitorsForm';

export const CompetitorsComponent: React.FC = () => {

  const { t } = useTranslation();

  const dispatch = useDispatch();
  const history = useHistory();

  const clientsStatus = useSelector((state: State) => state.clients.listClientsStatus);

  const projectsStatus = useSelector((state: State) => state.projects.listProjectsStatus);
  const projects = useSelector((state: State) => state.projects.projects);
  const selectedProject = useSelector((state: State) => state.projects.selected);

  const archivedProjectsStatus = useSelector((state: State) => state.projects.listArchivedProjectsStatus);
  const archivedProjects = useSelector((state: State) => state.projects.archivedProjects);

  const allProjectsOpen = useSelector((state: State) => state.projects.allProjectsOpen);
  const archivedProjectsOpen = useSelector((state: State) => state.projects.archivedProjectsOpen);

  const usersStatus = useSelector((state: State) => state.users.listUsersStatus);

  const goBack = () => {
    history.goBack();
  }
  
  const goToEditProjectSettings = () => {
    if(clientsStatus === Status.SUCCESS) {
      dispatch(hideToolMenu());
      dispatch(toggleAddProject());
    }
  }

  const goToCompetitors = () => {
    history.push({ pathname: '/competitors', search: getQueryString()});
  }

  const titleBlock = projectsStatus === Status.SUCCESS ? ( <CompetitorsTitle /> ) : ( <CompetitorsTitleLoader /> )

  return (
    <React.Fragment>
      <section className="container-fluid fake-header grey">
        <Header />
        <SubscriptionBanner />
        <section className="container container-xxxl container-body">
          <div className="row">
          <div className="col-12 col-lg-3 left-column">
              <h4 className="back" onClick={goBack}>
                <i className="fas fa-chevron-left"></i>
                {t('general.back')}
              </h4>
            </div>
            <div className="col-12 col-lg-9 right-column">
              {titleBlock}
            </div>
          </div>
        </section>
      </section>
      <section className="container-fluid">
        <section className="container container-xxxl container-body">
          <div className="row">
            <div className="col-12 col-lg-3 left-column">
              &nbsp;
            </div>
            <div className="col-12 col-lg-9 right-column">
              <CompetitorsForm />
            </div>
          </div>
        </section>
        <Footer />
      </section>
    </React.Fragment>
  );
}

export default CompetitorsComponent ;