
export const getImageData = async (key: string) : Promise<string> => {

    if(!key) {
        return new Promise((resolve, reject) => {
            reject(null);
        });
    }

    let url = `${process.env.REACT_APP_MEDIA_PATH}${key}`;

    const data = await fetch(url);
    const blob = await data.blob();
    
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(blob); 
        reader.onloadend = () => {
            const base64data = reader.result as string;   
            if(base64data) {
                resolve(base64data);
            } else {
                reject(null);
            }
        }
    });
}
     