import React, { useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import Header from '../shared/Header';
import { State } from '../../store/shared/state';
import { Status } from '../../store/shared/status';
import Footer from '../shared/Footer';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import SubscriptionBanner from '../shared/SubscriptionBanner';
import { getValueFromQueryString } from '../../shared/queryString';
import { listReports, toggleGenerateReport } from '../../store/reports/actions';
import DownloadsTitle from './DownloadsTitle';
import DownloadsTitleLoader from './DownloadsTitleLoader';
import BrandStrategyDownloadsList from './BrandStrategyDownloadsList';
import MediaBriefDownloadsList from './MediaBriefDownloadsList';
import DownloadsLoader from './DownloadsLoader';
import { ToolType } from '../../store/tools/types';
import './DownloadsComponent.scss';
import GenerateReport from "./GenerateReport";

export const DownloadsComponent: React.FC = () => {

  const { t } = useTranslation();

  const dispatch = useDispatch();
  const history = useHistory();

  const reportsStatus = useSelector((state: State) => state.reports.listReportsStatus);
  const reports = useSelector((state: State) => state.reports.reports);

  const selectedTool = useSelector((state: State) => state.projects.selectedTool);

  useEffect(() => {
    listReports(dispatch, getValueFromQueryString('tool'));
    // eslint-disable-next-line 
  }, [])

  const goBack = () => {
    history.goBack();
  }

  const titleBlock = reportsStatus === Status.LOADING ? ( <DownloadsTitleLoader /> ) : ( <DownloadsTitle /> )

  const downloadsLoadingBlock = reportsStatus === Status.LOADING ? ( <DownloadsLoader /> ) : null;
  const brandStategyDownloadsBlock = reportsStatus === Status.SUCCESS ? (<BrandStrategyDownloadsList typeKey='brandStrategy' reports={reports} />) : null;
  const mediaBriefDownloadsBlock = reportsStatus === Status.SUCCESS ? (<MediaBriefDownloadsList typeKey='mediaBrief' reports={reports} />) : null;

  const emptyBlock = reportsStatus === Status.SUCCESS && reports.length === 0 ? 
  (
    <React.Fragment>
      <p>{t('downloads.introduction')}</p>
      <div className="row">
        <div className="col-12 col-xl-6">
          <div className="call-to-action medium yellow-spacemen" onClick={() => dispatch(toggleGenerateReport())}>
            <h2>{t('downloads.generateDoc')}</h2>
            <h3>{t('downloads.docFormatExplanation')}</h3>
          </div>
        </div>
        <div className="col-12 col-xl-6">
          <div className="call-to-action medium red-computer" onClick={() => dispatch(toggleGenerateReport())}>
            <h2>{t('downloads.generatePdf')}</h2>
            <h3>{t('downloads.pdfFormatExplanation')}</h3>
          </div>
        </div>
      </div>
    </React.Fragment>
  ) : null;

  return (
    <React.Fragment>
      <section className="container-fluid fake-header yellow">
        <Header />
        <SubscriptionBanner />
        <section className="container container-xxxl container-body">
          <div className="row">
          <div className="col-12 col-lg-3 left-column">
              <h4 className="back" onClick={goBack}>
                <i className="fas fa-chevron-left"></i>
                {t('general.back')}
              </h4>
            </div>
            <div className="col-12 col-lg-9 right-column">
              {titleBlock}
            </div>
          </div>
        </section>
      </section>
      <GenerateReport />
      <section className="container-fluid">
        <section className="container container-xxxl container-body">
          <div className="row">
            <div className="col-12 col-lg-3 left-column">
            </div>
            <div className="col-12 col-lg-9 right-column">
              {downloadsLoadingBlock}
              {emptyBlock}
              {selectedTool?.type === ToolType.BRAND_STRATEGY && brandStategyDownloadsBlock}
              {selectedTool?.type === ToolType.MEDIA_BRIEF && mediaBriefDownloadsBlock}
            </div>
          </div>

        </section>
        <Footer />
      </section>
    </React.Fragment>
  );
}

export default DownloadsComponent;