import axios from '../../shared/http';
import { transformObjectFromString } from '../shared/transform';
import { AxiosResponse } from 'axios';
import { loadImageData } from '../images/actions';

const getSummaryLoading = () => ({ type: 'reporting/get-summary-loading' });
const getSummarySuccess = (payload: any) => ({ type: 'reporting/get-summary-success', payload});
const getSummaryError = (payload: any) => ({ type: 'reporting/get-summary-error', payload });

export const getSummary = async (dispatch: any) => {

  dispatch(getSummaryLoading());

  axios.get<any>(
    `${process.env.REACT_APP_REPORTING_SUMMARY_PATH}`,
    { transformResponse: (data: string) => transformObjectFromString<any>(data) })
    .then((response: AxiosResponse<any>) => {

      loadImageData(dispatch, response.data.logo);

      dispatch(getSummarySuccess(response.data));
    })
    .catch((error: any) => {
      // console.log(error);
      dispatch(getSummaryError(error));
    });
};
