import React from "react";
import { Modal } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import { State } from "../../store/shared/state";
import { Status } from "../../store/shared/status";
import { Controller, useForm, } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { cloneTool, toggleCloneTool } from "../../store/tools/actions";
import Select from 'react-select'
import { ToolType } from "../../store/tools/types";
import { IProject } from "../../store/projects/types";

type FormData = {
  projectId: string | undefined;
  name: string;
};

export const CloneTool: React.FC = () => {

  const { t } = useTranslation();
  const { register, handleSubmit, control, formState: { errors }, reset, setValue } = useForm<FormData>();

  const selectedClient = useSelector((state: State) => state.clients.selected);
  const selectedProject = useSelector((state: State) => state.projects.selected);
  const selectedTool = useSelector((state: State) => state.projects.selectedTool);

  const cloneToolOpened = useSelector((state: State) => state.tools.cloneToolOpened);
  const cloneToolStatus = useSelector((state: State) => state.tools.cloneToolStatus);
  const cloneToolError = useSelector((state: State) => state.tools.cloneToolError);

  const defaultValues = { projectId: undefined };
   
  const projects = useSelector((state: State) => state.projects.projects.filter(x => selectedTool?.type !== ToolType.BRAND_STRATEGY || x.name !== selectedProject?.name).map((x: IProject) => {
    return {'value': x.uuid['value'], 'label': x.name}
  }));

  const submitButtonText = cloneToolStatus !== Status.LOADING 
    ? ( <span>{t('cloneTool.submit')} <i className="fas fa-chevron-right"></i></span> )
    : ( <span>{t('cloneTool.processing')} <i className="fas fa-spin fa-circle-notch"></i></span> );

  const dispatch = useDispatch();

  const typeName = t('tools.types.' + selectedTool?.type.toString());

  const onSubmit = (data: any, errors: any) => {
    cloneTool(dispatch, selectedTool, { name: data.name, projectId: data.projectId });
  }
  
  const onErrors = (errors: any) => console.error(errors);

  return (

    <Modal className="modal form-modal yellow-modal"
      show={cloneToolOpened}
      animation={false}
      onShow={() => reset(defaultValues)}
      onHide={() => dispatch(toggleCloneTool())}
      centered
    >

      <Modal.Header>
        <Modal.Title>
          {t('cloneTool.title', { type: typeName })}
          <span>{selectedClient?.name} &gt; {selectedProject?.name} &gt; {selectedTool?.name}</span>
          </Modal.Title>
        <button type="button" data-bs-dismiss="modal" aria-label="Close" onClick={() => { dispatch(toggleCloneTool()) }}><i className="fas fa-times"></i></button>
      </Modal.Header>

      <Modal.Body>
        <form onSubmit={handleSubmit(onSubmit, onErrors)}>
          <fieldset>
            <label>
              <div className="row">
                <div className="col-12 col-lg-6">{t('cloneTool.project')}</div>
                <div className="col-12 col-lg-6 error">{errors.projectId?.type === 'required' && t('form.required', { field: t('cloneTool.project') })}</div>
              </div>
            </label>
  
            <Controller
                control={control}
                name="projectId"
                rules={{ required: true }}
                render={({ field }) => (
                    <Select
                      key={`project-id-${field.value}`}
                        classNamePrefix="select"
                        options={projects}
                        value={projects.find(x => x.value === field.value)}
                        onChange={value => {
                          setValue("projectId", value ? value.value : '', {
                            shouldDirty: true
                          });
                        }}
                    />
                )}
            />

            <label>
              <div className="row">
              <div className="col-12 col-lg-6">{t('cloneTool.name', { type: typeName })}</div>
                <div className="col-12 col-lg-6 error">{errors.name?.type === 'required' && t('form.required', { field: t('form.name') })}</div>
              </div>
            </label>
            <input type="text" {...register("name", { required: true, maxLength: 255 })} /> 

            <div className="server-error">{t(cloneToolError)}</div>

          </fieldset>
          <div className="actions">
            <button type="submit" disabled={cloneToolStatus === Status.LOADING}>{submitButtonText}</button>
          </div>

        </form>

      </Modal.Body>
    </Modal>

  );
};
export default CloneTool;