import { Guid } from 'guid-typescript';
import { AxiosResponse } from 'axios';
import axios from '../../shared/http';
import { normaliseErrorCode, transformList, transformObjectFromString } from '../shared/transform';
import { ITemplate, ITemplatesResponse } from './types';
import { toast } from 'react-toastify';
import i18next from 'i18next';

const addTemplateLoading = () => ({ type: 'templates/add-template-loading' });
const addTemplateSuccess = (payload: any) => ({ type: 'templates/add-template-success', payload});
const addTemplateError = (payload: any) => ({ type: 'templates/add-template-error', payload });

const listTemplatesLoading = () => ({ type: 'templates/list-templates-loading' });
const listTemplatesSuccess = (payload: any) => ({ type: 'templates/list-templates-success', payload});
const listTemplatesError = (payload: any) => ({ type: 'templates/list-templates-error', payload });

export const toggleTemplateMenu = (payload: Guid) => ({ type: 'templates/toggle-template-menu', payload });
export const hideTemplateMenu = () => ({ type: 'templates/toggle-template-menu', payload: null });

const editTemplateLoading = () => ({ type: 'templates/edit-template-loading' });
const editTemplateSuccess = (payload: any) => ({ type: 'templates/edit-template-success', payload});
const editTemplateError = (payload: any) => ({ type: 'templates/edit-template-error', payload });

export const toggleEditTemplate = () => ({ type: 'templates/toggle-edit-template'});
export const toggleAddTemplate = () => ({ type: 'templates/toggle-add-template' });

export const selectTemplate = (payload: ITemplate) => {
  return { type: 'templates/select-template', payload: payload }
};

export const listTemplates = async (dispatch: any, agencyId: Guid) => {

  dispatch(listTemplatesLoading());

  axios.get<ITemplatesResponse>(
    `${process.env.REACT_APP_TEMPLATES_PATH}?agencyId=${agencyId['value']}&sort=dateModified&order=desc`,
    { transformResponse: (data: string) => transformList<ITemplate>(data, 'templates') })
    .then((response: AxiosResponse<ITemplatesResponse>) => {
      dispatch(listTemplatesSuccess(response.data));

    })
    .catch((error: any) => {
      // console.log(error);
      dispatch(listTemplatesError(error));
    });
};


export const addTemplate = async (dispatch: any, data: ITemplate) => {

  dispatch(addTemplateLoading());

  axios.post(
    `${process.env.REACT_APP_TEMPLATES_PATH}`, 
    { agencyId: data.agencyId['value'], name: data.name, type: data.type, example: false},
    { transformResponse: (data: string) => transformObjectFromString<ITemplate>(data) })
    .then((response: any) => {

      dispatch(addTemplateSuccess(response.data));
      dispatch(toggleAddTemplate());

    })
    .catch((error: any) => {
      // console.log(error);
      dispatch(addTemplateError(normaliseErrorCode(error, 'templates.template.create')));
    });
};


export const editTemplate = async (dispatch: any, template: ITemplate | null, data: any) => {

  dispatch(editTemplateLoading());

  if(!template) {
    dispatch(editTemplateError(normaliseErrorCode('Error', 'projects.template.edit')));
    return;
  }

  let payload = data;

  axios.patch(
    `${process.env.REACT_APP_TEMPLATES_PATH}/${template!.uuid['value']}`, 
    payload,
    { transformResponse: (data: string) => transformObjectFromString<ITemplate>(data) })
    .then((response: any) => {

      toast.success(i18next.t('editTemplate.success'));

      dispatch(editTemplateSuccess(response.data));
      dispatch(toggleEditTemplate());

    })
    .catch((error: any) => {
      // console.log(error);
      dispatch(editTemplateError(normaliseErrorCode(error, 'templates.template.edit')));
    });
};
