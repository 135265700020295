import React from "react";
import { useSelector } from 'react-redux';
import { useDispatch } from "react-redux";
import { State } from "../../store/shared/state";
import { hideToolMenu, toggleCloneTool, toggleEditTool, toggleToolMenu } from "../../store/tools/actions";
import { ITool, ResearchType, ToolType } from "../../store/tools/types";
import { useTranslation } from "react-i18next";
import { getQueryString, setValueInQueryString } from "../../shared/queryString";
import { useHistory } from "react-router";
import { selectTool } from "../../store/projects/actions";

type Props = {
  tool: ITool
};

export const ToolMenu: React.FC<Props> = ({tool}) => {

  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();

  const selectedClient = useSelector((state: State) => state.clients.selected);
  const toolMenuOpened = useSelector((state: State) => state.tools.toolMenuOpened);
  const selectedProject = useSelector((state: State) => state.projects.selected);
  const typeName = t('tools.types.' + tool.type.toString());

  const goToTool = () => {
    var url = `${process.env.REACT_APP_LEGACY_BASE_URL}`;

    switch(tool.type) {

      case ToolType.RESEARCH:
        url += getResearchToolUrl();
        break;

        case ToolType.BRAND_STRATEGY:
        url += '/discovery/';
        break;

      case ToolType.MEDIA_BRIEF:
        url += '/media-brief/';
        break;

      case ToolType.COMPETITIVE_MAPPING:
        url += '/competitive-mapping/competitive-mapping-competitors/';
        break;

      case ToolType.CREATIVE_BRIEF:
        url += '/creative-brief/';
        break;

      case ToolType.CREATIVE_COMPETITIVE_OVERVIEW:
        url += '/creative-competitive-overview/';
        break;

        case ToolType.SCRIPT_TEMPLATE:
        url += '/script-template/';
        break;

      default:
        break;
    }
    
    url += getToolUrl();

    window.location.href = url;
  }

  const goToMediaBriefExercises = () => {
    var url = `${process.env.REACT_APP_LEGACY_BASE_URL}`;

    url += '/projects/media-brief-exercises/';
    url += getToolUrl();

    window.location.href = url;

  }

  const goToCreativeBriefExercises = () => {
    var url = `${process.env.REACT_APP_LEGACY_BASE_URL}`;

    url += '/projects/creative-brief-exercises/';
    url += getToolUrl();

    window.location.href = url;

  }

  const goToBrandStrategyExercises = () => {
    var url = `${process.env.REACT_APP_LEGACY_BASE_URL}`;

    url += '/projects/workshop-exercises/';
    url += getToolUrl();

    window.location.href = url;

  }

  const goToBrandStrategySettings = () => {
    var url = `${process.env.REACT_APP_LEGACY_BASE_URL}`;

    url += '/projects/workshop-settings/';
    url += getToolUrl();

    window.location.href = url;

  }

  const goToCompetitors = () => {
    var url = `${process.env.REACT_APP_LEGACY_BASE_URL}`;

    url += '/projects/competitors/';
    url += getToolUrl();

    window.location.href = url;
  }

  const goToDownloads = () => {

    setValueInQueryString('tool', tool.uuid);

    var url = `${process.env.REACT_APP_LEGACY_BASE_URL}`;

    switch(tool.type) {

      case ToolType.RESEARCH:
        url += '/research/downloads/';
        break;

      case ToolType.BRAND_STRATEGY:
        // history.push({ pathname: '/downloads', search: getQueryString()});
        // return;

        url += '/projects/downloads/';
        break;

      case ToolType.MEDIA_BRIEF:
        url += '/media-brief-downloads/';
        break;

      case ToolType.COMPETITIVE_MAPPING:
        url += '/competitive-mapping/competitive-mapping-downloads/';
        break;

      case ToolType.CREATIVE_BRIEF:
        url += '/creative-brief-downloads/';
        break;

      case ToolType.CREATIVE_COMPETITIVE_OVERVIEW:
        url += '/creative-competitive-overview/creative-competitive-overview-downloads/';
        break;

      case ToolType.SCRIPT_TEMPLATE:
        url += '/script-template/downloads/';
        break;

      default:
        break;
    }

    url += getToolUrl();

    window.location.href = url;
  }

  const getToolUrl = () : string => selectedProject!.uuid['value'] + '/' + tool.uuid['value'];

  const getResearchToolUrl = () : string => {

    if(!tool.data || !tool.data['researchType']) {
      return '';
    }

    switch(tool.data['researchType']) {
      case ResearchType.ROY_MORGAN: 
        return '/roy-morgan-research/';
      case ResearchType.AUSTRALIA_POST: 
        return '/australia-post-research/';
      case ResearchType.CUSTOM: 
        return '/custom-research/';
      default:
        return '';
    }
  }
  
  return (
   
    <div className={toolMenuOpened !== null && tool.uuid['value'] === toolMenuOpened['value'] ? "context-menu active" : "context-menu"}>
        <div className="header">
          <span>{t('list.actions')}</span><span className="action" onClick={() => { dispatch(toggleToolMenu(tool.uuid)) }}><i className="fas fa-chevron-up"></i></span>
        </div>

        <div className="content">
          {
            (
              tool.type === ToolType.RESEARCH
              || tool.type === ToolType.BRAND_STRATEGY
              || tool.type === ToolType.CREATIVE_COMPETITIVE_OVERVIEW
              || tool.type === ToolType.MEDIA_BRIEF
              || tool.type === ToolType.COMPETITIVE_MAPPING
              || tool.type === ToolType.CREATIVE_BRIEF
              || tool.type === ToolType.SCRIPT_TEMPLATE
            ) && 
            <div className="selectable-row" key="open-tool" onClick={goToTool}>
              {t('toolMenu.open', { type: typeName })}
            </div>
          }
          {
            (
              tool.type === ToolType.RESEARCH 
              || tool.type === ToolType.BRAND_STRATEGY
              || tool.type === ToolType.CREATIVE_COMPETITIVE_OVERVIEW
              || tool.type === ToolType.MEDIA_BRIEF
              || tool.type === ToolType.COMPETITIVE_MAPPING
              || tool.type === ToolType.CREATIVE_BRIEF
              || tool.type === ToolType.SCRIPT_TEMPLATE
            ) && 
            <div className="selectable-row" key="open-downloads" onClick={goToDownloads}>
              {t('toolMenu.download', { type: typeName })}
            </div>
          }
          {
            (
              !selectedClient?.example 
              && (tool.type === ToolType.CREATIVE_COMPETITIVE_OVERVIEW
                || tool.type === ToolType.BRAND_STRATEGY
                || tool.type === ToolType.CREATIVE_BRIEF
              )
            ) && 
            <div className="selectable-row" key="edit-competitors" onClick={goToCompetitors}>
              {t('toolMenu.editCompetitors')}
            </div>
          }
          {
            (
              !selectedClient?.example 
              && tool.type === ToolType.BRAND_STRATEGY
            ) && 
            <div className="selectable-row" key="edit-workshop-settings" onClick={goToBrandStrategySettings}>
              {t('toolMenu.editWorkshopSettings')}
            </div>
          }
          {
            (
              !selectedClient?.example 
              && tool.type === ToolType.MEDIA_BRIEF
            ) && 
            <div className="selectable-row" key="edit-media-brief-exercises" onClick={goToMediaBriefExercises}>
              {t('toolMenu.customiseExercises')}
            </div>
          }
          {
            (
              !selectedClient?.example 
              && tool.type === ToolType.CREATIVE_BRIEF
            ) && 
            <div className="selectable-row" key="edit-creative-brief-exercises" onClick={goToCreativeBriefExercises}>
              {t('toolMenu.customiseExercises')}
            </div>
          }
          {
            (
              !selectedClient?.example 
              && tool.type === ToolType.BRAND_STRATEGY
            ) && 
            <div className="selectable-row" key="edit-creative-brief-exercises" onClick={goToBrandStrategyExercises}>
              {t('toolMenu.customiseExercises')}
            </div>
          }
          {
            (
              !selectedClient?.example 
              && (tool.type === ToolType.RESEARCH
                || tool.type === ToolType.CREATIVE_COMPETITIVE_OVERVIEW
                || tool.type === ToolType.BRAND_STRATEGY
                || tool.type === ToolType.MEDIA_BRIEF
                || tool.type === ToolType.COMPETITIVE_MAPPING
                || tool.type === ToolType.CREATIVE_BRIEF
                || tool.type === ToolType.SCRIPT_TEMPLATE
              )
            ) && 
            <div className="selectable-row" key="edit-tool" onClick={() => { dispatch(hideToolMenu()); dispatch(selectTool(tool)); dispatch(toggleEditTool()); }}>
              {t('toolMenu.edit')}
            </div>
          }

          {
            (
              !selectedClient?.example 
              && (tool.type === ToolType.BRAND_STRATEGY
                || tool.type === ToolType.MEDIA_BRIEF
                || tool.type === ToolType.COMPETITIVE_MAPPING
                || tool.type === ToolType.CREATIVE_BRIEF
              )
            ) && 
            <div className="selectable-row" key="clone-tool" onClick={() => { dispatch(hideToolMenu()); dispatch(selectTool(tool)); dispatch(toggleCloneTool()); }}>
              {t('toolMenu.clone')}
            </div>
          }

        </div>

    </div>
  );
};
export default ToolMenu;