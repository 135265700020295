import React from "react";
import { Modal } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import { State } from "../../store/shared/state";
import { useTranslation } from "react-i18next";
import { showAddTool, selectResearchType, hideSelectResearchType, showSendNielsenEnquiry, showSendPureSpectrumEnquiry } from "../../store/tools/actions";
import { ResearchType, ToolType } from "../../store/tools/types";

export const SelectResearchType: React.FC = () => {

  const { t } = useTranslation();

  const selectResearchTypeOpened = useSelector((state: State) => state.tools.selectResearchTypeOpened);

  const dispatch = useDispatch();

  const doSelectResearchType = (type: ResearchType) => { 
    dispatch(hideSelectResearchType());
    dispatch(selectResearchType(type));
    dispatch(showAddTool(ToolType.RESEARCH));
  }

  const clearResearchType = () => {
    dispatch(selectResearchType(null));
    dispatch(hideSelectResearchType());
  }

  const doShowSendNielsenEnquiry = () => {
    dispatch(hideSelectResearchType());
    dispatch(showSendNielsenEnquiry());
  }

  const doShowPureSpectrumEnquiry = () => {
    dispatch(hideSelectResearchType());
    dispatch(showSendPureSpectrumEnquiry());
  }

  return (

    <Modal className="modal form-modal select-group-modal yellow-modal"
      show={selectResearchTypeOpened}
      animation={false}
      onHide={clearResearchType}
      centered
    >

      <Modal.Header>
        <Modal.Title>
          {t('selectResearchType.title')}
          </Modal.Title>
        <button type="button" data-bs-dismiss="modal" aria-label="Close" onClick={clearResearchType}><i className="fas fa-times"></i></button>
      </Modal.Header>

      <Modal.Body>
        <div className="select">
          <div className="row">
            <div className="col col-12 col-lg-6">
              <div className="research clickable" onClick={() => doSelectResearchType(ResearchType.ROY_MORGAN) }>
                <div className="title">
                  <div><img src="/app/images/research/roy-morgan-logo-small.png" alt="Roy Morgan" /></div>
                  <button>{t('selectResearchType.select')}</button>
                </div>
                <p>
                  {t('selectResearchType.royMorgan')}
                </p>
                <span className="available">{t('selectResearchType.available')}</span>
              </div>
            </div>
            <div className="col col-12 col-lg-6">
              <div className="research clickable" onClick={() => doSelectResearchType(ResearchType.AUSTRALIA_POST) }>
                <div className="title">
                  <div><img className="short" src="/app/images/research/aus-post-logo-small.png" alt="Australia Post" /></div>
                  <button>{t('selectResearchType.select')}</button>
                </div>
                <p>
                  {t('selectResearchType.australiaPost')}
                </p>
                <span className="available">{t('selectResearchType.available')}</span>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col col-12 col-lg-6">
              <div className="research">
                <div className="title">
                  <div><img src="/app/images/research/pure-spectrum-logo-small.png" alt="PureSpectrum" /></div>
                </div>
                <p>
                  {t('selectResearchType.pureSpectrum')}
                </p>
                <div className="footer">
                  <span className="coming-soon">{t('selectResearchType.comingSoon')}</span>
                  <span className="enquiry" onClick={doShowPureSpectrumEnquiry}>
                  {t('selectResearchType.enquiry')}
                  </span>
                </div>
              </div>
            </div>
            <div className="col col-12 col-lg-6">
              <div className="research">
                <div className="title">
                  <div><img src="/app/images/research/nielsen-logo-small.png" alt="Nielsen" /></div>
                </div>
                <p>
                  {t('selectResearchType.nielsen')}
                </p>
                <div className="footer">
                  <span className="coming-soon">{t('selectResearchType.comingSoon')}</span>
                  <span className="enquiry" onClick={doShowSendNielsenEnquiry}>
                  {t('selectResearchType.enquiry')}
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col col-12 col-lg-6">
              <div className="research">
                <div className="title">
                    <div><img src="/app/images/research/abs-logo-small.png" alt="Australia Bureau of Statistics" /></div>
                </div>
                <p>
                  {t('selectResearchType.abs')}
                </p>
                <span className="coming-soon">{t('selectResearchType.comingSoon')}</span>
              </div>
            </div>
            <div className="col col-12 col-lg-6">
              <div className="research custom clickable" onClick={() => doSelectResearchType(ResearchType.CUSTOM) }>
                <div className="title">
                  <h3>{t('selectResearchType.customTitle')}</h3>
                  <button>{t('selectResearchType.select')}</button>
                </div>
                <p className="narrow">
                  {t('selectResearchType.custom')}
                </p>
              </div>
            </div>
          </div>
        </div>

      </Modal.Body>
    </Modal>

  );
};
export default SelectResearchType;