import React from "react"
import ContentLoader from 'react-content-loader'

const ProjectsLoader: React.FC = () => {
  return (
    <div className="projects-loader">
      <ContentLoader width={"100%"} height={250}>
        <rect x="5" y="0" rx="5" ry="5" width="80%" height="15" />
        <rect x="5" y="20" rx="5" ry="5" width="80%" height="15" />
        <rect x="5" y="40" rx="5" ry="5" width="80%" height="15" />
        <circle cx="93%" cy="30" r="15" />

        <rect x="5" y="70" rx="5" ry="5" width="80%" height="15" />
        <rect x="5" y="90" rx="5" ry="5" width="80%" height="15" />
        <rect x="5" y="110" rx="5" ry="5" width="80%" height="15" />
        <circle cx="93%" cy="100" r="15" />

        <rect x="5" y="140" rx="5" ry="5" width="80%" height="15" />
        <rect x="5" y="160" rx="5" ry="5" width="80%" height="15" />
        <rect x="5" y="180" rx="5" ry="5" width="80%" height="15" />
        <circle cx="93%" cy="170" r="15" />
      </ContentLoader>
    </div>
  );
};

export default ProjectsLoader;
  