import { IProject } from "../../store/projects/types";
import { ITool, ToolType } from "../../store/tools/types";

export const processBusinessData = (project: IProject) => {

    if(!project || project === undefined) {
        return null;
    }

    let contentData : Record<string, any>[] = [];
    let pageNo = 0;

    if(project.business['marketType'] ) {
        let dimension : Record<string, any> = {};
        dimension['headline'] = 'Market type';
        dimension['description'] = project.business['marketType'];
        let noOfLines = getNumberOfLines(dimension);
        pageNo = pushToContentData(contentData, pageNo, dimension, noOfLines );
    }

    if(project.business['customCategoryDecision'] ) {
        let dimension : Record<string, any> = {};
        dimension['headline'] = 'Category';
        dimension['description'] = project.business['category']['name'];
        let noOfLines = getNumberOfLines(dimension);
        pageNo = pushToContentData(contentData, pageNo, dimension, noOfLines );

        if(project.business['subCategory'] ) {
            let dimension : Record<string, any> = {};
            dimension['headline'] = 'Sub category';
            dimension['description'] = project.business['subCategory']['name'];
            let noOfLines = getNumberOfLines(dimension);
            pageNo = pushToContentData(contentData, pageNo, dimension, noOfLines );
        }
    }

    if(project.business['customCategory'] ) {
        let dimension : Record<string, any> = {};
        dimension['headline'] = 'Category';
        dimension['description'] = project.business['customCategory'];
        let noOfLines = getNumberOfLines(dimension);
        pageNo = pushToContentData(contentData, pageNo, dimension, noOfLines );
    }

    if(project.business['primaryService'] ) {
        let dimension : Record<string, any> = {};
        dimension['headline'] = 'Primary product or service';
        dimension['description'] = project.business['primaryService'];
        let noOfLines = getNumberOfLines(dimension);
        pageNo = pushToContentData(contentData, pageNo, dimension, noOfLines );
    }
    if( project.business['clientLocation'] ) {
        let dimension : Record<string, any> = {};
        dimension['headline'] = 'Client location';
        dimension['description'] = project.business['clientLocation'];
        let noOfLines = getNumberOfLines(dimension);
        pageNo = pushToContentData(contentData, pageNo, dimension, noOfLines );
    }

    if(project.business['futureCategoryDecision'] ) {
        let dimension : Record<string, any> = {};
        dimension['headline'] = 'Future category';
        dimension['description'] = project.business['futureCategory'];
        let noOfLines = getNumberOfLines(dimension);
        pageNo = pushToContentData(contentData, pageNo, dimension, noOfLines );
    }

    if(project.businessMetrics['businessMetricsDecision'] !== 'true' ) {
        let dimension : Record<string, any> = {};
        dimension['headline'] = 'Future category';
        dimension['description'] = project.businessMetrics['businessMetricsNoTemplate'];
        let noOfLines = getNumberOfLines(dimension);
        pageNo = pushToContentData(contentData, pageNo, dimension, noOfLines );
    }

    if(project.businessMetrics['businessMetricsDecision'] === 'true' ) {

        if(project.businessMetrics['businessMetricsTemplate'] === 'b2cRetailChannel' ) {
            let dimension : Record<string, any> = {};
            let noOfLines;
            dimension['headline'] = 'Revenue at maturity';
            dimension['description'] = project.businessMetrics['revenueAtMaturity'];
            noOfLines = getNumberOfLines(dimension);
            pageNo = pushToContentData(contentData, pageNo, dimension, noOfLines );
            noOfLines = 0;
            dimension = {};

            dimension['headline'] = 'Sales (units)';
            dimension['description'] = project.businessMetrics['salesInUnits'];
            noOfLines = getNumberOfLines(dimension);
            pageNo = pushToContentData(contentData, pageNo, dimension, noOfLines );
            noOfLines = 0;
            dimension = {};

            dimension['headline'] = 'Average unit price';
            dimension['description'] = project.businessMetrics['averageUnitPrice'];
            noOfLines = getNumberOfLines(dimension);
            pageNo = pushToContentData(contentData, pageNo, dimension, noOfLines );
            noOfLines = 0;
            dimension = {};

            dimension['headline'] = 'Retail markup';
            dimension['description'] = project.businessMetrics['retailMarkup'];
            noOfLines = getNumberOfLines(dimension);
            pageNo = pushToContentData(contentData, pageNo, dimension, noOfLines );
            noOfLines = 0;
            dimension = {};

            dimension['headline'] = 'Wholesale';
            dimension['description'] = project.businessMetrics['averageUnitPrice'];
            noOfLines = getNumberOfLines(dimension);
            pageNo = pushToContentData(contentData, pageNo, dimension, noOfLines );
            noOfLines = 0;
            dimension = {};

            dimension['headline'] = 'Retail';
            dimension['description'] = project.businessMetrics['salesInUnits'];
            noOfLines = getNumberOfLines(dimension);
            pageNo = pushToContentData(contentData, pageNo, dimension, noOfLines );
            noOfLines = 0;
            dimension = {};
        }

        if(project.businessMetrics['businessMetricsTemplate'] === 'b2bTransaction' ) {

            let dimension : Record<string, any> = {};
            let noOfLines;

            dimension['headline'] = 'Revenue at maturity';
            dimension['description'] = project.businessMetrics['revenueAtMaturity'];
            noOfLines = getNumberOfLines(dimension);
            pageNo = pushToContentData(contentData, pageNo, dimension, noOfLines );
            noOfLines = 0;
            dimension = {};

            dimension['headline'] = 'Sales per year (units)';
            dimension['description'] = project.businessMetrics['salesPerYear'];
            noOfLines = getNumberOfLines(dimension);
            pageNo = pushToContentData(contentData, pageNo, dimension, noOfLines );
            noOfLines = 0;
            dimension = {};

            dimension['headline'] = 'Average transaction';
            dimension['description'] = project.businessMetrics['averageTransaction'];
            noOfLines = getNumberOfLines(dimension);
            pageNo = pushToContentData(contentData, pageNo, dimension, noOfLines );
            noOfLines = 0;
            dimension = {};

            dimension['headline'] = 'Revenue';
            dimension['description'] = project.businessMetrics['averageTransaction'];
            noOfLines = getNumberOfLines(dimension);
            pageNo = pushToContentData(contentData, pageNo, dimension, noOfLines );
            noOfLines = 0;
            dimension = {};

            dimension['headline'] = 'times';
            dimension['description'] = project.businessMetrics['salesPerYear'];
            noOfLines = getNumberOfLines(dimension);
            pageNo = pushToContentData(contentData, pageNo, dimension, noOfLines );
            noOfLines = 0;
            dimension = {};
        }

        if(project.businessMetrics['businessMetricsTemplate'] === 'b2cRetailDirect' ) {

            let dimension : Record<string, any> = {};
            let noOfLines;

            dimension['headline'] = 'Revenue at maturity';
            dimension['description'] = project.businessMetrics['revenueAtMaturity'];
            noOfLines = getNumberOfLines(dimension);
            pageNo = pushToContentData(contentData, pageNo, dimension, noOfLines );
            noOfLines = 0;
            dimension = {};

            dimension['headline'] = 'Subscribers';
            dimension['description'] = project.businessMetrics['subscribers'];
            noOfLines = getNumberOfLines(dimension);
            pageNo = pushToContentData(contentData, pageNo, dimension, noOfLines );
            noOfLines = 0;
            dimension = {};

            dimension['headline'] = 'Average per month';
            dimension['description'] = project.businessMetrics['averagePerMonth'];
            noOfLines = getNumberOfLines(dimension);
            pageNo = pushToContentData(contentData, pageNo, dimension, noOfLines );
            noOfLines = 0;
            dimension = {};

            dimension['headline'] = 'Annual recurring revenue';
            dimension['description'] = project.businessMetrics['subscribers'];
            noOfLines = getNumberOfLines(dimension);
            pageNo = pushToContentData(contentData, pageNo, dimension, noOfLines );
            noOfLines = 0;
            dimension = {};

            dimension['headline'] = 'Customer acquisition cost';
            dimension['description'] = project.businessMetrics['customerAcquisitionCost'];
            noOfLines = getNumberOfLines(dimension);
            pageNo = pushToContentData(contentData, pageNo, dimension, noOfLines );
            noOfLines = 0;
            dimension = {};

            dimension['headline'] = 'ARR-CAC';
            dimension['description'] = project.businessMetrics['subscribers'];
            noOfLines = getNumberOfLines(dimension);
            pageNo = pushToContentData(contentData, pageNo, dimension, noOfLines );
            noOfLines = 0;
            dimension = {};

            dimension['headline'] = 'Average customer';
            dimension['description'] = project.businessMetrics['averageCustomerLifetime'];
            noOfLines = getNumberOfLines(dimension);
            pageNo = pushToContentData(contentData, pageNo, dimension, noOfLines );
            noOfLines = 0;
            dimension = {};

            dimension['headline'] = 'Churn rate';
            dimension['description'] = project.businessMetrics['averageCustomerLifetime'];
            noOfLines = getNumberOfLines(dimension);
            pageNo = pushToContentData(contentData, pageNo, dimension, noOfLines );
            noOfLines = 0;
            dimension = {};

            dimension['headline'] = 'Customer lifetime value';
            dimension['description'] = project.businessMetrics['averagePerMonth'];
            noOfLines = getNumberOfLines(dimension);
            pageNo = pushToContentData(contentData, pageNo, dimension, noOfLines );
            noOfLines = 0;
            dimension = {};

            dimension['headline'] = 'CLV-CAC';
            dimension['description'] = project.businessMetrics['averagePerMonth'];
            noOfLines = getNumberOfLines(dimension);
            pageNo = pushToContentData(contentData, pageNo, dimension, noOfLines );
            noOfLines = 0;
            dimension = {};

        }

        let dimension : Record<string, any> = {};
        let noOfLines;
        dimension['headline'] = 'Project objectives';
        dimension['description'] = project.businessMetrics['projectObjectives'];
        noOfLines = getNumberOfLines(dimension);
        
        pushToContentData(contentData, pageNo, dimension, noOfLines );
        noOfLines = 0;
        dimension = {};
    }

    return contentData;
}

export const processBrandValues = (tool: ITool | null) => {

    if(!tool || !tool.data || !tool.data['brandValuesRefinement'] || !tool.data['brandValuesProof']) {
        return null;
    }

    let pageNo = 0;
    let contentData : Record<string, any>[] = [];

    for(var i = 0; i < tool?.data['brandValuesRefinement'].length; i++ ){
        let dimension : Record<string, any> = {};

        dimension['headline'] = tool.data['brandValuesRefinement'][i]['name'];
        dimension['description'] = tool.data['brandValuesProof'][`brandValueProof${i+1}`];

        let noOfLines = getNumberOfLines(dimension);
        pageNo = pushToContentData(contentData, pageNo, dimension, noOfLines );
    }

    return contentData.length > 0 ? contentData[0] : [];
}

export const processBrandProofPoints = (tool: ITool | null) => {

    if(!tool || !tool.data || !tool.data['brandProofPoints']) {
        return null;
    }

    let pageNo = 0;
    let contentData : Record<string, any>[] = [];

    if(tool.data['brandProofPoints']['brandEssence']) {

        let dimension : Record<string, any> = {};

        dimension['headline'] = 'Your brand essence';
        dimension['description'] = tool.data['brandProofPoints']['brandEssence'];
        dimension['question'] = false;

        let noOfLines = getNumberOfLines(dimension);
        pageNo = pushToContentData(contentData, pageNo, dimension, noOfLines );
    }

    if(tool.data['brandProofPoints']['brandDifferentiators'] && tool.data['brandProofPoints']['brandDifferentiators'].length > 0 ) {
        for( var i = 0; i < tool.data['brandProofPoints']['brandDifferentiators'].length; i++ ){
            let dimension : Record<string, any> = {};
            if( i === 0 ) {
                dimension['headline'] = "Your brand differentiators";
                dimension['question'] = true;
            }
            dimension['description'] = tool.data['brandProofPoints']['brandDifferentiators'][i].name;

            let noOfLines = getNumberOfLines(dimension);
            pageNo = pushToContentData(contentData, pageNo, dimension, noOfLines );
        }
    }

    if(tool.data['brandProofPoints']['brandValueProposition']) {
        let dimension : Record<string, any> = {};
        dimension['headline'] = 'Your brand value proposition';
        dimension['description'] = tool.data['brandProofPoints']['brandValueProposition'];
        dimension['question'] = true;
        let noOfLines = getNumberOfLines(dimension);
        pageNo = pushToContentData(contentData, pageNo, dimension, noOfLines );
    }

    if(tool.data['brandProofPoints']['brandProofPoints'] && tool.data['brandProofPoints']['brandProofPoints'].length ) {
        for( var j = 0; j < tool.data['brandProofPoints']['brandProofPoints'].length; j++ ){
            let dimension : Record<string, any> = {};
            if( j === 0 ) {
                dimension['headline'] = "Your brand proof points";
                dimension['question'] = true;
            }
            dimension['description'] = tool.data['brandProofPoints']['brandProofPoints'][j].name;

            let noOfLines = getNumberOfLines(dimension);
            pageNo = pushToContentData(contentData, pageNo, dimension, noOfLines );
        }
    }

    return contentData;
}

export const processKeyAudienceData = (tool: ITool | null) => {

    if(!tool || !tool.data || !tool.data['coreCustomer']) {
        return null;
    }

    let pageNo = 0;
    let contentData: any = [];

    if(tool.data['coreCustomer']['age'] ) {
        let dimension : Record<string, any> = {};
        dimension['headline'] = 'Age';
        dimension['description'] = tool.data['coreCustomer']['age'];
        let noOfLines = getNumberOfLines(dimension);
        pageNo = pushToContentData(contentData, pageNo, dimension, noOfLines );
    }
    if( tool.data['coreCustomer']['gender'] ) {
        let dimension : Record<string, any> = {};
        dimension['headline'] = 'Male/Female';
        dimension['description'] = tool.data['coreCustomer']['gender'];
        let noOfLines = getNumberOfLines(dimension);
        pageNo = pushToContentData(contentData, pageNo, dimension, noOfLines );
    }
    if( tool.data['coreCustomer']['occupation'] ) {
        let dimension : Record<string, any> = {};
        dimension['headline'] = 'Occupation';
        dimension['description'] = tool.data['coreCustomer']['occupation'];
        let noOfLines = getNumberOfLines(dimension);
        pageNo = pushToContentData(contentData, pageNo, dimension, noOfLines );
        dimension = {};
    }
    if( tool.data['coreCustomer']['familyStatus'] ) {
        let dimension : Record<string, any> = {};
        dimension['headline'] = 'Family Status';
        dimension['description'] = tool.data['coreCustomer']['familyStatus'];
        let noOfLines = getNumberOfLines(dimension);
        pageNo = pushToContentData(contentData, pageNo, dimension, noOfLines );
    }
    if( tool.data['coreCustomer']['location'] ) {
        let dimension : Record<string, any> = {};
        dimension['headline'] = 'Lives';
        dimension['description'] = tool.data['coreCustomer']['location'];
        let noOfLines = getNumberOfLines(dimension);
        pageNo = pushToContentData(contentData, pageNo, dimension, noOfLines );
    }
    if( tool.data['coreCustomer']['interests'] ) {
        let dimension : Record<string, any> = {};
        dimension['headline'] = 'Interests/Passions';
        dimension['description'] = tool.data['coreCustomer']['interests'];
        let noOfLines = getNumberOfLines(dimension);
        pageNo = pushToContentData(contentData, pageNo, dimension, noOfLines );
    }
    if( tool.data['coreCustomer']['personalityAttributes'] ) {
        let dimension : Record<string, any> = {};
        dimension['headline'] = 'Personality Attributes';
        dimension['description'] = tool.data['coreCustomer']['personalityAttributes'];
        let noOfLines = getNumberOfLines(dimension);
        pageNo = pushToContentData(contentData, pageNo, dimension, noOfLines );
    }
    if( tool.data['coreCustomer']['mediaUse'] ) {
        let dimension : Record<string, any> = {};
        dimension['headline'] = 'Media, Social Media & Tech use';
        dimension['description'] = tool.data['coreCustomer']['mediaUse'];
        let noOfLines = getNumberOfLines(dimension);
        pageNo = pushToContentData(contentData, pageNo, dimension, noOfLines );
    }
    if( tool.data['coreCustomer']['defines'] ) {
        let dimension : Record<string, any> = {};
        dimension['headline'] = 'What defines them relative to this category?';
        dimension['description'] = tool.data['coreCustomer']['defines'];
        dimension['question'] = true;
        let noOfLines = getNumberOfLines(dimension);
        pageNo = pushToContentData(contentData, pageNo, dimension, noOfLines );
    }
    if( tool.data['coreCustomer']['frustrations'] ) {
        let dimension : Record<string, any> = {};
        dimension['headline'] = 'What are their most significant frustrations?';
        dimension['description'] = tool.data['coreCustomer']['frustrations'];
        dimension['question'] = true;
        let noOfLines = getNumberOfLines(dimension);
        pageNo = pushToContentData(contentData, pageNo, dimension, noOfLines );
    }
    if( tool.data['coreCustomer']['motivations'] ) {
        let dimension : Record<string, any> = {};
        dimension['headline'] = 'What are their key motivations?';
        dimension['description'] = tool.data['coreCustomer']['motivations'];
        dimension['question'] = true;
        let noOfLines = getNumberOfLines(dimension);
        pageNo = pushToContentData(contentData, pageNo, dimension, noOfLines );
    }
    if( tool.data['coreCustomer']['improve'] ) {
        let dimension : Record<string, any> = {};
        dimension['headline'] = 'What do they wish to achieve/improve from their interaction with this category?';
        dimension['description'] = tool.data['coreCustomer']['improve'];
        dimension['question'] = true;
        let noOfLines = getNumberOfLines(dimension);
        pushToContentData(contentData, pageNo, dimension, noOfLines );
    }

    return contentData;
}


export const processSecondaryKeyAudienceData = (tool: ITool | null) => {

    if(!tool || !tool.data || !tool.data['coreCustomer'] || !tool.data['coreCustomer']['secondaryAge']) {
        return null;
    }

    let pageNo = 0;
    let contentData: any = [];

    if(tool.data['coreCustomer']['secondaryAge'] ) {
        let dimension : Record<string, any> = {};
        dimension['headline'] = 'Age';
        dimension['description'] = tool.data['coreCustomer']['secondaryAge'];
        let noOfLines = getNumberOfLines(dimension);
        pageNo = pushToContentData(contentData, pageNo, dimension, noOfLines );
    }
    if( tool.data['coreCustomer']['secondaryGender'] ) {
        let dimension : Record<string, any> = {};
        dimension['headline'] = 'Male/Female';
        dimension['description'] = tool.data['coreCustomer']['secondaryGender'];
        let noOfLines = getNumberOfLines(dimension);
        pageNo = pushToContentData(contentData, pageNo, dimension, noOfLines );
    }
    if( tool.data['coreCustomer']['secondaryOccupation'] ) {
        let dimension : Record<string, any> = {};
        dimension['headline'] = 'Occupation';
        dimension['description'] = tool.data['coreCustomer']['secondaryOccupation'];
        let noOfLines = getNumberOfLines(dimension);
        pageNo = pushToContentData(contentData, pageNo, dimension, noOfLines );
        dimension = {};
    }
    if( tool.data['coreCustomer']['secondaryFamilyStatus'] ) {
        let dimension : Record<string, any> = {};
        dimension['headline'] = 'Family Status';
        dimension['description'] = tool.data['coreCustomer']['secondaryFamilyStatus'];
        let noOfLines = getNumberOfLines(dimension);
        pageNo = pushToContentData(contentData, pageNo, dimension, noOfLines );
    }
    if( tool.data['coreCustomer']['secondaryLocation'] ) {
        let dimension : Record<string, any> = {};
        dimension['headline'] = 'Lives';
        dimension['description'] = tool.data['coreCustomer']['secondaryLocation'];
        let noOfLines = getNumberOfLines(dimension);
        pageNo = pushToContentData(contentData, pageNo, dimension, noOfLines );
    }
    if( tool.data['coreCustomer']['secondaryInterests'] ) {
        let dimension : Record<string, any> = {};
        dimension['headline'] = 'Interests/Passions';
        dimension['description'] = tool.data['coreCustomer']['secondaryInterests'];
        let noOfLines = getNumberOfLines(dimension);
        pageNo = pushToContentData(contentData, pageNo, dimension, noOfLines );
    }
    if( tool.data['coreCustomer']['secondaryPersonalityAttributes'] ) {
        let dimension : Record<string, any> = {};
        dimension['headline'] = 'Personality Attributes';
        dimension['description'] = tool.data['coreCustomer']['secondaryPersonalityAttributes'];
        let noOfLines = getNumberOfLines(dimension);
        pageNo = pushToContentData(contentData, pageNo, dimension, noOfLines );
    }
    if( tool.data['coreCustomer']['secondaryMediaUse'] ) {
        let dimension : Record<string, any> = {};
        dimension['headline'] = 'Media, Social Media & Tech use';
        dimension['description'] = tool.data['coreCustomer']['secondaryMediaUse'];
        let noOfLines = getNumberOfLines(dimension);
        pageNo = pushToContentData(contentData, pageNo, dimension, noOfLines );
    }
    if( tool.data['coreCustomer']['secondaryDefines'] ) {
        let dimension : Record<string, any> = {};
        dimension['headline'] = 'What defines them relative to this category?';
        dimension['description'] = tool.data['coreCustomer']['secondaryDefines'];
        dimension['question'] = true;
        let noOfLines = getNumberOfLines(dimension);
        pageNo = pushToContentData(contentData, pageNo, dimension, noOfLines );
    }
    if( tool.data['coreCustomer']['secondaryFrustrations'] ) {
        let dimension : Record<string, any> = {};
        dimension['headline'] = 'What are their most significant frustrations?';
        dimension['description'] = tool.data['coreCustomer']['secondaryFrustrations'];
        dimension['question'] = true;
        let noOfLines = getNumberOfLines(dimension);
        pageNo = pushToContentData(contentData, pageNo, dimension, noOfLines );
    }
    if( tool.data['coreCustomer']['secondaryMotivations'] ) {
        let dimension : Record<string, any> = {};
        dimension['headline'] = 'What are their key motivations?';
        dimension['description'] = tool.data['coreCustomer']['secondaryMotivations'];
        dimension['question'] = true;
        let noOfLines = getNumberOfLines(dimension);
        pageNo = pushToContentData(contentData, pageNo, dimension, noOfLines );
    }
    if( tool.data['coreCustomer']['secondaryImprove'] ) {
        let dimension : Record<string, any> = {};
        dimension['headline'] = 'What do they wish to achieve/improve from their interaction with this category?';
        dimension['description'] = tool.data['coreCustomer']['secondaryImprove'];
        dimension['question'] = true;
        let noOfLines = getNumberOfLines(dimension);
        pushToContentData(contentData, pageNo, dimension, noOfLines );
    } 
 
    return contentData;
}


export const processCoreCustomerDataForCanvas = (tool: ITool | null) => {

    if(!tool || !tool.data['coreCustomer']) {
        return [];
    }

    let noOfCharacters = 0;
    //let limit = 942;
    let limit = 1450;

    let data: any[] = [];

    if(tool.data['coreCustomer']['age'] ) {
        let dimension : Record<string, any> = {};
        dimension['headline'] = 'Age';
        dimension['description'] = tool.data['coreCustomer']['age'];
        noOfCharacters = pushToCanvasCoreCustomerData(data, dimension, noOfCharacters );
    }
    if(tool.data['coreCustomer']['gender'] && noOfCharacters < limit ) {
        let dimension : Record<string, any> = {};
        dimension['headline'] = 'Male/Female';
        dimension['description'] = tool.data['coreCustomer']['gender'];
        noOfCharacters = pushToCanvasCoreCustomerData(data, dimension, noOfCharacters );
    }
    if(tool.data['coreCustomer']['occupation'] && noOfCharacters < limit ) {
        let dimension : Record<string, any> = {};
        dimension['headline'] = 'Occupation';
        dimension['description'] = tool.data['coreCustomer']['occupation'];
        noOfCharacters = pushToCanvasCoreCustomerData(data, dimension, noOfCharacters );
    }
    if(tool.data['coreCustomer']['familyStatus'] && noOfCharacters < limit ) {
        let dimension : Record<string, any> = {};
        dimension['headline'] = 'Family Status';
        dimension['description'] = tool.data['coreCustomer']['familyStatus'];
        noOfCharacters = pushToCanvasCoreCustomerData(data, dimension, noOfCharacters );
    }
    if(tool.data['coreCustomer']['location'] && noOfCharacters < limit ) {
        let dimension : Record<string, any> = {};
        dimension['headline'] = 'Lives';
        dimension['description'] = tool.data['coreCustomer']['location'];
        noOfCharacters = pushToCanvasCoreCustomerData(data, dimension, noOfCharacters );
    }
    if(tool.data['coreCustomer']['interests'] && noOfCharacters < limit ) {
        let dimension : Record<string, any> = {};
        dimension['headline'] = 'Interests/Passions';
        dimension['description'] = tool.data['coreCustomer']['interests'];
        noOfCharacters = pushToCanvasCoreCustomerData(data, dimension, noOfCharacters );
    }
    if(tool.data['coreCustomer']['personalityAttributes'] && noOfCharacters < limit ) {
        let dimension : Record<string, any> = {};
        dimension['headline'] = 'Personality Attributes';
        dimension['description'] = tool.data['coreCustomer']['personalityAttributes'];
        noOfCharacters = pushToCanvasCoreCustomerData(data, dimension, noOfCharacters );
    }
    if(tool.data['coreCustomer']['mediaUse'] && noOfCharacters < limit ) {
        let dimension : Record<string, any> = {};
        dimension['headline'] = 'Media, Social Media & Tech use';
        dimension['description'] = tool.data['coreCustomer']['mediaUse'];
        noOfCharacters = pushToCanvasCoreCustomerData(data, dimension, noOfCharacters );
    }
    if(tool.data['coreCustomer']['defines'] && noOfCharacters < limit ) {
        let dimension : Record<string, any> = {};
        dimension['headline'] = 'What defines them relative to this category?';
        dimension['description'] = tool.data['coreCustomer']['defines'];
        noOfCharacters = pushToCanvasCoreCustomerData(data, dimension, noOfCharacters );
    }
    if(tool.data['coreCustomer']['frustrations'] && noOfCharacters < limit ) {
        let dimension : Record<string, any> = {};
        dimension['headline'] = 'What are their most significant frustrations?';
        dimension['description'] = tool.data['coreCustomer']['frustrations'];
        noOfCharacters = pushToCanvasCoreCustomerData(data, dimension, noOfCharacters );
    }
    if(tool.data['coreCustomer']['motivations'] && noOfCharacters < limit ) {
        let dimension : Record<string, any> = {};
        dimension['headline'] = 'What are their key motivations?';
        dimension['description'] = tool.data['coreCustomer']['motivations'];
        noOfCharacters = pushToCanvasCoreCustomerData(data, dimension, noOfCharacters );
    }
    if(tool.data['coreCustomer']['improve'] && noOfCharacters < limit ) {
        let dimension : Record<string, any> = {};
        dimension['headline'] = 'What do they wish to achieve/improve from their interaction with this category?';
        dimension['description'] = tool.data['coreCustomer']['improve'];
        noOfCharacters = pushToCanvasCoreCustomerData(data, dimension, noOfCharacters );
    }

    return data;
}

export const processBrandProofPointsForCanvas = (tool: ITool | null) => {

    if(!tool || !tool.data['brandProofPoints'] || !tool.data['brandProofPoints'] || tool.data['brandProofPoints'].length === 0) {
        return [];
    }

    let chunkLength = tool.data['brandProofPoints']['brandProofPoints'].length / 2;
    let chunks: any[] = splitArrayInChunks(tool.data['brandProofPoints']['brandProofPoints'], chunkLength);

    let proofPoints: any[] = [];

    for( var i = 0; i < chunks.length; i++) {

        let characterLimit = 250;
        
        proofPoints[i] = [];

        for( var j = 0; j < chunks[i].length; j++ ) {
            characterLimit = characterLimit - chunks[i][j].name.length;

            if(characterLimit >= 0 ) {
                proofPoints[i].push(chunks[i][j].name)
            } else {
                let lastString = chunks[i][j].name.slice(0, characterLimit-3)+ '...';
                proofPoints[i].push(lastString);
                break;
            }
        }
    }

    return proofPoints;
}

const splitArrayInChunks = (orgArray : any[], chunk: number) => {

    let arrayChunks = [];

    var i,j,temparray;
    for (i=0,j=orgArray.length; i<j; i+=chunk) {
      temparray = orgArray.slice(i,i+chunk);

      arrayChunks.push(temparray);
    }

    return arrayChunks;

}

const pushToCanvasCoreCustomerData = (data: any[], dimension: any, noOfCharacters: number) => {

    let limit = 1450;
    let headlineLength = dimension['headline'].length;
    let descriptionLength = dimension['description'].toString().length;
    noOfCharacters += headlineLength;

    if( noOfCharacters <= limit ) {
        noOfCharacters += descriptionLength;
        if( noOfCharacters <= limit ) {
            data.push(dimension);
        } else {
            let overLimit = noOfCharacters - limit;
            let endOfString = descriptionLength - overLimit;
            dimension['description'] = dimension['description'].toString().substring(0, endOfString) + '...';
            data.push(dimension);
        }

    }
    return noOfCharacters;
}

export const getReseachTools = (project: IProject | null) => {

    if(!project || !project.tools) {
        return [];
    }

    let researchTools = [];
    for(var t = 0; t < project.tools.length; t++) {
        let tool = project.tools[t];

        if(tool.type === ToolType.BRAND_STRATEGY && tool['data']['selectedResearch']) {
            for(var i = 0; i < tool['data']['selectedResearch'].length; i++) {
                if(tool['data']['selectedResearch'][i]['checked']) {
                    for(var j = 0; j < project.tools.length; j++) {
                        if(project.tools[j]['type'] === 'RESEARCH' && project.tools[j]['uuid']['value'] === tool['data']['selectedResearch'][i]['uuid']) {
                            researchTools.push(project.tools[j]);
                            break;
                        }
                    }
                }
            }
        }
    }

    return researchTools;
}

export const getCompetitorMappingData = (tool: ITool | null) => {

    if(!tool || tool === undefined) {
        return null;
    }

    let differentiators = getRankedDifferentiators(
        tool['data'].competitorPositioningAxes['differentiators'],
        tool['data'].competitorPositioningAxes['rankings']
    );

    let firstAttributes = [];
    let secondAttributes = [];
    let thirdAttributes = [];
    let fourthAttributes = [];

    let firstX = [];
    let firstY = [];
    let secondX = [];
    let secondY = [];

    let thirdX: any[] = [];
    let thirdY: any[] = [];
    let fourthX: any[] = [];
    let fourthY: any[] = [];

    for(let m of differentiators) {
        if(m['checked']) {
            firstAttributes.push(m);
            secondAttributes.push(m);
            thirdAttributes.push(m);
            fourthAttributes.push(m);
        }
    }

    firstX = [firstAttributes[0]];
    firstY = [firstAttributes[1]];
    secondX = [secondAttributes[2]];
    secondY = [secondAttributes[3]];

    if(thirdAttributes.length >= 6) {
        thirdX = [thirdAttributes[4]];
        thirdY = [thirdAttributes[5]];
    }

    if(fourthAttributes.length === 8) {
        fourthX = [fourthAttributes[6]];
        fourthY = [fourthAttributes[7]];
    }


    let matrixData = getMatrixData(tool);

    let attributes = {
        first: firstAttributes,
        second: secondAttributes,
        third: thirdAttributes,
        fourth: fourthAttributes
    };

    let axes = {
        firstX: firstX,
        firstY: firstY,
        secondX: secondX,
        secondY: secondY,
        thirdX: thirdX,
        thirdY: thirdY,
        fourthX: fourthX,
        fourthY: fourthY
    }

    let marginData = getMarginData(matrixData, attributes, axes);

    // if(matrixData && matrixData.length) {
    //     processMarginsOfCompetitors(matrixData, attributes, axes, marginData);
    // }

    return {
        matrixData: matrixData,
        marginData: marginData,
        attributes: attributes,
        axes: axes
    };

}

export const edgeClass = (marginData: any, row: string, matrixData: any, counter: number ) => {

    let minusClass = 'matrix-cell';

    if(!marginData || !matrixData) {
        return minusClass;
    }

    let leftMarginKey = row + '-left-original-' + matrixData.uuid.value +'-'+ counter;
    if(marginData && marginData[leftMarginKey] && marginData[leftMarginKey] === 100) {
        minusClass = minusClass+' left-margin-minus';
    }

    let topMarginKey = row +'-top-original-' + matrixData.uuid.value +'-'+ counter;
    if(marginData && marginData[topMarginKey] && marginData[topMarginKey] === 100) {
        minusClass = minusClass+' top-margin-minus';
    }

    return minusClass;
}

export const writeTopMargin = (marginData: any, row: string, matrixData: any, counter: number) => {

    let topMargin;
    let topMarginKey = row +'-top-' + matrixData.uuid.value +'-'+ counter;

    if(marginData && marginData[topMarginKey] ) {
        topMargin = marginData[topMarginKey] +"%";
    }
    return topMargin;

}

export const writeLeftMargin = (marginData: any, row: string, matrixData: any, counter: number) => {
    
    let leftMargin;
    let leftMarginKey = row +'-left-' + matrixData.uuid.value +'-'+ counter;

    if(marginData && marginData[leftMarginKey] ) {
        leftMargin = marginData[leftMarginKey] +"%";
    }

    return leftMargin;
}

export const filterPersonalitySetsByScore = (tool: ITool | null, score: number) : any[] => {

    let personalitySets: any[] = [];

    if(!tool) {
        return personalitySets;
    }

    for(let x of tool.data['brandPersonalitySummary']['personalitySets']) {
        for(let y of tool.data['brandPersonality']['personalitySets']) {
            if(x['personalities'].join('') === y['personalities'].join('') && y['score'] === score) {
                personalitySets.push(x);
                break;
            }
        }
    }

    return personalitySets;
}

export const useRoyMorgan = (researchTools: ITool[]) => useProvider(researchTools, 'ROY_MORGAN');
export const useAustraliaPost = (researchTools: ITool[]) => useProvider(researchTools, 'AUSTRALIA_POST');
export const useCustom = (researchTools: ITool[]) => useProvider(researchTools, 'CUSTOM');

const getMatrixData = (tool: ITool | null) => {

    if(!tool || tool === undefined) {
        return [];
    }

    let matrixData = [];

    if(tool['data'].competitorPositioningMatrixA) {
        for(var a of tool['data'].competitorPositioningMatrixA) {
            if(!a['client'] || tool['data'].includeProjectBrandInExercises) {
                matrixData.push(a);
            }
        }
    }

    if(tool['data'].competitorPositioningMatrixB) {
        for(var b of tool['data'].competitorPositioningMatrixB) {
            var xb = matrixData.find(y => y['uuid']['value'] === b['uuid']['value']);
            if(xb) {
                xb['differentiator3'] = b['differentiator3'];
                xb['differentiator4'] = b['differentiator4'];
            } else if(!b['client'] || tool['data'].includeProjectBrandInExercises) {
                b['differentiator1'] = 1;
                b['differentiator2'] = 1;
                matrixData.push(b);
            }
        }
    }

    if(tool['data'].competitorPositioningMatrixC) {
        for(var c of tool['data'].competitorPositioningMatrixC) {
            var xc = matrixData.find(y => y['uuid']['value'] === c['uuid']['value']);
            if(xc) {
                xc['differentiator5'] = c['differentiator5'];
                xc['differentiator6'] = c['differentiator6'];
            } else if(!c['client'] || tool['data'].includeProjectBrandInExercises) {
                c['differentiator1'] = 1;
                c['differentiator2'] = 1;
                c['differentiator3'] = 1;
                c['differentiator4'] = 1;
                matrixData.push(c);
            }
        }
    }

    if(tool['data'].competitorPositioningMatrixD) {
        for(var d of tool['data'].competitorPositioningMatrixD) {
            var xd = matrixData.find(y => y['uuid']['value'] === d['uuid']['value']);
            if(xd) {
                xd['differentiator7'] = d['differentiator7'];
                xd['differentiator8'] = d['differentiator8'];
            } else if(!d['client'] || tool['data'].includeProjectBrandInExercises) {
                d['differentiator1'] = 1;
                d['differentiator2'] = 1;
                d['differentiator3'] = 1;
                d['differentiator4'] = 1;
                d['differentiator5'] = 1;
                d['differentiator6'] = 1;
                matrixData.push(d);
            }
        }
    }

    return matrixData;
}

const getMarginData = (matrixData: any | null, attributes: any, axes: any) => {

    if(!matrixData || matrixData === undefined) {
        return [];
    }
   
    let marginData: any = {};
    let marginCompetitors: any[] = [];
    let conflictsObj: any = {};

    for( let i = 0; i < matrixData.length; i++ ) {

        if(axes['firstY'] && axes['firstY'][0] ) {
            let chart = 'first';

            let topMargin = getTopMargin(marginCompetitors, attributes, axes, chart, matrixData[i], i);
            let leftMargin = getLeftMargin(marginCompetitors, attributes, axes, chart, matrixData[i], i);
            let shiftedMargins = shiftMarginsIfConflictExist(marginCompetitors, conflictsObj, chart, matrixData[i], topMargin, leftMargin, i);

            let topMarginKey = 'first-top-' + matrixData[i].uuid.value +'-'+ i;
            let leftMarginKey = 'first-left-' + matrixData[i].uuid.value +'-'+ i;

            let topMarginOriginalKey = 'first-top-original-' + matrixData[i].uuid.value +'-'+ i;
            let leftMarginOriginalKey = 'first-left-original-' + matrixData[i].uuid.value +'-'+ i;

            if( shiftedMargins ) {
                marginData[topMarginKey] = shiftedMargins['topMargin'];
                marginData[leftMarginKey] = shiftedMargins['leftMargin'];
                if( shiftedMargins['topMarginOriginal'] ) {
                    marginData[topMarginOriginalKey] = shiftedMargins['topMarginOriginal'];
                }
                if( shiftedMargins['leftMarginOriginal'] ) {
                    marginData[leftMarginOriginalKey] = shiftedMargins['leftMarginOriginal'];
                }
            }
        }

        if( axes['secondY'] && axes['secondY'][0] ) {
            let chart = 'second';

            let topMargin = getTopMargin(marginCompetitors, attributes, axes, chart, matrixData[i], i);
            let leftMargin = getLeftMargin(marginCompetitors, attributes, axes, chart, matrixData[i], i);
            let shiftedMargins = shiftMarginsIfConflictExist(marginCompetitors, conflictsObj, chart, matrixData[i], topMargin, leftMargin, i);

            let topMarginKey = 'second-top-' + matrixData[i].uuid.value +'-'+ i;
            let leftMarginKey = 'second-left-' + matrixData[i].uuid.value +'-'+ i;

            let topMarginOriginalKey = 'second-top-original-' + matrixData[i].uuid.value +'-'+ i;
            let leftMarginOriginalKey = 'second-left-original-' + matrixData[i].uuid.value +'-'+ i;

            if( shiftedMargins ) {
                marginData[topMarginKey] = shiftedMargins['topMargin'];
                marginData[leftMarginKey] = shiftedMargins['leftMargin'];
                if( shiftedMargins['topMarginOriginal'] ) {
                    marginData[topMarginOriginalKey] = shiftedMargins['topMarginOriginal'];
                }
                if( shiftedMargins['leftMarginOriginal'] ) {
                    marginData[leftMarginOriginalKey] = shiftedMargins['leftMarginOriginal'];
                }
            }
        }

        if( axes['thirdY'] && axes['thirdY'][0] ) {
            let chart = 'third';

            let topMargin = getTopMargin(marginCompetitors, attributes, axes, chart, matrixData[i], i);
            let leftMargin = getLeftMargin(marginCompetitors, attributes, axes, chart, matrixData[i], i);
            let shiftedMargins = shiftMarginsIfConflictExist(marginCompetitors, conflictsObj, chart, matrixData[i], topMargin, leftMargin, i);

            let topMarginKey = 'third-top-' + matrixData[i].uuid.value +'-'+ i;
            let leftMarginKey = 'third-left-' + matrixData[i].uuid.value +'-'+ i;

            let topMarginOriginalKey = 'third-top-original-' + matrixData[i].uuid.value +'-'+ i;
            let leftMarginOriginalKey = 'third-left-original-' + matrixData[i].uuid.value +'-'+ i;

            if( shiftedMargins ) {
                marginData[topMarginKey] = shiftedMargins['topMargin'];
                marginData[leftMarginKey] = shiftedMargins['leftMargin'];
                if( shiftedMargins['topMarginOriginal'] ) {
                    marginData[topMarginOriginalKey] = shiftedMargins['topMarginOriginal'];
                }
                if( shiftedMargins['leftMarginOriginal'] ) {
                    marginData[leftMarginOriginalKey] = shiftedMargins['leftMarginOriginal'];
                }
            }
        }

        if( axes['fourthX'] && axes['fourthX'][0] ) {
            let chart = 'fourth';

            let topMargin = getTopMargin(marginCompetitors, attributes, axes, chart, matrixData[i], i);
            let leftMargin = getLeftMargin(marginCompetitors, attributes, axes, chart, matrixData[i], i);
            let shiftedMargins = shiftMarginsIfConflictExist(marginCompetitors, conflictsObj, chart, matrixData[i], topMargin, leftMargin, i);

            let topMarginKey = 'fourth-top-' + matrixData[i].uuid.value +'-'+ i;
            let leftMarginKey = 'fourth-left-' + matrixData[i].uuid.value +'-'+ i;

            let topMarginOriginalKey = 'fourth-top-original-' + matrixData[i].uuid.value +'-'+ i;
            let leftMarginOriginalKey = 'fourth-left-original-' + matrixData[i].uuid.value +'-'+ i;

            if( shiftedMargins ) {
                marginData[topMarginKey] = shiftedMargins['topMargin'];
                marginData[leftMarginKey] = shiftedMargins['leftMargin'];
                if( shiftedMargins['topMarginOriginal'] ) {
                    marginData[topMarginOriginalKey] = shiftedMargins['topMarginOriginal'];
                }
                if( shiftedMargins['leftMarginOriginal'] ) {
                    marginData[leftMarginOriginalKey] = shiftedMargins['leftMarginOriginal'];
                }
            }
        }

    }
    return marginData;
}

const shiftMarginsIfConflictExist = (marginCompetitors: any[], conflictsObj: any, chart: string, cell: any, topMargin: number, leftMargin: number, i: number) : any => {

    let topMarginPercentage = 0, leftMarginPercentage = 0;
    let resultMarginObj: any = {};

    let marginObj: any = {};
    marginObj['name'] = cell.name;
    marginObj['chartNo'] = chart;
    marginObj['topMargin'] = topMargin;
    marginObj['leftMargin'] = leftMargin;

    let conflict: any = checkIfTheMarginConflicts(marginCompetitors, marginObj);

    // left margin to percent
    if(leftMargin === 1) leftMarginPercentage = 0;
    if(leftMargin === 2) leftMarginPercentage = 11;
    if(leftMargin === 3) leftMarginPercentage = 22;
    if(leftMargin === 4) leftMarginPercentage = 33;
    if(leftMargin === 5) leftMarginPercentage = 44;
    if(leftMargin === 6) leftMarginPercentage = 55;
    if(leftMargin === 7) leftMarginPercentage = 66;
    if(leftMargin === 8) leftMarginPercentage = 77;
    if(leftMargin === 9) leftMarginPercentage = 88;
    if(leftMargin === 10) leftMarginPercentage = 100;

    // top margin to percent
    if(topMargin === 10) topMarginPercentage = 0;
    if(topMargin === 9) topMarginPercentage = 11;
    if(topMargin === 8) topMarginPercentage = 22;
    if(topMargin === 7) topMarginPercentage = 33;
    if(topMargin === 6) topMarginPercentage = 44;
    if(topMargin === 5) topMarginPercentage = 55;
    if(topMargin === 4) topMarginPercentage = 66;
    if(topMargin === 3) topMarginPercentage = 77;
    if(topMargin === 2) topMarginPercentage = 88;
    if(topMargin === 1) topMarginPercentage = 100;

    resultMarginObj['topMarginOriginal'] = topMarginPercentage;
    resultMarginObj['leftMarginOriginal'] = leftMarginPercentage;
    if( conflict['happened'] ) {
        let conflictAt = chart +'_'+ conflict['conflictAt'];
        if( conflictsObj && conflictsObj[conflictAt]) {
            conflictsObj[conflictAt] = conflictsObj[conflictAt] + 1;
        } else {
            conflictsObj[conflictAt] = 1;
        }

        let shiftedObject = selectShift( leftMarginPercentage, topMarginPercentage, conflictsObj[conflictAt] )
        if( shiftedObject['leftMargin']) {
            leftMarginPercentage = shiftedObject['leftMargin'];
        }
        if( shiftedObject['topMargin']) {
            topMarginPercentage = shiftedObject['topMargin'];
        }
    }

    resultMarginObj['topMargin'] = topMarginPercentage;
    resultMarginObj['leftMargin'] = leftMarginPercentage;
    return resultMarginObj;

}

const selectShift = (leftMarginPercentage: number, topMarginPercentage: number, noOfConflicts: number ) => {

    let resultMarginObj: any = {};
    let leftShiftBy = 0;
    let topShiftBy = 0;

    switch(noOfConflicts) {
      case 1:
          leftShiftBy = 8;
          leftMarginPercentage += leftShiftBy;
          break;
      case 2:
          leftShiftBy = 8.3;
          leftMarginPercentage -= leftShiftBy;
          break;
      case 3:
          topShiftBy = 12.3;
          topMarginPercentage -= topShiftBy;
          break;
      case 4:
          topShiftBy = 12.5;
          topMarginPercentage += topShiftBy;
          break;
      case 5:
          leftShiftBy = 8;
          topShiftBy = 12.3;
          leftMarginPercentage += leftShiftBy;
          topMarginPercentage -= topShiftBy;
          break;
      case 6:
          leftShiftBy = 8;
          topShiftBy = 12.5;
          leftMarginPercentage += leftShiftBy;
          topMarginPercentage += topShiftBy;
          break;
      case 7:
          leftShiftBy = 8.3;
          topShiftBy = 12.5;
          leftMarginPercentage -= leftShiftBy;
          topMarginPercentage -= topShiftBy;
          break;
      case 8:
          leftShiftBy = 8.3;
          topShiftBy = 12.5;
          leftMarginPercentage -= leftShiftBy;
          topMarginPercentage += topShiftBy;
          break;
    }

    resultMarginObj['topMargin'] = topMarginPercentage;
    resultMarginObj['leftMargin'] = leftMarginPercentage;

    return resultMarginObj;

}

const getTopMargin = (marginCompetitors: any[], attributes: any, axes: any, chart: string, cell: any, i: number) => {

    let value = getYValue(attributes, axes, chart, cell);
    let marginObj: any = {};

    marginObj['name'] = cell.name;
    marginObj['chartNo'] = chart;
    marginObj['topMargin'] = value;

    storeMarginsOfCompetitors(marginCompetitors, marginObj);

    return value;
}

const getYValue = (attributes: any, axes: any, chart: string, cell: any) : number =>  {

    let index = chart === 'first' ? getAttributeIndex(attributes, axes['firstY'][0])
            : chart === 'second' ? getAttributeIndex(attributes, axes['secondY'][0])
            : chart === 'third' ? getAttributeIndex(attributes, axes['thirdY'][0])
            : getAttributeIndex(attributes, axes['fourthY'][0]);

    let value = cell['differentiator' + index];

    return value;
}

const getLeftMargin = (marginCompetitors: any[], attributes: any, axes: any, chart: string, cell: any, i: number) : number => {

    let value = getXValue(attributes, axes, chart, cell);
    let marginObj: any = {};

    marginObj['name'] = cell.name;
    marginObj['chartNo'] = chart;
    marginObj['leftMargin'] = value;

    storeMarginsOfCompetitors(marginCompetitors, marginObj);

    return value;
}

const getXValue = (attributes: any, axes: any, chart: string, cell: any) : number => {

    let index = chart === 'first' ? getAttributeIndex(attributes, axes['firstX'][0])
            : chart === 'second' ? getAttributeIndex(attributes, axes['secondX'][0])
            : chart === 'third' ? getAttributeIndex(attributes, axes['thirdX'][0])
            : getAttributeIndex(attributes, axes['fourthX'][0]);

    let value = cell['differentiator' + index];

    return value;
}

const getAttributeIndex = (attributes: any, axis: any) => {

    for(let i = 1; i <= attributes['first'].length; i++) {
        if(attributes['first'][i-1]['uuid'] === axis['uuid']) {
            return i;
        }
    }
    for(let i = 1; i <= attributes['second'].length; i++) {
        if(attributes['second'][i-1]['uuid'] === axis['uuid']) {
            return i;
        }
    }
    for(let i = 1; i <= attributes['third'].length; i++) {
        if(attributes['third'][i-1]['uuid'] === axis['uuid']) {
            return i;
        }
    }
    for(let i = 1; i <= attributes['fourth'].length; i++) {
        if(attributes['fourth'][i-1]['uuid'] === axis['uuid']) {
            return i;
        }
    }
    return 1;
}



const checkIfTheMarginConflicts = (marginCompetitors: any[], marginObj: any) => {

    let conflictObj: any = {
        'happened': false
    }

    let conflict = false;

    if(marginCompetitors && marginCompetitors.length ) {

        for( var i=0; i< marginCompetitors.length; i++ ) {
            if( marginObj.name !== marginCompetitors[i].name
              && marginObj.topMargin === marginCompetitors[i].topMargin
              && marginObj.leftMargin === marginCompetitors[i].leftMargin
              && marginObj.chartNo === marginCompetitors[i].chartNo
            ) {
                conflict = true;
                conflictObj['happened'] = conflict;
                conflictObj['conflictAt'] = i;
                break;
            }
        }
    }

    return conflictObj;
}

const storeMarginsOfCompetitors = (marginCompetitors: any[], marginObj: any) => {

    let exist = checkIfMarginObjectAlreadyExist(marginCompetitors, marginObj);

    if( exist !== -1 ) {
        if( marginObj['leftMargin'] ) {
            marginCompetitors[exist]['leftMargin'] = marginObj['leftMargin'];
        } else if( marginObj['topMargin'] ){
            marginCompetitors[exist]['topMargin'] = marginObj['topMargin'];
        }
    } else {
        marginCompetitors.push(marginObj);
    }

}

const checkIfMarginObjectAlreadyExist = (marginCompetitors: any[], marginObj: any) => {

    var existAt = -1;
    if(marginCompetitors && marginCompetitors.length ) {
        for( var i=0; i < marginCompetitors.length; i++ ) {
            if( marginObj.name === marginCompetitors[i].name
              && marginObj.chartNo === marginCompetitors[i].chartNo
            ) {
                existAt = i;
                break;
            }
        }
    }

    return existAt;
}

const getRankedDifferentiators = (differentiators: Array<any>, rankings: Array<any>)  => {

    differentiators = differentiators.filter(x => x['checked'] === true);
    differentiators.sort((a, b) => {
        let aRanking = rankings.find(x => x['uuid']['value'] === a['uuid']['value']);
        let bRanking = rankings.find(x => x['uuid']['value'] === b['uuid']['value']);

        return bRanking['score'] - aRanking['score'];
    });

    return differentiators;
}

const useProvider = (researchTools: ITool[], provider: string) => {

    if(!researchTools || !provider) {
        return false;
    }

    for(var i = 0; i < researchTools.length; i++) {
        if(researchTools[i]['data']['researchType'] === provider) {
            return true;
        }
    }

    return false;
}

const pushToContentData = (content: Record<string, any>[], pageNo: number, dimension: any, noOfLines: number) => {

    let allowedNoOfLines: number = 15;

    if( content && content.length && (content[pageNo]) ) {
        let lastElement = content[content.length-1];

        // if the second column is setup
        if( lastElement['secondNoOfLines'] ) {
            let updatedNoOfLines: number = noOfLines + lastElement['secondNoOfLines'];

            if( updatedNoOfLines <= allowedNoOfLines ) {
                content[pageNo]['secondNoOfLines'] = updatedNoOfLines;
                content[pageNo]['second'].push(dimension);
            } else {
                pageNo++;
                let newPagedata : Record<string, any> = {
                    'first': [],
                    'firstNoOfLines': noOfLines,
                }
                newPagedata['first'].push(dimension);
                content.push(newPagedata);
            }

        // if the first column is setup
        } else if( lastElement['firstNoOfLines'] ) {

            let updatedNoOfLines = noOfLines + lastElement['firstNoOfLines'];

            if( updatedNoOfLines <= allowedNoOfLines ) {
                content[pageNo]['firstNoOfLines'] = updatedNoOfLines;
                content[pageNo]['first'].push(dimension);
            } else {
                content[pageNo]['secondNoOfLines'] = noOfLines;
                content[pageNo]['second'] = [];
                content[pageNo]['second'].push(dimension);
            }
        }

    // if the mainBusiness has no data
    } else {
        let newPagedata : Record<string, any> = {
            'first': [],
            'firstNoOfLines': noOfLines,
        }
        newPagedata['first'].push(dimension);
        content.push(newPagedata);
    }

    return pageNo;
}

const getNumberOfLines = (dimension: any) => {
    let noOfCharactersPerLine = 60

    let noOfCharacters = 0;
    if( dimension['headline'] ) {
        noOfCharacters += dimension['headline'].length;
    }
    if( dimension['description'] ) {
        noOfCharacters += dimension['description'].toString().length;
    }
    let noOfLines = noOfCharacters / noOfCharactersPerLine;
    if( dimension['question'] ) {
        if( noOfLines < 2 ) {
            noOfLines = 2;
        }
    }

    noOfLines = Math.ceil(noOfLines);
    return noOfLines;
}
