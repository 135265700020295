import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { IProject } from "../../store/projects/types";
import { State } from "../../store/shared/state";
import { ITool, ResearchType, ToolType } from "../../store/tools/types";

export const DashboardExamples: React.FC = () => {

  const { t } = useTranslation();

  const projects = useSelector((state: State) => state.projects.exampleProjects);

  const goToTool = (project: IProject, tool: ITool) => {
    var url = `${process.env.REACT_APP_LEGACY_BASE_URL}`;

    switch(tool.type) {

      case ToolType.RESEARCH:
        url += getResearchToolUrl(tool);
        break;

        case ToolType.BRAND_STRATEGY:
        url += '/discovery/';
        break;

      case ToolType.MEDIA_BRIEF:
        url += '/media-brief/';
        break;

      case ToolType.COMPETITIVE_MAPPING:
        url += '/competitive-mapping/competitive-mapping-competitors/';
        break;

      case ToolType.CREATIVE_BRIEF:
        url += '/creative-brief/';
        break;

      case ToolType.CREATIVE_COMPETITIVE_OVERVIEW:
        url += '/creative-competitive-overview/';
        break;

        case ToolType.SCRIPT_TEMPLATE:
        url += '/script-template/';
        break;

      default:
        break;
    }
    
    url += project.uuid['value'] + '/' + tool.uuid['value'];

    window.location.href = url;
  }

  const getResearchToolUrl = (tool: ITool) : string => {

    if(!tool.data || !tool.data['researchType']) {
      return '';
    }

    switch(tool.data['researchType']) {
      case ResearchType.ROY_MORGAN: 
        return '/roy-morgan-research/';
      case ResearchType.AUSTRALIA_POST: 
        return '/australia-post-research/';
      case ResearchType.CUSTOM: 
        return '/custom-research/';
      default:
        return '';
    }
  }

  return (
    <div className="table-container main secondary">
      <table>
        <thead>
          <tr>
            <th>{t('dashboard.exampleProjects.project')}</th>
            <th className="d-none label d-xl-table-cell">{t('dashboard.exampleProjects.status')}</th>
            <th className="d-none d-sm-table-cell actions single">{t('dashboard.exampleProjects.actions')}</th>
          </tr>
        </thead>
        <tbody>
          {projects.map((project: IProject) => (
             project.tools.map((tool: ITool) => (
              <tr key={tool.uuid['value'].toString()}>
                <td>
                  {tool.type === ToolType.RESEARCH && <a href={`${process.env.REACT_APP_LEGACY_BASE_URL}${getResearchToolUrl(tool)}${project.uuid['value']}/${tool.uuid['value']}`}>{tool.name}</a>}
                  {tool.type === ToolType.BRAND_STRATEGY && <a href={`${process.env.REACT_APP_LEGACY_BASE_URL}/discovery/${project.uuid['value']}/${tool.uuid['value']}`}>{tool.name}</a>}
                  {tool.type === ToolType.CREATIVE_BRIEF && <a href={`${process.env.REACT_APP_LEGACY_BASE_URL}/creative-brief/${project.uuid['value']}/${tool.uuid['value']}`}>{tool.name}</a>}
                  {tool.type === ToolType.MEDIA_BRIEF && <a href={`${process.env.REACT_APP_LEGACY_BASE_URL}/media-brief/${project.uuid['value']}/${tool.uuid['value']}`}>{tool.name}</a>}
                  {tool.type === ToolType.COMPETITIVE_MAPPING && <a href={`${process.env.REACT_APP_LEGACY_BASE_URL}/competitive-mapping/competitive-mapping-competitors/${project.uuid['value']}/${tool.uuid['value']}`}>{tool.name}</a>}
                  {tool.type === ToolType.CREATIVE_COMPETITIVE_OVERVIEW && <a href={`${process.env.REACT_APP_LEGACY_BASE_URL}/creative-competitive-overview/${project.uuid['value']}/${tool.uuid['value']}`}>{tool.name}</a>}
                  {tool.type === ToolType.SCRIPT_TEMPLATE && <a href={`${process.env.REACT_APP_LEGACY_BASE_URL}/script-template/${project.uuid['value']}/${tool.uuid['value']}`}>{tool.name}</a>}
                </td>
                <td className="d-none d-xl-table-cell label">
                  <span>Example Only</span>
                </td>
                <td className="d-none d-sm-table-cell actions single">
                  <button className="open" onClick={() => { goToTool(project, tool)}}>{t('dashboard.exampleProjects.open')}</button>
                </td>
              </tr>
            ))
          ))}
        </tbody>                  
      </table>
    </div>
  );
};

export default DashboardExamples;
