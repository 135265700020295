import { Status } from "../shared/status";
import { ReportingState, ReportingAction } from "./types";

const initialState: ReportingState = {
  summary: null,
  getSummaryStatus: Status.IDLE,
  getSummaryError: null
};

const reportingReducer = (state: ReportingState = initialState, action: ReportingAction): ReportingState => {

  switch (action.type) {

    case 'reporting/get-summary':

      return {
        ...state,
        summary: action.payload,
        getSummaryStatus: Status.SUCCESS,
        getSummaryError: action.payload
      };

    case 'reporting/get-summary-loading':

      return {
        ...state,
        getSummaryStatus: Status.LOADING,
        getSummaryError: null
      };

    case 'reporting/get-summary-error':

      return {
        ...state,
        getSummaryStatus: Status.FAILED,
        getSummaryError: action.payload
      };

    case 'reporting/get-summary-success':

      return {
        ...state,
        summary: action.payload,
        getSummaryStatus: Status.SUCCESS,
        getSummaryError: action.payload
      };
  }
  
  return state;
};

export default reportingReducer;
