import { Guid } from "guid-typescript";

export enum Role {
  USER = 'USER',
  REPORTING_VIEW = 'REPORTING_VIEW',
  AGENCIES_MANAGE = 'AGENCIES_MANAGE',
  USERS_MANAGE = 'USERS_MANAGE',
  PROJECTS_MANAGE = 'PROJECTS_MANAGE',
}

export const allowAdmin = (): boolean => {
  const agency = getAgencyFromToken();
  return agency != null && agency['admin'] === true;
};

export const allowSuperuser = (): boolean => {
  const token = getValidToken();
  return token !== null && hasRole(Role.REPORTING_VIEW);
};

export const hasRole = (role: Role) : boolean => {

  var decodedToken = getDecodedToken();

  let permission = decodedToken['role'].find((x: string) => x === role);
  return permission != null && permission !== undefined;
}

export const getUserIdFromToken = (): Guid | null => {

  var decodedToken = getDecodedToken();

  return decodedToken != null ? Guid.parse(decodedToken['identifier']) : null;
};

export const getAgencyIdFromToken = (): Guid | null => {

  const agency = getAgencyFromToken();

  return agency != null ? Guid.parse(agency['uuid']) : null;
};

export const getAgencyFromToken = (): any | null => {

  var decodedToken = getDecodedToken();

  return decodedToken != null && decodedToken['agencies'].length >= 1 ? decodedToken['agencies'][0] : null;
};

export const getFirstNameFromToken = (): string => {

  var decodedToken = getDecodedToken();
 
  return decodedToken !== null ? decodedToken['firstName'] : '';
};

export const getInitialsFromToken = (): string => {

  var decodedToken = getDecodedToken();

  return decodedToken !== null ? decodedToken['firstName'].substring(0, 1) + decodedToken['lastName'].substring(0, 1) : '';
};

const getDecodedToken = (): any => {

  const token = getToken();

  if(!token || token === undefined || token === '' || token === 'null') {
    return null;
  }

  return JSON.parse(atob(token.split('.')[1]));
}

export const setToken = (token: string) : void => localStorage.setItem('token', token);
export const getToken = () : string | null => localStorage.getItem('token');
export const setRefreshToken = (token: string) : void => localStorage.setItem('refresh_token', token);
export const getRefreshToken = () : string | null => localStorage.getItem('refresh_token');

export const hardCodeTokens = () : void => {
  //TODOmjhorne
  setToken('eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzUxMiJ9.eyJpZGVudGlmaWVyIjoiMDA3ZWJlZTYtMmM3ZC00M2VlLWJiMmEtZGE1ODY5NWY0YmI0Iiwic3ViIjoibWF0dEA3OGNvbmNlcHRzLmNvbSIsImZpcnN0TmFtZSI6Ik1hdHQiLCJsYXN0TmFtZSI6Ikhvcm5lIiwicm9sZSI6WyJBR0VOQ0lFU19NQU5BR0UiLCJVU0VSU19NQU5BR0UiLCJQUk9KRUNUU19NQU5BR0UiLCJVU0VSIiwiUkVQT1JUSU5HX1ZJRVciXSwiaXNzIjoiYnJhbmR2YXMiLCJhY3Rpb25zUmVxdWlyZWQiOnsiZmlyc3RVc2VyQWN0aW9ucyI6ZmFsc2UsInBhc3N3b3JkUmVzZXQiOmZhbHNlLCJmaXJzdEFnZW5jeUFjdGlvbnMiOltdfSwiYWdlbmNpZXMiOlt7ImFkbWluIjp0cnVlLCJ1dWlkIjoiMWM0MTc1ZTUtYzUxMy00Y2MwLTgzYzEtZmU0NjRhYTA5MTllIn1dLCJleHAiOjE2NDU4MzY2NzE4MjksImlhdCI6MTY0NTgzNjA3MTgyOX0.UP5YawrqXYFwqWs3Fah2OGAwDkulaqDACJX6ZR--4kaZ6T4_os-iNYvhDdbhz8wAk7kaVXRfO9SdCfRynreSLw');
  setRefreshToken('c1202bf6-9850-4079-9c32-146fe6e90727');
}


const deleteToken = () : void => localStorage.removeItem('token');
const deleteRefreshToken = () : void => localStorage.removeItem('refresh_token');

export const logout = () : void => {

  deleteToken();

  window.location.href = `${process.env.REACT_APP_LEGACY_BASE_URL}/auth/login`;
}

export const isLoggedIn = () : boolean => {

  const token = getValidToken();

  if(token === null) {
      return false;
  }

  if (token.exp < new Date().getTime()) {
      // console.error("Token expiry is in the past");
      return false;
  }

  return true;
}

const getValidToken = () : any => {

  try {
    var decodedToken = getDecodedToken();

    if(decodedToken == null) {
      deleteToken();
      deleteRefreshToken();
      return null;
    }


    if (decodedToken.iat > new Date().getTime()) {
        console.warn("AuthService - Token issued at time is in the future");
        // this.removeTokens();
        // return null;
    }

    if(decodedToken['role']) {
        let roles: string[] = [];
        for(let i in decodedToken['role']) {
          if(Object.values(Role).filter(k => isNaN(Number(k))).filter(x => x === decodedToken['role'][i]).length > 0) {
              roles.push(decodedToken['role'][i]);
          }
        }
        decodedToken['role'] = roles;
    }

    return decodedToken;

  } catch (e) {
      console.error("AuthService - Token is invalid: " + e);
      deleteToken();
      deleteRefreshToken();
      return null;
  }
}

export const canActivateAdminRoute = () : boolean => {

  if(!canActivateRoute()) {
    return false;
  }

  return allowSuperuser();
}

export const canActivateRoute = () : boolean => {

  if(isLoggedIn()) {
  
    //TODOmjhorne
    // if(this.passwordResetRequired() && this.router.url == '/auth/reset') {
    //   return of(true);
    // }

    // return this.hasRequiredRoles(route);
    return true;
  }

  if(!getValidToken()) {

    // if(!this.isPublicPage()) {
    //   this.router.navigate(['/auth/login']);
    //   return of(false);
    // } else {
    //   this.router.navigate([this.router.url]);
    //   return of(true);
    // }
    return false;
  }

  //TODOmjhorne
  return true;
  // return this.authService.refreshToken()
  //   .pipe(
  //     switchMap(tokenDetails => {
  //       if(tokenDetails) {
  //         this.authService.setToken(tokenDetails['token']);
  //         this.authService.setRefreshToken(tokenDetails['refreshToken']);

  //         if(this.passwordResetRequired()) {
  //           this.router.navigate(['/auth/reset']);
  //           return of(false);
  //         }

  //         return this.hasRequiredRoles(route);
  //       }

  //       this.authService.logout();
  //       this.router.navigate(['/auth/login']);

  //       return of(false);
  //     }),
  //     catchError((error, caught) => {
  //       this.authService.logout();
  //       this.router.navigate(['/auth/login']);
  //       return of(false);
  //     }),
  //     finalize(() => {
  //       this.loader = false;
  //     })
  //   );
}