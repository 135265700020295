import React, { useEffect, useState } from "react";
import { Modal } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { selectClient, toggleAddClient, toggleSelectClient } from "../../store/clients/actions";
import { useDispatch } from "react-redux";
import { State } from "../../store/shared/state";
import { Status } from "../../store/shared/status";
import { IClient } from "../../store/clients/types";
import SelectClientLoader from "./SelectClientLoader";
import { listArchivedProjects, listProjects } from "../../store/projects/actions";
import { Guid } from "guid-typescript";
import { useTranslation } from "react-i18next";

type Props = {
  selectedClient: IClient | null
};

export const SelectClient: React.FC<Props> = ({ selectedClient }) => {

  const { t } = useTranslation();
  const dispatch = useDispatch();

  const selectClientOpened = useSelector((state: State) => state.clients.selectClientOpened);
  const clients = useSelector((state: State) => state.clients.clients);

  const clientsStatus = useSelector((state: State) => state.clients.listClientsStatus);

  const [filter, setFilter] = useState('');

  useEffect(() => {
    updateModalLocation();
  });

  const handleSearch = (value: string) => {
    setFilter(value);
  }

  const doSelectClient = (clientId: Guid) => {
    dispatch(selectClient(clientId))
    listProjects(dispatch, clientId);
    listArchivedProjects(dispatch, clientId);
    dispatch(toggleSelectClient())
  }

  window.addEventListener('resize', () => {
    updateModalLocation();
  });

  const updateModalLocation = () => {
    let modals = document.querySelectorAll<HTMLElement>('.select-modal');
    let toggle = document.getElementById('headerSelectClientToggle');

    if(toggle && modals[0] && window.innerWidth >= 1200) {
      modals[0].style.left = (toggle.getBoundingClientRect().x - 2) + 'px';
      modals[0].style.top = (toggle.getBoundingClientRect().y - 28) + 'px';
    } else if(modals[0]) {
      modals[0].style.left = "0px";
    }
  }

  const loadingBlock = clientsStatus === Status.LOADING ? ( <SelectClientLoader /> ) : null;
  const clientsBlock = clientsStatus !== Status.LOADING ? (
    <div>
      {clients.filter(x => x.example !== true && (x.name.toLowerCase().includes(filter.toLowerCase()) || filter === '')).map((client: any) => (
        <div className="selectable-row" key={client.uuid['value'].toString()} onClick={() => { doSelectClient(client.uuid); }}>
         {client.name}
        </div>
      ))}
    </div>
  ) : null;
  
  return (

    <Modal className="select-modal"
      show={selectClientOpened}
      scrollable={true}
      animation={false}
      onShow={() => setFilter('') }
      onHide={() => dispatch(toggleSelectClient())}
    >

      <Modal.Header>
        <Modal.Title><span>{selectedClient?.example && <span>{t('header.selectClient')}</span>}{!selectedClient?.example && selectedClient?.name}</span> <i className="fas fa-chevron-up" onClick={() => { dispatch(toggleSelectClient()) }}></i></Modal.Title>

        <button type="button" onClick={() => { dispatch(toggleSelectClient()); dispatch(toggleAddClient()) }}><i className="fas fa-plus"></i> Add</button>

      </Modal.Header>

      <Modal.Body>
        <input type="text" placeholder="Search for a client" value={filter} onChange={event => handleSearch(event.target.value)} />

        {loadingBlock}
        {clientsBlock}

      </Modal.Body>
    </Modal>

  );
};
export default SelectClient;