import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { listArchivedProjects, listProjects } from "../../store/projects/actions";
import { State } from "../../store/shared/state";

export const ProjectsListError: React.FC = () => {

  const { t } = useTranslation();

  const dispatch = useDispatch();

  const selectedClient = useSelector((state: State) => state.clients.selected);

  return selectedClient != null ? (
    <div className="alert list alert-danger reload"><p>{t('projects.projectsError')}</p><i onClick={() => { listProjects(dispatch, selectedClient!.uuid); listArchivedProjects(dispatch, selectedClient!.uuid) }} className="fas fa-sync-alt"></i></div>
  ) : null;
};

export default ProjectsListError;
