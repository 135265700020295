import React from "react";
import { Modal } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { useDispatch } from "react-redux";
import { State } from "../../store/shared/state";
import { Status } from "../../store/shared/status";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { archiveProject, toggleArchiveProject } from "../../store/projects/actions";

type FormData = {
  projectNotes: string;
};

export const ArchiveProject: React.FC = () => {

  const { t } = useTranslation();
  const { handleSubmit } = useForm<FormData>();

  const selectedProject = useSelector((state: State) => state.projects.selected);

  const archiveProjectOpened = useSelector((state: State) => state.projects.archiveProjectOpened);
  const archiveProjectStatus = useSelector((state: State) => state.projects.archiveProjectStatus);
  const archiveProjectError = useSelector((state: State) => state.projects.archiveProjectError);

  const submitButtonText = archiveProjectStatus !== Status.LOADING 
    ? !selectedProject?.archived 
      ? ( <span>{t('archiveProject.submit')} <i className="fas d-none d-sm-inline fa-chevron-right"></i></span> )
      : ( <span>{t('archiveProject.submitAlt')} <i className="fas d-none d-sm-inline fa-chevron-right"></i></span> )
    : ( <span>{t('archiveProject.processing')} <i className="fas d-none d-sm-inline fa-spin fa-circle-notch"></i></span> );

  const dispatch = useDispatch();

  const onSubmit = (data: any, errors: any) => {
    archiveProject(
      dispatch, 
      selectedProject
    );
  }

  const onErrors = (errors: any) => console.error(errors);

  return (

    <Modal className="modal confirm-modal grey-modal"
      show={archiveProjectOpened}
      animation={false}
      onHide={() => dispatch(toggleArchiveProject())}
      centered
    >

      <Modal.Header>
        <Modal.Title>{t('archiveProject.title')}</Modal.Title>
        <button type="button" data-bs-dismiss="modal" aria-label="Close" onClick={() => { dispatch(toggleArchiveProject()) }}><i className="fas fa-times"></i></button>
      </Modal.Header>

      <Modal.Body>
        <form onSubmit={handleSubmit(onSubmit, onErrors)}>
          <fieldset>
            {!selectedProject?.archived && <p>{t('archiveProject.warning')}</p>}
            {selectedProject?.archived && <p>{t('archiveProject.unarchive')}</p>}
            <p className="action-object">{selectedProject?.name}</p>
            <p>{t('archiveProject.confirm')}</p>

            <div className="server-error">{t(archiveProjectError)}</div>

          </fieldset>
          <div className="actions">
            <button className="danger" type="submit" disabled={archiveProjectStatus === Status.LOADING}>{submitButtonText}</button>
          </div>
        </form>

      </Modal.Body>
    </Modal>

  );
};
export default ArchiveProject;