import * as React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { toggleAddClient } from "../../store/clients/actions";

type Props = {
  totalClients: number;
  totalProjects: number;
};

export const DashboardActions: React.FC<Props> = ({ totalClients, totalProjects }) => {

  const { t } = useTranslation();

  const dispatch = useDispatch();
  
  const addClient = () => dispatch(toggleAddClient());

  const helpBlock = totalClients === 0 ? (<h4>{t('dashboard.addClient')}</h4>) : null;

  return (
    <React.Fragment>
      <button className="add" onClick={addClient}>
        <i className="fas fa-plus-circle"></i>
        {t('dashboard.client')}
      </button>
      {helpBlock}
    </React.Fragment>
  );
  
};

export default DashboardActions;
