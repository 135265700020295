import React, { useRef } from "react";
import { Modal } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import { State } from "../../store/shared/state";
import { Status } from "../../store/shared/status";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { hideSendPureSpectrumEnquiry, sendPureSpectrumEnquiry } from "../../store/tools/actions";
import IntlTelInput from 'react-intl-tel-input';
import 'react-intl-tel-input/dist/main.css';

type FormData = {
  name: string;
  agencyName: string;
  emailAddress: string;
  phoneNumber: any;
  message: string;
};

export const SendPureSpectrumEnquiry: React.FC = () => {

  const { t } = useTranslation();
  const { register, handleSubmit, control, formState: { errors }, reset, getValues, setValue} = useForm<FormData>();

  const phoneNumberRef = useRef<IntlTelInput | null>(null);

  const sendPureSpectrumEnquiryOpened = useSelector((state: State) => state.tools.sendPureSpectrumEnquiryOpened);
  const sendPureSpectrumEnquiryStatus = useSelector((state: State) => state.tools.sendPureSpectrumEnquiryStatus);
  const sendPureSpectrumEnquiryError = useSelector((state: State) => state.tools.sendPureSpectrumEnquiryError);

  const defaultValues = { name: '', agencyName: '' , emailAddress: '', phoneNumber: '', message: ''};
 
  const submitButtonText = sendPureSpectrumEnquiryStatus !== Status.LOADING 
    ? ( <span>{t('sendPureSpectrumEnquiry.submit')} <i className="fas fa-chevron-right"></i></span> )
    : ( <span>{t('sendPureSpectrumEnquiry.processing')} <i className="fas fa-spin fa-circle-notch"></i></span> );

  const dispatch = useDispatch();

  const onSubmit = (data: any, errors: any) => {
    
    data['phoneNumber'] = getPhoneData(phoneNumberRef, data['phoneNumber']);

    sendPureSpectrumEnquiry(dispatch, data);
  }
  
  const getPhoneData = (ref: React.MutableRefObject<IntlTelInput | null>, value: any): any => {

    if(!ref || !ref.current) {
      return null;
    }

    let countryCode = ref.current!.selectedCountryData ? ref.current!.selectedCountryData.iso2?.toUpperCase() : null;
    let dialCode = ref.current!.selectedCountryData ? ref.current!.selectedCountryData.dialCode : null;

    let response: any = {
      number: value,
      internationalNumber: ref.current!.formatFullNumber(value),
      nationalNumber: ref.current!.formatNumber(value),
      e164Number: ref.current!.formatFullNumber(value).replaceAll(' ', ''),
      countryCode: countryCode,
      dialCode: '+' + dialCode
    };

    return response;
  }

  const onErrors = (errors: any) => console.error(errors);

  return (

    <Modal className="modal form-modal complex-modal yellow-modal double"
      show={sendPureSpectrumEnquiryOpened}
      animation={false}
      onShow={() => { 
        reset(defaultValues);
        if(phoneNumberRef.current && phoneNumberRef.current.tel) {
          phoneNumberRef.current.updateValFromNumber('', true, true);
        }
      }}
      onHide={() => { reset(defaultValues); dispatch(hideSendPureSpectrumEnquiry()) }}
      centered
    >

      <Modal.Header>
        <Modal.Title>
          {t('sendPureSpectrumEnquiry.title')}
          </Modal.Title>
        <button type="button" data-bs-dismiss="modal" aria-label="Close" onClick={() => { reset(defaultValues); dispatch(hideSendPureSpectrumEnquiry()) }}><i className="fas fa-times"></i></button>
      </Modal.Header>

      <Modal.Body>
        <form onSubmit={handleSubmit(onSubmit, onErrors)}>
          <fieldset>

            <div className="row">
              <div className="col-12 col-lg-6">
                <label>
                  <div className="row">
                    <div className="col-12 col-lg-6">{t('form.name')}</div>
                    <div className="col-12 col-lg-6 error">{errors.name?.type === 'required' && t('form.required', { field: t('form.name') })}</div>
                  </div>
                </label>
                <input type="text" {...register("name", { required: true, maxLength: 255 })} /> 

                <label>
                  <div className="row">
                    <div className="col-12 col-lg-6">{t('sendPureSpectrumEnquiry.agency')}</div>
                    <div className="col-12 col-lg-6 error">{errors.agencyName?.type === 'required' && t('form.required', { field: t('sendPureSpectrumEnquiry.agency') })}</div>
                  </div>
                </label>
                <input type="text" {...register("agencyName", { required: true, maxLength: 255 })} /> 
            

                <label>
                  <div className="row">
                    <div className="col-12 col-lg-6">{t('form.message')}</div>
                    <div className="col-12 col-lg-6 error">{errors.message?.type === 'required' && t('form.required', { field: t('form.message') })}</div>
                  </div>
                </label>
                <textarea {...register("message", { required: true, maxLength: 255 })}></textarea>

              </div>
              <div className="col-12 col-lg-6">

              <label>
                  <div className="row">
                    <div className="col-12 col-lg-6">{t('sendPureSpectrumEnquiry.emailAddress')}</div>
                    <div className="col-12 col-lg-6 error">
                      {errors.emailAddress?.type === 'required' && t('form.required', { field: t('form.emailAddress') })}
                      {errors.emailAddress?.type === 'pattern' && t('form.invalid', { field: t('form.emailAddress') })}
                    </div>
                  </div>
                </label>
                <input type="text" {...register("emailAddress", { required: true, pattern: /\S+@\S+\.\S+/, maxLength: 255 })} /> 

                <label>
                  <div className="row">
                    <div className="col-12 col-lg-6">{t('sendPureSpectrumEnquiry.phoneNumber')}</div>
                    <div className="col-12 col-lg-6 error">
                      {errors.phoneNumber?.type === 'required' && t('form.required', { field: t('form.phoneNumber') })}
                      {errors.phoneNumber?.type === 'validate' && t('form.invalid', { field: t('form.phoneNumber') })}
                      </div>
                  </div>
                </label>

                <Controller
                    name="phoneNumber"
                    control={control}
                    rules={{ required: true, validate: (value) => { 
                      return phoneNumberRef.current ? phoneNumberRef.current!.isValidNumber(value) : false;
                    }}}
                    render={({
                      field: { onChange, onBlur, value, name, ref },
                      fieldState: { invalid, isTouched, isDirty, error },
                      formState,
                    }) => (
                      <IntlTelInput
                        ref={phoneNumberRef}
                        onPhoneNumberChange={(status, value, country) => {
                          setValue('phoneNumber', value, { shouldDirty: true })
                        }}
                        onPhoneNumberBlur={(status, value, country) => {
                          setValue('phoneNumber', value, { shouldDirty: true, shouldValidate: true });
                        }}
                        defaultValue={getValues('phoneNumber')}
                        containerClassName="intl-tel-input"
                        inputClassName={`form-control ${errors && errors.phoneNumber ? 'is-invalid' : ''}`}
                        preferredCountries={['au']}
                        format={true}
                      />
                    )}
                />

                <label>
                  <div className="row">
                    <div className="col-12">{t('form.note')}</div>
                  </div>
                </label>
                <p className="note">If you would like to speak directly with PureSpectrum, please call Anthony on +61 402 466 230</p>

              </div>
            </div>

            <div className="server-error">{t(sendPureSpectrumEnquiryError)}</div>
            <div className="actions">
              <button type="submit" disabled={sendPureSpectrumEnquiryStatus === Status.LOADING}>{submitButtonText}</button>
            </div>

          </fieldset>
        </form>

      </Modal.Body>
    </Modal>

  );
};
export default SendPureSpectrumEnquiry;
