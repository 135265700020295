import { Guid } from "guid-typescript";

export function transformList<Type>(data: string, key: string) : any { 
    let json = JSON.parse(data);

    if(!json || !json[key])
    { 
        return json;
    }

    json[key] = json[key].map((x: any) => transformObject<Type>(x));

    return json;
};

export function transformObjectFromString<Type>(data: any) : any { 
    return transformObject<Type>(JSON.parse(data));
};

export function transformObject<Type>(data: any) : any { 
    for (let key of Object.keys(data)) {
        if(key.endsWith('uuid') || key.endsWith('Id')) {
            try {
                data[key] = Guid.parse(data[key]);
            } catch(e) {
                console.error(e);
            }
        } else if(key.startsWith('date')) {
            data[key] = data[key] !== null ? new Date(data[key]) : null;
        } else if(Array.isArray(data[key])) {
            for (let child of Object.keys(data[key])) {
                data[key][child] = transformObject(data[key][child]);
            }
        }

    }
    return data as Type;
};
  
export function normaliseErrorCode(error: any, prefix: string) : string {
    if(error && error.response && !error.response.data.message.startsWith('web-public.')) {
        return `errors.${error.response.data.message}`;
    } else {
        return `errors.${prefix}.default`;
    }
}