import { Status } from "../shared/status";
import { ReportsAction, ReportsState } from "./types";

const initialState: ReportsState = {
  reports: [],
  totalReports: 0,
  listReportsStatus: Status.IDLE,
  listReportsError: null,
  deleteReportStatus: Status.IDLE,
  deleteReportError: null,
  generateReportOpened: false,
  generateReportStatus: Status.IDLE,
  generateReportError: null
};

const reportsReducer = (state: ReportsState = initialState, action: ReportsAction): ReportsState => {

  switch (action.type) {

    case 'reports/list-reports-loading':

      return {
        ...state,
        listReportsStatus: Status.LOADING,
        listReportsError: null
      };

    case 'reports/list-reports-error':

      return {
        ...state,
        listReportsStatus: Status.FAILED,
        listReportsError: action.payload
      };

    case 'reports/list-reports-success':

      return {
        ...state,
        reports: action.payload.reports,
        totalReports: action.payload.total,
        listReportsStatus: Status.SUCCESS,
        listReportsError: null
      };

    case 'reports/delete-report-loading':

      return {
        ...state,
        deleteReportStatus: Status.LOADING,
        deleteReportError: null
      };

    case 'reports/delete-report-error':

      return {
        ...state,
        deleteReportStatus: Status.FAILED,
        deleteReportError: action.payload
      };

    case 'reports/delete-report-success':

      let reports = state.reports.slice();

      for(let i = 0; i < reports.length; i++) {
        if(reports[i].uuid['value'] === action.payload['value']) {
          reports.splice(i, 1);
        }
      }

      let totalReports = reports.length;

      return {
        ...state,
        reports: reports,
        totalReports: totalReports,
        deleteReportStatus: Status.SUCCESS,
        deleteReportError: null
      };      

    case 'reports/toggle-generate-report':

      return {
        ...state,
        generateReportOpened: !state.generateReportOpened,
        generateReportError: state.generateReportOpened ? state.generateReportError : null
      };

    case 'reports/close-generate-report':

      return {
        ...state,
        generateReportOpened: false,
        generateReportError: null
      };
  
    case 'reports/generate-report-loading':

      return {
        ...state,
        generateReportStatus: Status.LOADING,
        generateReportError: null
      };

    case 'reports/generate-report-error':

      return {
        ...state,
        generateReportStatus: Status.FAILED,
        generateReportError: action.payload
      };

    case 'reports/generate-report-success':

      let reports2 = state.reports.slice();
      let totalReports2 = state.totalReports + 1;

      reports2.unshift(action.payload);

      return {
        ...state,
        reports: reports2,
        totalReports: totalReports2,
        generateReportStatus: Status.SUCCESS,
        generateReportError: null
      };
  }

  return state;
};

export default reportsReducer;
