import * as React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { State } from '../../store/shared/state';
import { Status } from '../../store/shared/status';
import { hideTemplateMenu, toggleAddTemplate } from "../../store/templates/actions";
import AddProject from "./AddProject";

export const CompetitorsTitle: React.FC = () => {

  const { t } = useTranslation();

  const dispatch = useDispatch();

  const clientsStatus = useSelector((state: State) => state.clients.listClientsStatus);
  const selectedProject = useSelector((state: State) => state.projects.selected);

  const addTemplate = () => {
    if(clientsStatus === Status.SUCCESS) {
      dispatch(hideTemplateMenu());
      dispatch(toggleAddTemplate());
    }
  }

  return (
    <div className="row justify-content-end">
      <div className="col-12 col-xl">
        <h1>{selectedProject?.name} {t('competitors.title')}</h1>
      </div>
      <div className="col-12 col-xl-auto buttons">
        <button className="add" onClick={addTemplate}>
          <i className="fas fa-plus-circle"></i>
          {t('competitors.competitor')}
        </button>
        <AddProject />
      </div>
    </div>
  );
};

export default CompetitorsTitle;
