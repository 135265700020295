import * as React from "react";
import { useTranslation } from "react-i18next";
import { getFirstNameFromToken } from "../../shared/auth";

type Props = {
  firstTime: boolean;
};

export const DashboardIntroduction: React.FC<Props> = ({ firstTime }) => {

  const { t } = useTranslation();

  return firstTime ? (
    <React.Fragment>
      <h1>{t('dashboard.getStarted')}</h1>
      <p className="introduction">
        {t('dashboard.introduction')}
      </p>
    </React.Fragment>
  ) : (
    <React.Fragment>
      <h1>{t('dashboard.welcomeBack')}, {getFirstNameFromToken()}</h1>
      <p className="introduction">
        {t('dashboard.introductionExisting')}
      </p>
    </React.Fragment>
  );
};

export default DashboardIntroduction;
