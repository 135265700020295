import * as React from "react";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { listClients } from "../../store/clients/actions";

export const ClientsListError: React.FC = () => {

  const { t } = useTranslation();

  const dispatch = useDispatch();

  return (
    <div className="alert list alert-danger reload"><p>{t('clients.clientsError')}</p><i onClick={() => { listClients(dispatch) }} className="fas fa-sync-alt"></i></div>
  );
};

export default ClientsListError;
