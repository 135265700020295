import axios from '../../shared/http';
import { normaliseErrorCode, transformObjectFromString } from '../shared/transform';
import { ITool, ResearchType, ToolType } from './types';
import { gotoLegacyUrl } from '../../shared/queryString';
import { toast } from 'react-toastify';
import i18next from 'i18next';
import { Guid } from 'guid-typescript';

const addToolLoading = () => ({ type: 'tools/add-tool-loading' });
const addToolSuccess = (payload: any) => ({ type: 'tools/add-tool-success', payload});
const addToolError = (payload: any) => ({ type: 'tools/add-tool-error', payload });

export const showAddTool = (type: ToolType) => ({ type: 'tools/toggle-add-tool', payload: type });
export const hideAddTool = () => ({ type: 'tools/toggle-add-tool' });

export const showSelectResearchType = () => ({ type: 'tools/toggle-select-research-type' });
export const hideSelectResearchType = () => ({ type: 'tools/toggle-select-research-type' });
export const selectResearchType = (type: ResearchType | null) => ({ type: 'tools/select-research-type', payload: type });

export const showSendNielsenEnquiry = () => ({ type: 'tools/toggle-send-nielsen-enquiry' });
export const hideSendNielsenEnquiry = () => ({ type: 'tools/toggle-send-nielsen-enquiry' });

const sendNielsenEnquiryLoading = () => ({ type: 'tools/send-nielsen-enquiry-loading' });
const sendNielsenEnquirySuccess = (payload: any) => ({ type: 'tools/send-nielsen-enquiry-success', payload});
const sendNielsenEnquiryError = (payload: any) => ({ type: 'tools/send-nielsen-enquiry-error', payload });

export const showSendPureSpectrumEnquiry = () => ({ type: 'tools/toggle-send-pure-spectrum-enquiry' });
export const hideSendPureSpectrumEnquiry = () => ({ type: 'tools/toggle-send-pure-spectrum-enquiry' });

const sendPureSpectrumEnquiryLoading = () => ({ type: 'tools/send-pure-spectrum-enquiry-loading' });
const sendPureSpectrumEnquirySuccess = (payload: any) => ({ type: 'tools/send-pure-spectrum-enquiry-success', payload});
const sendPureSpectrumEnquiryError = (payload: any) => ({ type: 'tools/send-pure-spectrum-enquiry-error', payload });

export const toggleToolMenu = (payload: Guid) => ({ type: 'tools/toggle-tool-menu', payload });
export const hideToolMenu = () => ({ type: 'tools/toggle-tool-menu', payload: null });

const editToolLoading = () => ({ type: 'tools/edit-tool-loading' });
const editToolSuccess = (payload: any) => ({ type: 'tools/edit-tool-success', payload});
const editToolError = (payload: any) => ({ type: 'tools/edit-tool-error', payload });

export const toggleEditTool = () => ({ type: 'tools/toggle-edit-tool'});

const cloneToolLoading = () => ({ type: 'tools/clone-tool-loading' });
const cloneToolSuccess = (payload: any) => ({ type: 'tools/clone-tool-success', payload});
const cloneToolError = (payload: any) => ({ type: 'tools/clone-tool-error', payload });

export const toggleCloneTool = () => ({ type: 'tools/toggle-clone-tool'});

export const addTool = async (dispatch: any, data: ITool) => {

  dispatch(addToolLoading());

  let payload: any = { projectId: data.projectId['value'], type: data.type, name: data.name, templateId: data.templateId};
  
  if(data.type === ToolType.RESEARCH) {
    payload['data'] = JSON.stringify(data.data);
  }

  axios.post(
    `${process.env.REACT_APP_TOOLS_PATH}`, 
    payload,
    { transformResponse: (data: string) => transformObjectFromString<ITool>(data) })
    .then((response: any) => {

      dispatch(addToolSuccess(response.data));
      dispatch(hideAddTool());

      switch(data.type) {
        case ToolType.RESEARCH:
          gotoLegacyUrl(`${getResearchLegacyUrl(response.data)}`, response.data.uuid);
          break;
        case ToolType.BRAND_STRATEGY:
          gotoLegacyUrl('/discovery', response.data.uuid);
          break;
        case ToolType.COMPETITIVE_MAPPING:
          gotoLegacyUrl('/competitive-mapping/competitive-mapping-competitors', response.data.uuid);
          break;
        case ToolType.CREATIVE_BRIEF:
          gotoLegacyUrl('/creative-brief', response.data.uuid);
          break;
        case ToolType.CREATIVE_COMPETITIVE_OVERVIEW:
          gotoLegacyUrl('/creative-competitive-overview', response.data.uuid);
          break;
        case ToolType.MEDIA_BRIEF:
          gotoLegacyUrl('/media-brief', response.data.uuid);
          break;
        case ToolType.SCRIPT_TEMPLATE:
          gotoLegacyUrl('/script-template', response.data.uuid);
          break;
        }
    })
    .catch((error: any) => {
      // console.log(error);
      dispatch(addToolError(normaliseErrorCode(error, 'projects.tool.create')));
    });
};

export const editTool = async (dispatch: any, tool: ITool | null, data: any) => {

  dispatch(editToolLoading());

  let payload = data;

  if(!tool) {
    dispatch(editToolError(normaliseErrorCode('Error', 'projects.tool.edit')));
    return;
  }
  
  axios.patch(
    `${process.env.REACT_APP_TOOLS_PATH}/${tool.uuid['value']}`, 
    payload,
    { transformResponse: (data: string) => transformObjectFromString<ITool>(data) })
    .then((response: any) => {

      toast.success(i18next.t('editTool.success'));

      dispatch(editToolSuccess(response.data));
      dispatch(toggleEditTool());
    })
    .catch((error: any) => {
      // console.log(error);
      dispatch(editToolError(normaliseErrorCode(error, 'projects.tool.edit')));
    });
};

export const cloneTool = async (dispatch: any, tool: ITool | null, data: any) => {

  dispatch(cloneToolLoading());

  let payload = data;

  if(!tool) {
    dispatch(cloneToolError(normaliseErrorCode('Error', 'projects.tool.clone')));
    return;
  }
  
  axios.post(
    `${process.env.REACT_APP_TOOLS_PATH}/${tool.uuid['value']}`, 
    payload,
    { transformResponse: (data: string) => transformObjectFromString<ITool>(data) })
    .then((response: any) => {

      toast.success(i18next.t('cloneTool.success'));

      dispatch(cloneToolSuccess(response.data));
      dispatch(toggleCloneTool());
    })
    .catch((error: any) => {
      // console.log(error);
      dispatch(cloneToolError(normaliseErrorCode(error, 'projects.tool.clone')));
    });
};

export const sendNielsenEnquiry = async (dispatch: any, data: any) => {

  dispatch(sendNielsenEnquiryLoading());

  data['provider'] = 'NIELSEN';
  
  axios.post(
    `${process.env.REACT_APP_INSIGHTS_PATH}/enquiries`, 
    data,
    { transformResponse: (data: string) => transformObjectFromString<ITool>(data) })
    .then((response: any) => {

      dispatch(sendNielsenEnquirySuccess(response.data));
      dispatch(hideSendNielsenEnquiry());
      toast.success(i18next.t('sendNielsenEnquiry.success'));
    })
    .catch((error: any) => {
      // console.log(error);
      dispatch(sendNielsenEnquiryError(normaliseErrorCode(error, 'projects.sendNielsenEnquiry.create')));
    });
};

export const sendPureSpectrumEnquiry = async (dispatch: any, data: any) => {

  dispatch(sendPureSpectrumEnquiryLoading());

  data['provider'] = 'PURE_SPECTRUM';

  axios.post(
    `${process.env.REACT_APP_INSIGHTS_PATH}/enquiries`, 
    data,
    { transformResponse: (data: string) => transformObjectFromString<ITool>(data) })
    .then((response: any) => {

      dispatch(sendPureSpectrumEnquirySuccess(response.data));
      dispatch(hideSendPureSpectrumEnquiry());
      toast.success(i18next.t('sendPureSpectrumEnquiry.success'));
    })
    .catch((error: any) => {
      // console.log(error);
      dispatch(sendPureSpectrumEnquiryError(normaliseErrorCode(error, 'projects.sendPureSpectrumEnquiry.create')));
    });
};

const getResearchLegacyUrl = (data: any) : string => {

  if(!data || !data['data'] || !data['data']['researchType']) {
    return '';
  }

  switch(data['data']['researchType']) {
    case ResearchType.ROY_MORGAN: 
      return '/roy-morgan-research';
    case ResearchType.AUSTRALIA_POST: 
      return '/australia-post-research';
    case ResearchType.CUSTOM: 
      return '/custom-research';
    default:
      return '';
  }
}
