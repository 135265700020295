import React from "react"
import ContentLoader from 'react-content-loader'

const DownloadsTitleLoader: React.FC = () => {
  return (
    <ContentLoader width={"100%"} height={100}>
      <rect x="5" y="0" rx="5" ry="5" width="50%" height="50" />
      <rect x="88%" y="0" rx="5" ry="5" width="15%" height="50" />
    </ContentLoader>
  );
};

export default DownloadsTitleLoader;
  