import { Status } from "../shared/status";
import { AgenciesState, AgenciesAction } from "./types";

const initialState: AgenciesState = {
  agencies: [],
  totalAgencies: 0,
  selected: null,
  listAgenciesStatus: Status.IDLE,
  listAgenciesError: null,
  myAgency: null,
  getMyAgencyStatus: Status.IDLE,
  getMyAgencyError: null,
  mySubscription: null,
  getMySubscriptionStatus: Status.IDLE,
  getMySubscriptionError: null
};

const agenciesReducer = (state: AgenciesState = initialState, action: AgenciesAction): AgenciesState => {

  switch (action.type) {

    case 'agencies/get-my-agency-loading':

      return {
        ...state,
        getMyAgencyStatus: Status.LOADING,
        getMyAgencyError: null
      };

    case 'agencies/get-my-agency-error':

      return {
        ...state,
        getMyAgencyStatus: Status.FAILED,
        getMyAgencyError: action.payload
      };

    case 'agencies/get-my-agency-success':

      return {
        ...state,
        myAgency: action.payload,
        getMyAgencyStatus: Status.SUCCESS,
        getMyAgencyError: action.payload
      };

    case 'agencies/get-my-subscription-loading':

      return {
        ...state,
        getMySubscriptionStatus: Status.LOADING,
        getMySubscriptionError: null
      };

    case 'agencies/get-my-subscription-error':

      return {
        ...state,
        getMySubscriptionStatus: Status.FAILED,
        getMySubscriptionError: action.payload
      };

    case 'agencies/get-my-subscription-success':

      return {
        ...state,
        mySubscription: action.payload,
        getMySubscriptionStatus: Status.SUCCESS,
        getMySubscriptionError: action.payload
      };
  }
  
  return state;
};

export default agenciesReducer;
