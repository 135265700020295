import React, { useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import Header from '../shared/Header';
import { State } from '../../store/shared/state';
import Footer from '../shared/Footer';
import { Status } from '../../store/shared/status';
import CallToActionLoader from './CallToActionLoader';
import './DashboardComponent.scss';
import IntroductionLoader from './DashboardIntroductionLoader';
import ClientsListError from './ClientsListError';
import DashboardIntroduction from './DashboardIntroduction';
import DashboardActions from './DashboardActions';
import DashboardLastProject from './DashboardLastProject';
import DashboardResources from './DashboardResources';
import DashboardClients from './DashboardClients';
import DashboardToolkit from './DashboardToolkit';
import DashboardExamplesIntroduction from './DashboardExamplesIntroduction';
import DashboardExamplesIntroductionLoader from './DashboardExamplesIntroductionLoader';
import DashboardExamples from './DashboardExamples';
import DashboardExamplesLoader from './DashboardExamplesLoader';
import { getAgencyIdFromToken } from '../../shared/auth';
import { listUsers } from '../../store/users/actions';
import SubscriptionBanner from '../shared/SubscriptionBanner';

export const DashboardComponent: React.FC = () => {

  const dispatch = useDispatch();
  
  const listClientsStatus = useSelector((state: State) => state.clients.listClientsStatus);
  const clients = useSelector((state: State) => state.clients.clients);
  const projects = useSelector((state: State) => state.projects.projects);
  const selectedClient = useSelector((state: State) => state.clients.selected);

  const getNumberOfActualClients = () => {
    let nonExampleClients = clients?.filter(x => x.example === false);
    return nonExampleClients != null ? nonExampleClients.length : 0;
  }

  useEffect(() => {
    const agencyId = getAgencyIdFromToken();
    if(agencyId) {
      listUsers(dispatch, agencyId);
    }
    // eslint-disable-next-line 
  }, [])


  const isLoading = () => listClientsStatus === Status.LOADING;
  const isFailed = () => listClientsStatus === Status.FAILED;
  const isExampleClient = () => selectedClient != null ? selectedClient!.example : false;

  const clientsErrorBlock = isFailed() ? ( <ClientsListError /> ) : null;
  const actionsBlock = !isFailed() && !isLoading() ? ( <DashboardActions totalClients={getNumberOfActualClients()} totalProjects={projects?.length} /> ) : null;
  const introductionBlock = isLoading() ? ( <IntroductionLoader /> ) : ( <DashboardIntroduction firstTime={getNumberOfActualClients() === 0} /> )
  const lastProjectBlock = !isFailed() && !isLoading() && clients?.length > 0 ? ( <DashboardLastProject example = {isExampleClient()} projects={projects} />) : null;
  const resourcesBlock = isLoading() ? ( <CallToActionLoader /> ) : isExampleClient() ? ( <DashboardResources /> ) : null;
  const clientsBlock = isLoading() ? ( <CallToActionLoader /> ) : ( <DashboardClients example = {isExampleClient()} /> )
  const toolkitBlock = isLoading() ? ( <CallToActionLoader /> ) : ( <DashboardToolkit example = {isExampleClient()} /> )
  const examplesIntroductionBlock = isLoading() ? ( <DashboardExamplesIntroductionLoader /> ) : ( <DashboardExamplesIntroduction />  );
  const examplesBlock = isLoading() ? ( <DashboardExamplesLoader /> ) : ( <DashboardExamples />  );

  return (
    <section className="container-fluid">
      <Header />
      <SubscriptionBanner />
      <section className="container container-xxxl container-body">
        <div className="row">
          <div className="col-12 col-lg-5 col-xl-3 left-column">
            {clientsErrorBlock}
            {actionsBlock}
          </div>
          <div className="col-12 col-lg-7 col-xl-9 right-column">
            {introductionBlock}
          </div>
        </div>

        <div className="row">
          <div className="col-12 col-lg-5 col-xl-3 left-column">
            {lastProjectBlock}
            {resourcesBlock} 
          </div>
          <div className="col-12 col-lg-7 col-xl-9 right-column">
            <div className="row">
              <div className="col-12 col-xl-6">
                {clientsBlock}
              </div>
              <div className="col-12 col-xl-6">
                {toolkitBlock}
              </div>
            </div>
          </div>
        </div>
        <div className="row examples">
          <div className="d-none d-xl-block col-xl-3 left-column">
            &nbsp;
          </div>
          <div className="col-12 col-xl-9 right-column">
              {examplesIntroductionBlock}
              {examplesBlock}
          </div>
        </div>
      </section>
      <Footer />
    </section>
  );
}

export default DashboardComponent;