import './index.css';
import App from './App';

import React from 'react';
import ReactDOM from 'react-dom';
import { history } from './store/configure/configure';
import { createStore, applyMiddleware, Store } from 'redux';
import { Provider } from "react-redux";
import thunk from "redux-thunk";
import { routerMiddleware } from 'connected-react-router';
import reportWebVitals from './reportWebVitals';
import { connectRouter } from 'connected-react-router';
import { combineReducers } from '@reduxjs/toolkit';
import clientsReducer from './store/clients/reducers';
import { composeWithDevTools } from 'redux-devtools-extension';
import projectsReducer from './store/projects/reducers';
import usersReducer from './store/users/reducers';
import templatesReducer from './store/templates/reducers';
import toolsReducer from './store/tools/reducers';
import agenciesReducer from './store/agencies/reducers';
import generalReducer from './store/general/reducers';
import reportsReducer from './store/reports/reducers';
import imagesReducer from './store/images/reducers';
import reportingReducer from './store/reporting/reducers';

const store : Store = createStore(
  combineReducers({ 
    router: connectRouter(history),
    clients: clientsReducer,
    projects: projectsReducer,
    reports: reportsReducer,
    users: usersReducer,
    templates: templatesReducer,
    tools: toolsReducer,
    agencies: agenciesReducer,
    general: generalReducer,
    images: imagesReducer,
    reporting: reportingReducer
  }),
  composeWithDevTools(
    applyMiddleware(thunk, routerMiddleware(history))
  )
);

const rootElement = document.getElementById("root");

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <App />
    </Provider>
  </React.StrictMode>,
  rootElement
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
