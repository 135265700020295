import * as React from "react";
import { Trans, useTranslation } from "react-i18next";

export const DashboardExamplesIntroduction: React.FC = () => {

  const { t } = useTranslation();

  return (
    <React.Fragment>
      <h1>{t('dashboard.exampleProjectsHeading')}</h1>
      <p>
        <Trans i18nKey="dashboard.exampleProjectsIntroduction">
          Fully completed brand projects ready for you to explore, edit and learn from. 
          <br />Wink Wink. Clone and use as one of your upcoming projects to make your job even easier. Nice.
          <br />Delete all projects if you prefer not to have this section.
        </Trans>
      </p>
    </React.Fragment>
  );
};

export default DashboardExamplesIntroduction;
