import * as React from "react";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { formatDate } from "../../shared/dates";
import { State } from "../../store/shared/state";
import { hideTemplateMenu, toggleTemplateMenu } from "../../store/templates/actions";
import { ITemplate } from '../../store/templates/types';
import { ToolType } from "../../store/tools/types";
import EditTemplate from "./EditTemplate";
import TemplateMenu from "./TemplateMenu";

type Props = {
  typeKey: string
  templates: ITemplate[]
};

export const TemplatesList: React.FC<Props> = ({ typeKey, templates}) => {

  const { t } = useTranslation();
  const dispatch = useDispatch();

  const translationKey = 'templates.' + typeKey;

  const selectedProject = useSelector((state: State) => state.projects.selected);

  useEffect(() => {
    return () => {
      dispatch(hideTemplateMenu());
    }
    // eslint-disable-next-line 
  }, [])

  const goToTemplate = (template: ITemplate) => {
    var url = `${process.env.REACT_APP_LEGACY_BASE_URL}/templates`;

    switch(template.type) {

      case ToolType.BRAND_STRATEGY:
        url += '/brand-strategy/';
        break;

      case ToolType.MEDIA_BRIEF:
        url += '/media-brief/';
        break;

      case ToolType.CREATIVE_BRIEF:
        url += '/creative-brief/';
        break;

      default:
        break;
    }
    
    url += selectedProject!.uuid['value'] + '/' + template.uuid['value'];

    window.location.href = url;
  }

  const openTemplateMenu = (template: ITemplate) => {
    dispatch(toggleTemplateMenu(template.uuid));
  }

  return (
    <React.Fragment>
      <h1>{t(translationKey)}</h1>
      <div className="table-container compact">
        <EditTemplate />
        <table>
          <tbody>
      
            {templates.map((template: ITemplate) => (
              <tr key={template.uuid['value'].toString()}>
                <td>
                  {template.type === ToolType.BRAND_STRATEGY && <a href={`${process.env.REACT_APP_LEGACY_BASE_URL}/templates/brand-strategy/${selectedProject?.uuid['value']}/${template.uuid['value']}`}>{template.name}</a>}
                  {template.type === ToolType.MEDIA_BRIEF && <a href={`${process.env.REACT_APP_LEGACY_BASE_URL}/templates/media-brief/${selectedProject?.uuid['value']}/${template.uuid['value']}`}>{template.name}</a>}
                  {template.type === ToolType.CREATIVE_BRIEF && <a href={`${process.env.REACT_APP_LEGACY_BASE_URL}/templates/creative-brief/${selectedProject?.uuid['value']}/${template.uuid['value']}`}>{template.name}</a>}
                  {t('general.lastEdited', { dateModified: formatDate(template.dateModified), lastModifiedBy: template.lastModifiedBy })}
                </td>
                <td className="actions">
                  <button className="d-none d-sm-inline-block open red"  onClick={() => goToTemplate(template)}>{t('general.edit')}</button>
                  <button className="toggle" onClick={() => { openTemplateMenu(template) } } id={`template-menu-toggle-${template.uuid.toString()}`}><i className="fas fa-chevron-down" /></button>
                  <TemplateMenu template={template} />
                </td>
              </tr>
            ))}

          </tbody>
        </table>
      </div>
    </React.Fragment>
  );
};

export default TemplatesList;
