import { Guid } from 'guid-typescript';
import { Status } from '../shared/status';

export enum ToolType {
    BRAND_STRATEGY = 'BRAND_STRATEGY',
    MEDIA_BRIEF = 'MEDIA_BRIEF',
    COMPETITIVE_MAPPING = 'COMPETITIVE_MAPPING',
    CREATIVE_BRIEF = 'CREATIVE_BRIEF',
    CREATIVE_COMPETITIVE_OVERVIEW = 'CREATIVE_COMPETITIVE_OVERVIEW',
    SCRIPT_TEMPLATE = 'SCRIPT_TEMPLATE',
    RESEARCH = 'RESEARCH'
};

export enum ResearchType {
    ROY_MORGAN = 'ROY_MORGAN',
    AUSTRALIA_POST = 'AUSTRALIA_POST',
    CUSTOM = 'CUSTOM'
};

export interface ITool {
    uuid: Guid
    projectId: Guid
    dateCreated: Date
    dateModified: Date
    dateCommenced: Date
    lastModifiedBy: string
    lastModifiedById: Guid
    template: string
    templateId: Guid
    type: ToolType
    name: string
    data: any
}

export type ToolsState = {
    addToolOpened: boolean
    addToolStatus: Status
    addToolType: ToolType | null
    addToolError: any
    selectedResearchType: ResearchType | null
    selectResearchTypeOpened: boolean
    sendNielsenEnquiryOpened: boolean
    sendNielsenEnquiryStatus: Status
    sendNielsenEnquiryError: any
    sendPureSpectrumEnquiryOpened: boolean
    sendPureSpectrumEnquiryStatus: Status
    sendPureSpectrumEnquiryError: any
    toolMenuOpened: Guid | null
    editToolOpened: boolean
    editToolStatus: Status
    editToolError: any
    cloneToolOpened: boolean
    cloneToolStatus: Status
    cloneToolError: any
};

export type ToolsAction = { 
    type: string; 
    payload: any;
};