
import React from "react";
import { Modal } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import { State } from "../../store/shared/state";
import { Status } from "../../store/shared/status";
import { useForm, } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { toggleEditTemplate, editTemplate } from "../../store/templates/actions";

type FormData = {
  name: string;
};

export const EditTemplate: React.FC = () => {

  const { t } = useTranslation();
  const { register, handleSubmit, formState: { errors }, reset } = useForm<FormData>();

  const selectedTemplate = useSelector((state: State) => state.templates.selected);

  const editTemplateOpened = useSelector((state: State) => state.templates.editTemplateOpened);
  const editTemplateStatus = useSelector((state: State) => state.templates.editTemplateStatus);
  const editTemplateError = useSelector((state: State) => state.templates.editTemplateError);

  const defaultValues = { name: selectedTemplate?.name};
   
  const submitButtonText = editTemplateStatus !== Status.LOADING 
    ? ( <span>{t('editTemplate.submit')} <i className="fas fa-chevron-right"></i></span> )
    : ( <span>{t('editTemplate.processing')} <i className="fas fa-spin fa-circle-notch"></i></span> );

  const dispatch = useDispatch();

  const typeName = t('tools.types.' + selectedTemplate?.type.toString());

  const onSubmit = (data: any, errors: any) => {
    editTemplate(dispatch, selectedTemplate, { name: data.name });
  }
  
  const onErrors = (errors: any) => console.error(errors);

  return (

    <Modal className="modal form-modal red-modal"
      show={editTemplateOpened}
      animation={false}
      onShow={() => reset(defaultValues)}
      onHide={() => dispatch(toggleEditTemplate())}
      centered
    >

      <Modal.Header>
        <Modal.Title>
          {t('editTemplate.title', { type: typeName })}
          </Modal.Title>
        <button type="button" data-bs-dismiss="modal" aria-label="Close" onClick={() => { dispatch(toggleEditTemplate()) }}><i className="fas fa-times"></i></button>
      </Modal.Header>

      <Modal.Body>
        <form onSubmit={handleSubmit(onSubmit, onErrors)}>
          <fieldset>
            <label>
              <div className="row">
                <div className="col-12 col-lg-6">{t('editTemplate.name')}</div>
                <div className="col-12 col-lg-6 error">{errors.name?.type === 'required' && t('form.required', { field: t('form.name') })}</div>
              </div>
            </label>
            <input type="text" {...register("name", { required: true, maxLength: 255 })} /> 

            <div className="server-error">{t(editTemplateError)}</div>

          </fieldset>
          <div className="actions">
            <button type="submit" disabled={editTemplateStatus === Status.LOADING}>{submitButtonText}</button>
          </div>

        </form>

      </Modal.Body>
    </Modal>

  );
};
export default EditTemplate;