import React from "react";
import { Modal } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import { State } from "../../store/shared/state";
import { Status } from "../../store/shared/status";
import { useForm, Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { addTemplate, toggleAddTemplate } from "../../store/templates/actions";
import Select from 'react-select'
import { Guid } from "guid-typescript";
import { ToolType } from "../../store/tools/types";

type FormData = {
  name: string;
  type: ToolType | undefined;
};

export const AddTemplate: React.FC = () => {

  const { t } = useTranslation();
  const { register, handleSubmit, control, formState: { errors }, reset, setValue } = useForm<FormData>();

  const selectedClient = useSelector((state: State) => state.clients.selected);

  const addTemplateOpened = useSelector((state: State) => state.templates.addTemplateOpened);
  const addTemplateStatus = useSelector((state: State) => state.templates.addTemplateStatus);
  const addTemplateError = useSelector((state: State) => state.templates.addTemplateError);

  const types = [
    {'value': ToolType.BRAND_STRATEGY, 'label': t('addTemplate.types.' + ToolType.BRAND_STRATEGY.toString())},
    {'value': ToolType.MEDIA_BRIEF, 'label': t('addTemplate.types.' + ToolType.MEDIA_BRIEF.toString())},
    {'value': ToolType.CREATIVE_BRIEF, 'label': t('addTemplate.types.' + ToolType.CREATIVE_BRIEF.toString())},
  ]

  const defaultValues = { name: '', jobNumber: '' , leader: undefined};
   
  const submitButtonText = addTemplateStatus !== Status.LOADING 
    ? ( <span>{t('addTemplate.submit')} <i className="fas fa-chevron-right"></i></span> )
    : ( <span>{t('addTemplate.processing')} <i className="fas fa-spin fa-circle-notch"></i></span> );

  const dispatch = useDispatch();

  const onSubmit = (data: any, errors: any) => {

    addTemplate(dispatch, {
       uuid: Guid.create(), 
       agencyId: selectedClient!.agencyId, 
       type: data.type,
       dateCreated: new Date(),
       dateModified: new Date(),
       lastModifiedBy: "User",
       lastModifiedById: Guid.create(),
       name: data.name, 
       steps: null, 
       example: false
      });
  }
  
  const onErrors = (errors: any) => console.error(errors);

  return (

    <Modal className="modal form-modal red-modal"
      show={addTemplateOpened}
      animation={false}
      onShow={() => reset(defaultValues)}
      onHide={() => dispatch(toggleAddTemplate())}
      centered
    >

      <Modal.Header>
        <Modal.Title>
          {t('addTemplate.title')}
          </Modal.Title>
        <button type="button" data-bs-dismiss="modal" aria-label="Close" onClick={() => { dispatch(toggleAddTemplate()) }}><i className="fas fa-times"></i></button>
      </Modal.Header>

      <Modal.Body>
        <form onSubmit={handleSubmit(onSubmit, onErrors)}>
          <fieldset>
            <label>
              <div className="row">
                <div className="col-12 col-lg-6">{t('addTemplate.name')}</div>
                <div className="col-12 col-lg-6 error">{errors.name?.type === 'required' && t('form.required', { field: t('form.name') })}</div>
              </div>
            </label>
            <input type="text" {...register("name", { required: true, maxLength: 255 })} /> 

            <div className="row">
              <div className="col-12 col-xl-6">
                <label>
                  <div className="row">
                    <div className="col-12 col-lg-6">{t('addTemplate.type')}</div>
                    <div className="col-12 col-lg-6 error">{errors.type?.type === 'required' && t('form.required', { field: t('addTemplate.type') })}</div>
                  </div>
                </label>
                
                <Controller
                    control={control}
                    name="type"
                    rules={{ required: true }}
                    render={({ field }) => (
                        <Select
                          key={`type-${field.value}`}
                            classNamePrefix="select"
                            options={types}
                            value={types.find(x => x.value === field.value)}
                            onChange={value => {
                              setValue("type", value ? value.value : undefined, {
                                shouldDirty: true
                              });
                            }}
                        />
                    )}
                />

              </div>
            
            </div> 
            <p className="mb-5">{t('addTemplate.note')}</p>

            <div className="server-error">{t(addTemplateError)}</div>

          </fieldset>

          <div className="actions">
            <button type="submit" disabled={addTemplateStatus === Status.LOADING}>{submitButtonText}</button>
          </div>

        </form>

      </Modal.Body>
    </Modal>

  );
};
export default AddTemplate;