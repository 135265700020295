import React, { useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import Header from '../shared/Header';
import { State } from '../../store/shared/state';
import { Status } from '../../store/shared/status';
import Footer from '../shared/Footer';
import { useTranslation } from 'react-i18next';
import TemplatesTitleLoader from './TemplatesTitleLoader';
import { getAgencyIdFromToken } from '../../shared/auth';
import { useHistory } from 'react-router';
import './TemplatesComponent.scss';
import TemplatesResources from './TemplatesResources';
import ResourcesLoader from './ResourcesLoader';
import { listTemplates } from '../../store/templates/actions';
import TemplatesLoader from './TemplatesLoader';
import { ToolType } from '../../store/tools/types';
import TemplatesList from './TemplatesList';
import TemplatesTitle from './TemplatesTitle';
import SubscriptionBanner from '../shared/SubscriptionBanner';
import { getQueryString } from '../../shared/queryString';

export const TemplatesComponent: React.FC = () => {

  const { t } = useTranslation();

  const dispatch = useDispatch();
  const history = useHistory();

  const templatesStatus = useSelector((state: State) => state.templates.listTemplatesStatus);
  const templates = useSelector((state: State) => state.templates.templates);

  useEffect(() => {
    const agencyId = getAgencyIdFromToken();
    if(agencyId) {
      listTemplates(dispatch, agencyId);
    }
    // eslint-disable-next-line 
  }, [])

  const goBack = () => {
    history.push({ pathname: '/projects', search: getQueryString() });
  }

  const resourcesBlock = templatesStatus === Status.LOADING ?  ( <ResourcesLoader /> ) : ( <TemplatesResources /> );
  const titleBlock = templatesStatus === Status.LOADING ? ( <TemplatesTitleLoader /> ) : ( <TemplatesTitle /> )

  const brandStategyTemplatesBlock = templatesStatus === Status.LOADING 
      ? ( <TemplatesLoader /> ) 
      : templatesStatus === Status.SUCCESS 
      ? <TemplatesList typeKey='brandStrategy' templates={templates.filter(x => x.type === ToolType.BRAND_STRATEGY)} />
      : null;
      
  const creativeBriefTemplatesBlock = templatesStatus === Status.LOADING 
      ? ( <TemplatesLoader /> ) 
      : templatesStatus === Status.SUCCESS 
      ? <TemplatesList typeKey='creativeBrief' templates={templates.filter(x => x.type === ToolType.CREATIVE_BRIEF)} />
      : null;

  const mediaBriefTemplatesBlock = templatesStatus === Status.LOADING 
      ? ( <TemplatesLoader /> ) 
      : templatesStatus === Status.SUCCESS 
      ? <TemplatesList typeKey='mediaBrief' templates={templates.filter(x => x.type === ToolType.MEDIA_BRIEF)} />
      : null;
    
    
      
  return (
    <React.Fragment>
      <section className="container-fluid fake-header red">
        <Header />
        <SubscriptionBanner />
        <section className="container container-xxxl container-body">
          <div className="row">
          <div className="col-12 col-lg-3 left-column">
              <h4 className="back" onClick={goBack}>
                <i className="fas fa-chevron-left"></i>
                {t('general.back')}
              </h4>
            </div>
            <div className="col-12 col-lg-9 right-column">
              {titleBlock}
            </div>
          </div>
        </section>
      </section>
      <section className="container-fluid">
        <section className="container container-xxxl container-body">
          <div className="row">
            <div className="col-12 col-lg-3 left-column">
              {resourcesBlock}
            </div>
            <div className="col-12 col-lg-9 right-column">
              {brandStategyTemplatesBlock}
              {creativeBriefTemplatesBlock}
              {mediaBriefTemplatesBlock}
            </div>
          </div>

        </section>
        <Footer />
      </section>
    </React.Fragment>
  );
}

export default TemplatesComponent;