import React from "react"
import ContentLoader from 'react-content-loader'

const SelectClientLoader: React.FC = () => {
  return (
    <ContentLoader width={"100%"} height={250}>
      <rect x="5" y="40" rx="5" ry="5" width="80%" height="25" />
      <rect x="5" y="100" rx="5" ry="5" width="70%" height="25" />
      <rect x="5" y="160" rx="5" ry="5" width="75%" height="25" />
      <rect x="5" y="220" rx="5" ry="5" width="80%" height="25" />
      <rect x="5" y="280" rx="5" ry="5" width="70%" height="25" />

    </ContentLoader>
  );
};

export default SelectClientLoader;
  