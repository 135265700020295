export const formatDate = (date: Date): string => {

    let formatted: string = '';

    let parts = new Intl.DateTimeFormat("en-GB", {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit'
    }).formatToParts(date);

    parts.forEach(x => {
        if(x['type'] !== 'literal') {
            formatted += x['value'];
        } else {
            formatted += '.';
        }
    });

    return formatted;
}
