import React from "react";
import { useRef } from "react";
import { Modal } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import { State } from "../../store/shared/state";
import { Status } from "../../store/shared/status";
import { useForm, Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { jsPDF } from 'jspdf';
import Select from 'react-select'
import { Guid } from "guid-typescript";
import { generateReport, generateReportLoading, saveReport, toggleGenerateReport } from "../../store/reports/actions";
import BrandStrategyDesigned from "./BrandStrategyDesigned";
import { normaliseErrorCode } from "../../store/shared/transform";
import { FontsService } from '../../shared/fonts';

type FormData = {
  type: string | undefined;
};

export const GenerateReport: React.FC = () => {

  const { t } = useTranslation();
  const { handleSubmit, control, formState: { errors }, reset, setValue } = useForm<FormData>();

  const selectedProject = useSelector((state: State) => state.projects.selected);
  const selectedTool = useSelector((state: State) => state.projects.selectedTool);
  
  const generateReportOpened = useSelector((state: State) => state.reports.generateReportOpened);
  const generateReportStatus = useSelector((state: State) => state.reports.generateReportStatus);
  const generateReportError = useSelector((state: State) => state.reports.generateReportError);

  const brandStrategyDesigned = useRef<HTMLDivElement>(null);

  const types = [{value: 'DOC', label: 'DOC Format'}, {value: 'PDF', label: 'PDF Format'}];

  const defaultValues = { name: '', jobNumber: '' , type: undefined};
   
  const submitButtonText = generateReportStatus !== Status.LOADING 
    ? ( <span>{t('generateReport.submit')} <i className="fas fa-chevron-right"></i></span> )
    : ( <span>{t('generateReport.processing')} <i className="fas fa-spin fa-circle-notch"></i></span> );

  const dispatch = useDispatch();

  const onSubmit = (data: any, errors: any) => {

    if(data.type === 'PDF') {
      doGeneratePdf();
      return;
    }

    generateReport(dispatch, selectedProject!.uuid, selectedTool!.uuid, {
      uuid: Guid.create(), 
      dateCreated: new Date(),
      file: '',
      notesFile: '',
      fileType: data.type
    });
  }


  const doGeneratePdf = () => {

    dispatch(generateReportLoading());

    //TODO types
    let document = brandStrategyDesigned;

    if(!document) {
      dispatch(generateReportError(normaliseErrorCode('Error', 'projects.tool.downloads')));
      return;
    }

    let data= document!.current;
    
    if(!data) {
      dispatch(generateReportError(normaliseErrorCode('Error', 'projects.tool.downloads')));
      return;
    }

    const doc: jsPDF = new jsPDF("l", "px", [842, 595]);

    FontsService.loadCustomFonts(doc);

    doc.html(data!.innerHTML, {
        callback: (doc) => {
          var pageCount = doc.getNumberOfPages();
          doc.deletePage(pageCount);

          // Un-comment to download in the browser 
          // doc.save('brandvas-report.pdf');

          let blob = doc.output('blob');
          saveReport(dispatch, selectedTool!.uuid, 'PDF', blob);
        }
    });
  }

  const onErrors = (errors: any) => console.error(errors);

  return (

    <React.Fragment>
      <Modal className="modal form-modal yellow-modal"
        show={generateReportOpened}
        animation={false}
        onShow={() => reset(defaultValues)}
        onHide={() => dispatch(toggleGenerateReport())}
        centered
      >

        <Modal.Header>
          <Modal.Title>
            {t('generateReport.title')}
            <span>{t('generateReport.tool')}: {selectedTool?.name}</span>
            </Modal.Title>
          <button type="button" data-bs-dismiss="modal" aria-label="Close" onClick={() => { dispatch(toggleGenerateReport()) }}><i className="fas fa-times"></i></button>
        </Modal.Header>

        <Modal.Body>
          <form onSubmit={handleSubmit(onSubmit, onErrors)}>
            <fieldset>
              <label>
                <div className="row">
                  <div className="col-12 col-lg-6">{t('generateReport.type')}</div>
                  <div className="col-12 col-lg-6 error">{errors.type?.type === 'required' && t('form.required', { field: t('generateReport.type') })}</div>
                </div>
              </label>
              
              <Controller
                  control={control}
                  name="type"
                  rules={{ required: true }}
                  render={({ field }) => (
                      <Select
                        key={`type-${field.value}`}
                          classNamePrefix="select"
                          options={types}
                          value={types.find(x => x.value === field.value)}
                          onChange={value => {
                            setValue("type", value ? value.value : '', {
                              shouldDirty: true
                            });
                          }}
                      />
                  )}
              />
              
              <div className="server-error">{t(generateReportError)}</div>

            </fieldset>
            <div className="actions">
              <button type="submit" disabled={generateReportStatus === Status.LOADING}>{submitButtonText}</button>
            </div>
          </form>

        </Modal.Body>
      </Modal>
      <BrandStrategyDesigned forwardedRef={brandStrategyDesigned} />
    </React.Fragment>
  );
};
export default GenerateReport;