import axios from "axios";
import { getRefreshToken, getToken, hardCodeTokens, logout, setRefreshToken, setToken } from "./auth";

axios.defaults.baseURL = process.env.REACT_APP_BASE_API;

if(process.env.NODE_ENV === 'development') {
  hardCodeTokens();
}

axios.interceptors.request.use((config) => {

  // console.warn("Request made without auth header: " + config['url']);

  //   if(this.authService.isLoginRequest(request.url) || this.authService.isForgotPasswordRequest(request.url) || this.authService.isExternalRequest(request.url)) {
  //     // console.info("Bypassing login or external request");
  //     return next.handle(request);
  // }

  const token = getToken();

  if(!token) {
    // console.warn("Token not found, redirecting to login")
    logout();
  }

  // Add the token to the header and continue on with the api call
  config.headers['Authorization'] = `Bearer ${token}`;
  
  if(config.headers['Content-Type'] === undefined) {
    config.headers['Content-Type'] = 'application/json';
  }

  return config;

}, (error) => {

  console.warn(error);

  return Promise.reject(error);

});


axios.interceptors.response.use(response => response, async error => {
  
  if(error.response.status === 401) {

    if(isRefreshError(error)) {
      console.warn("401 response received when refreshing token, redirecting to login");
      logout();
      return;
    } else {

      await refreshAccessToken();            

      return axios(error.config);
    }
  }

  return Promise.reject(error);

});


const refreshAccessToken = () : Promise<any> => {
  
  const refreshToken = getRefreshToken();

  // console.warn('Refreshing auth token');

  let config = {
    headers: {
      'X-Refresh-Request': true
    }
  };

  return axios.put<any>(`${process.env.REACT_APP_TOKEN_PATH}/${refreshToken}`, null, config)
    .then((response: any) => {
      if(response && response['data'] && response['data']['token']) {
        setToken(response['data']['token']);
        setRefreshToken(response['data']['refreshToken']);
        return true;
      } else {
        return false;
      }
    });
}

const isRefreshError = (error: any) : boolean => {
  return error && error.config && error.config.headers && error.config.headers['X-Refresh-Request'] && error.config.headers['X-Refresh-Request'] === true;
}

export default axios;