import React, { useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import Header from '../shared/Header';
import { State } from '../../store/shared/state';
import { Status } from '../../store/shared/status';
import Footer from '../shared/Footer';
import { useTranslation } from 'react-i18next';
import AddProject from './AddProject';
import { toggleAddProject, editProject } from '../../store/projects/actions';
import { listUsers } from '../../store/users/actions';
import { getAgencyIdFromToken } from '../../shared/auth';
import SubscriptionBanner from '../shared/SubscriptionBanner';
import { hideToolMenu } from '../../store/tools/actions';
import { useHistory } from 'react-router-dom';
import { getQueryString } from '../../shared/queryString';
import CompetitorsTitleLoader from './CompetitorsTitleLoader';
import CompetitorsTitle from './CompetitorsTitle';
import './CompetitorsComponent.scss';
import { useFieldArray, useForm } from 'react-hook-form';
import { Guid } from 'guid-typescript';

export const CompetitorsForm: React.FC = () => {

  const { t } = useTranslation();

  const dispatch = useDispatch();
  const history = useHistory();

  const clientsStatus = useSelector((state: State) => state.clients.listClientsStatus);

  const projectsStatus = useSelector((state: State) => state.projects.listProjectsStatus);
  const projects = useSelector((state: State) => state.projects.projects);
  const selectedProject = useSelector((state: State) => state.projects.selected);

  const archivedProjectsStatus = useSelector((state: State) => state.projects.listArchivedProjectsStatus);
  const archivedProjects = useSelector((state: State) => state.projects.archivedProjects);

  const allProjectsOpen = useSelector((state: State) => state.projects.allProjectsOpen);
  const archivedProjectsOpen = useSelector((state: State) => state.projects.archivedProjectsOpen);

  const usersStatus = useSelector((state: State) => state.users.listUsersStatus);

  const defaultValues = { competitors: selectedProject?.competitors};

  const { control, formState: { errors, dirtyFields }, handleSubmit, register } = useForm();
  const { fields, append, prepend, remove, swap, move, insert } = useFieldArray({control, name: "competitors"});

  var touched = false;
  // const selectedProjectWatch = watch('selectedProject');

  const onSubmit = (data: any, errors: any) => {
    
    let competitors = [];
    //  = data['competitors'];

    for(var i = 0; i < data['competitors'].length; i++) {
      let competitor = data['competitors'][i];
      if(competitor['name'] == '') continue;

      if(!(competitor['uuid'] instanceof Guid)) {
        competitor['uuid'] = Guid.parse(competitor['uuid']);
      }

      competitors.push(competitor);
    }

    console.log(competitors);
    editProject(dispatch, selectedProject, {competitors: competitors});
  }
  
  const onErrors = (errors: any) => console.error(errors);
console.log(dirtyFields);

  useEffect(() => {
    console.log('gringo')

    if(projectsStatus !== Status.SUCCESS || !selectedProject || fields.length > 0) {
      return;
    }

    if(!selectedProject.competitors) {
      append({
        uuid: Guid.create().toString(),
        name: '',
        loaded: true,
        logo: ''
      });
      return;
    }

    for(var i = 0; i < selectedProject?.competitors?.length; i++) {
      append({
        uuid: selectedProject?.competitors[i]['uuid']['value'],
        name: selectedProject?.competitors[i]['name'],
        loaded: selectedProject?.competitors[i]['loaded'] ? true : false,
        logo: selectedProject?.competitors[i]['logo']
      });
    }
  }, [selectedProject])

  const addCompetitor = () => {
    prepend({
      uuid: Guid.create().toString(),
      name: '',
      loaded: true,
      logo: ''
    });
    // touched = true;
  
  }

  console.log(touched);
  const goBack = () => {
    history.goBack();
  }
  
  const goToEditProjectSettings = () => {
    if(clientsStatus === Status.SUCCESS) {
      dispatch(hideToolMenu());
      // dispatch(toggleAddProject());
    }
  }

  const goToCompetitors = () => {
    history.push({ pathname: '/competitors', search: getQueryString()});
  }

  // const titleBlock = projectsStatus === Status.SUCCESS ? ( <CompetitorsTitle /> ) : ( <CompetitorsTitleLoader /> )
  const competitorsBlock = projectsStatus === Status.SUCCESS ? (
    <div className="row">
      {fields.map((field, i) => (
        <div className="col-12 col-lg-6" key={i}>
          <div className={selectedProject && !selectedProject?.competitors && fields.length == 1 ? "competitor first" : "competitor"}>
            <input {...register(`competitors.${i}.uuid`)} type="hidden"  />
            <input {...register(`competitors.${i}.loaded`)} type="hidden"  />

            Name: <input {...register(`competitors.${i}.name`)} type="text"  />
            <br />
            Logo: <input {...register(`competitors.${i}.logo`)} type="text"  />

            {(selectedProject && (selectedProject?.competitors || fields.length > 1)) && 
              <i className="delete fas fa-times" aria-label="Delete competitor"></i>
            }
          </div>
        </div>
      ))}
    </div>
  ) : null;

  return (
    <form onSubmit={handleSubmit(onSubmit, onErrors)}>
**{touched}**
      {/* <div className="alert alert-warning">
        <span>
          You have unsaved changes on this screen, make sure to save these changes before navigating away, or your changes will be lost. 
        </span>
        <button className="button">Submit<i className="fas d-none d-sm-inline fa-chevron-right"></i></button>
      </div> */}
      
      {selectedProject && !selectedProject?.competitors &&
        <p className="introduction">
          All brands live in the context of their competitors. The way a customer or client understands a brand’s positioning is relative to how it’s biggest competitors are positioned.
          Start by adding your brand's main competitors.
        </p>
      }

      {competitorsBlock}
    
      <button onClick={(e) => { e.stopPropagation(); addCompetitor();}}>Add competitor</button>

              Competitors are here, yo
              <br />
              LOADING STATE

              <br />
              EMPTY STATE
              <br />
    


      <button className="button standalone-save">Save Competitors<i className="fas d-none d-sm-inline fa-chevron-right"></i></button>

    </form>
  );
}

export default CompetitorsForm ;