import React, { useEffect } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { State } from '../../store/shared/state';
import './Header.scss';
import { getMySubscription } from '../../store/agencies/actions';
import { Status } from '../../store/shared/status';

export const SubscriptionBanner: React.FC = () => {

  const { t } = useTranslation();
  const dispatch = useDispatch();
  
  const subscriptionStatus = useSelector((state: State) => state.agencies.getMySubscriptionStatus);
  const subscription = useSelector((state: State) => state.agencies.mySubscription);
  
  useEffect(() => {
    getMySubscription(dispatch); 
    // eslint-disable-next-line 
  }, []);


  const goToPlan = () => { 
    let url = `${process.env.REACT_APP_LEGACY_BASE_URL}/agency/billing`;
    window.location.href = url;
  }

  const daysLeftOfFreeTrial = () : string | null => {

    if(!subscription || !subscription.freeTrial) {
      return null;
    }

    let date = new Date();
    let end = new Date(subscription.freeTrialEnd);

    const diffTime = Math.abs(date.getTime() - end.getTime());
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24)); 

    return diffDays > 1 ? `${diffDays} ${t('header.subscription.days')}` : `${diffDays} ${t('header.subscription.day')}`;
  }

  if(subscriptionStatus !== Status.SUCCESS || !subscription || subscription.active) {
    return null;
  }

  return subscription.freeTrial ? (
    <header className="call-out">
      <section className="container container-xxxl container-header">
        <div className="row g-0 call-out justify-content-between">
          <div className="call-out-text col-12 col-md-9 col-lg-auto">
            <p className="notice">
              {t('header.subscription.just')} <span>{daysLeftOfFreeTrial()}</span> {t('header.subscription.toGo')}
            </p>
            <p>
              <Trans i18nKey="header.subscription.signUp">
                Sign up for an annual plan before your trial ends with the promo code <strong>"tenoff"</strong> to get 10% off!
              </Trans>
            </p>
          </div>
          <div className="button-container col-12 col-md-3 col-lg-auto">
            <button onClick={goToPlan}>{t('header.subscription.selectPlan')}</button>
          </div>
        </div>   
      </section>
    </header>
  ) : (
    <header className="call-out">
      <section className="container container-xxxl container-header">
        <div className="row g-0 call-out justify-content-between">
          <div className="call-out-text col-12 col-md-9 col-lg-auto">
            <p className="notice solo">
              {t('header.subscription.ended')}
            </p>
          </div>
          <div className="button-container col-12 col-md-3 col-lg-auto">
            <button onClick={goToPlan}>{t('header.subscription.selectPlan')}</button>
          </div>
        </div>
      </section>
    </header>
  );
}

export default SubscriptionBanner;