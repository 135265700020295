import React, { useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import Header from '../shared/Header';
import { State } from '../../store/shared/state';
import { Status } from '../../store/shared/status';
import Footer from '../shared/Footer';
import { useTranslation } from 'react-i18next';
import AddProject from './AddProject';
import { toggleAddProject, toggleAllProjects, toggleArchivedProjects } from '../../store/projects/actions';
import ProjectsTitle from './ProjectsTitle';
import ProjectsListError from './ProjectsListError';
import ProjectsLoader from './ProjectsLoader';
import { IProject } from '../../store/projects/types';
import Project from './Project';
import { listUsers, updateUserContactStage } from '../../store/users/actions';
import { getAgencyIdFromToken, getUserIdFromToken } from '../../shared/auth';
import ToolsLoader from './ToolsLoader';
import ToolsList from './ToolsList';
import SubscriptionBanner from '../shared/SubscriptionBanner';
import './ProjectsComponent.scss';
import { hideToolMenu } from '../../store/tools/actions';
import ProjectsTitleLoader from './ProjectsTitleLoader';

export const ProjectsComponent: React.FC = () => {

  const { t } = useTranslation();

  const dispatch = useDispatch();

  const clientsStatus = useSelector((state: State) => state.clients.listClientsStatus);
  const selectedClient = useSelector((state: State) => state.clients.selected);

  const projectsStatus = useSelector((state: State) => state.projects.listProjectsStatus);
  const projects = useSelector((state: State) => state.projects.projects);
  const selectedProject = useSelector((state: State) => state.projects.selected);

  const archivedProjectsStatus = useSelector((state: State) => state.projects.listArchivedProjectsStatus);
  const archivedProjects = useSelector((state: State) => state.projects.archivedProjects);

  const allProjectsOpen = useSelector((state: State) => state.projects.allProjectsOpen);
  const archivedProjectsOpen = useSelector((state: State) => state.projects.archivedProjectsOpen);

  const usersStatus = useSelector((state: State) => state.users.listUsersStatus);

  useEffect(() => {
    const agencyId = getAgencyIdFromToken();
    const userId = getUserIdFromToken();
    if(agencyId) {
      listUsers(dispatch, agencyId);
    }
    if(userId) {
      updateUserContactStage(dispatch, userId, 'GotDistracted');
    }
    // eslint-disable-next-line 
  }, [])

  const addProject = () => {
    if(clientsStatus === Status.SUCCESS) {
      dispatch(hideToolMenu());
      dispatch(toggleAddProject());
    }
  }

  const isLoading = () => clientsStatus === Status.LOADING || projectsStatus === Status.LOADING || usersStatus === Status.LOADING;

  let addProjectBlock = clientsStatus !== Status.FAILED ? (
    <React.Fragment>
      {!selectedClient?.example && <button className="add" onClick={addProject}>
        <i className="fas fa-plus-circle"></i>
        {t('projects.project')}
      </button>}
      {selectedClient?.example && <span className="badge">Example</span>}
      <AddProject />
    </React.Fragment>
  ) : null;

  const titleBlock = projectsStatus === Status.LOADING || clientsStatus === Status.LOADING || usersStatus === Status.LOADING ? ( <ProjectsTitleLoader /> ) : ( <ProjectsTitle /> )
  const projectsLoadingBlock = isLoading() ? (<ProjectsLoader />) : null;
  const projectsErrorBlock = projectsStatus === Status.FAILED ? ( <ProjectsListError /> ) : null;

  const projectsBlock = !isLoading() && projectsStatus === Status.SUCCESS ? (
    <div className="projects">
      {projects.slice(0, 5).map((project: IProject) => (
        <Project
          key={project.uuid['value'].toString()}
          project={project}
          selected={selectedProject}
        />
      ))}
    </div>
  ) : null;
  
  const moreProjectsToggleBlock = projectsStatus === Status.SUCCESS && projects.length > 10 ? (
    <button className="concertina" onClick={() => dispatch(toggleAllProjects())}>
      {t('projects.allProjects')}
      {!allProjectsOpen && <i className="fas fa-chevron-down" /> }
      {allProjectsOpen && <i className="fas fa-chevron-up" /> }
    </button>
  ) : null;

  const moreProjectsBlock = projectsStatus === Status.SUCCESS && allProjectsOpen ? (
    <div className="projects">
      {projects.slice(5).map((project: IProject) => (
        <Project
          key={project.uuid['value'].toString()}
          project={project}
          selected={selectedProject}
        />
      ))}
    </div>
  ) : null;

  const archivedProjectsToggleBlock = archivedProjectsStatus === Status.SUCCESS && archivedProjects.length > 0 ? (
    <button className="concertina" onClick={() => dispatch(toggleArchivedProjects())}>
      {t('projects.archived')}
      {!archivedProjectsOpen && <i className="fas fa-chevron-down" />}
      {archivedProjectsOpen && <i className="fas fa-chevron-up" />}
    </button>
  ) : null;

  const archivedProjectsBlock = archivedProjectsStatus === Status.SUCCESS && archivedProjectsOpen ? (
    <div className="projects">
      {archivedProjects.map((project: IProject) => (
        <Project
          key={project.uuid['value'].toString()}
          project={project}
          selected={selectedProject}
        />
      ))}
    </div>
  ) : null;

  const toolsLoadingBlock = isLoading() ? (<ToolsLoader />) : null;
  const toolsBlock = !isLoading() && projectsStatus === Status.SUCCESS ? <ToolsList /> : null;

  return (
    <section className="container-fluid">
      <Header />
      <SubscriptionBanner />
      <section className="container container-xxxl container-body">
      <div className="row">
        <div className="col-12 col-lg-3 left-column">
          {addProjectBlock}
        </div>
        <div className="col-12 col-lg-9 right-column">
          {titleBlock}
        </div>
      </div>

      <div className="row">
        <div className="col-12 col-lg-3 left-column">
          {projectsErrorBlock} 
          {projectsLoadingBlock}
          {projectsBlock}
          {moreProjectsToggleBlock}
          {moreProjectsBlock}
          {archivedProjectsToggleBlock}
          {archivedProjectsBlock}
        </div>
        <div className="col-12 col-lg-9 right-column">
          {toolsLoadingBlock}
          {toolsBlock}
        </div>
      </div>

      </section>
      <Footer />
    </section>
  );
}

export default ProjectsComponent;