import React from "react"
import ContentLoader from 'react-content-loader'

const DownloadsLoader: React.FC = () => {
  return (
    <ContentLoader width={"100%"} height={340}>
      <rect x="5" y="0" rx="5" ry="5" width="40%" height="50" />

      <rect x="5" y="80" rx="5" ry="5" width="20%" height="15" />
      <rect x="88%" y="80" rx="5" ry="5" width="10%" height="40" />
      <rect x="5" y="105" rx="5" ry="5" width="40%" height="15" />

      <rect x="5" y="155" rx="5" ry="5" width="20%" height="15" />
      <rect x="88%" y="155" rx="5" ry="5" width="10%" height="40" />
      <rect x="5" y="180" rx="5" ry="5" width="40%" height="15" />

      <rect x="5" y="235" rx="5" ry="5" width="20%" height="15" />
      <rect x="88%" y="235" rx="5" ry="5" width="10%" height="40" />
      <rect x="5" y="260" rx="5" ry="5" width="40%" height="15" />

    </ContentLoader>
  );
};

export default DownloadsLoader;
  