import React, { useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import Header from '../shared/Header';
import { State } from '../../store/shared/state';
import { Status } from '../../store/shared/status';
import Footer from '../shared/Footer';
import { useTranslation } from 'react-i18next';
import { listUsers } from '../../store/users/actions';
import { getAgencyIdFromToken } from '../../shared/auth';
import SubscriptionBanner from '../shared/SubscriptionBanner';
import { useHistory } from 'react-router-dom';
import ProjectSettingsTitleLoader from './ProjectSettingsTitleLoader';
import { getQueryString, setValueInQueryString } from '../../shared/queryString';
import { toggleArchiveProject, toggleEditProjectNotes } from '../../store/projects/actions';
import EditProjectNotes from './EditProjectNotes';
import ArchiveProject from './ArchiveProject';

export const ProjectSettingsComponent: React.FC = () => {

  const { t } = useTranslation();

  const dispatch = useDispatch();
  const history = useHistory();

  const projectsStatus = useSelector((state: State) => state.projects.listProjectsStatus);
  const selectedProject = useSelector((state: State) => state.projects.selected);

  const goBack = () => {
    if(selectedProject !== null && selectedProject!.archived) {
      setValueInQueryString('project', null);
      history.push({ pathname: '/projects', search: getQueryString() });
    } else {
      history.goBack();
    }
  }
  
  const goToCompetitors = () => {
    history.push({ pathname: '/competitors', search: getQueryString()});
  }

  const toggleProjectNotes = () => {

  }

  const archiveProject = () => {
    history.push({ pathname: '/competitors', search: getQueryString()});
  }

  const titleBlock = projectsStatus === Status.SUCCESS ? ( <h1>{selectedProject?.name} {t('projectSettings.title')}</h1> ) : ( <ProjectSettingsTitleLoader /> )

  return (
    <React.Fragment>
      <section className="container-fluid fake-header grey">
        <Header />
        <SubscriptionBanner />
        <section className="container container-xxxl container-body">
          <div className="row">
          <div className="col-12 col-lg-3 left-column">
              <h4 className="back" onClick={goBack}>
                <i className="fas fa-chevron-left"></i>
                {t('general.back')}
              </h4>
            </div>
            <div className="col-12 col-lg-9 right-column">
              {titleBlock}
            </div>
          </div>
        </section>
      </section>
      <section className="container-fluid">
        <section className="container container-xxxl container-body">
          <div className="row">
            <div className="col-12 col-lg-3 left-column">
              &nbsp;
            </div>
            <div className="col-12 col-lg-9 right-column">
              {/* <div className="row">
                <div className="col-12 col-xl-6">
                  <div className="call-to-action medium red-computer" >
                    <h2>{t('projectSettings.settings')}</h2>
                    <h3>{t('projectSettings.settingsSubHeading')}</h3>
                  </div>
                </div>
                <div className="col-12 col-xl-6">
                  <div className="call-to-action medium yellow-spacemen">
                    <h2>{t('projectSettings.competitors')}</h2>
                    <h3>{t('projectSettings.competitorsSubHeading')}</h3>
                  </div>
                </div>
              </div> */}
              <div className="row">
                <div className="col-12 col-xl-6">
                  <div className="call-to-action medium grey-computer" onClick={() => { dispatch(toggleEditProjectNotes()) }}>
                    <h2>{t('projectSettings.notes')}</h2>
                    <h3>{t('projectSettings.notesSubHeading')}</h3>
                  </div>
                  <EditProjectNotes />
                </div>
                <div className="col-12 col-xl-6">
                  <div className="call-to-action medium orange-rocket" onClick={() => { dispatch(toggleArchiveProject()) }}>
                    <h2>{t('projectSettings.archive')}</h2>
                    <h3>{t('projectSettings.archiveSubHeading')}</h3>
                  </div>
                  <ArchiveProject />
                </div>
              </div>
            </div>
          </div>
        </section>
        <Footer />
      </section>
    </React.Fragment>
  );
}

export default ProjectSettingsComponent;