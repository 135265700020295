import { Status } from "../shared/status";
import { IProject, ProjectsAction, ProjectsState } from "./types";

const initialState: ProjectsState = {
  projects: [],
  totalProjects: 0,
  selected: null,
  listProjectsStatus: Status.IDLE,
  listProjectsError: null,
  archivedProjects: [],
  totalArchivedProjects: 0,
  listArchivedProjectsStatus: Status.IDLE,
  listArchivedProjectsError: null,
  addProjectOpened: false,
  addProjectStatus: Status.IDLE,
  addProjectError: null,
  allProjectsOpen: false,
  archivedProjectsOpen: false,
  exampleProjects: [],
  totalExampleProjects: 0,
  listExampleProjectsStatus: Status.IDLE,
  listExampleProjectsError: null,
  editProjectNotesOpened: false,
  editProjectNotesStatus: Status.IDLE,
  editProjectNotesError: null,
  archiveProjectOpened: false,
  archiveProjectStatus: Status.IDLE,
  archiveProjectError: null,
  selectedTool: null
};

const projectsReducer = (state: ProjectsState = initialState, action: ProjectsAction): ProjectsState => {

  switch (action.type) {

    case 'projects/list-projects-loading':

      return {
        ...state,
        listProjectsStatus: Status.LOADING,
        listProjectsError: null
      };

    case 'projects/list-projects-error':

      return {
        ...state,
        listProjectsStatus: Status.FAILED,
        listProjectsError: action.payload
      };

    case 'projects/list-projects-success':

      return {
        ...state,
        projects: action.payload.projects,
        totalProjects: action.payload.total,
        listProjectsStatus: Status.SUCCESS,
        listProjectsError: null
      };

    case 'projects/select-project':

      // Select the project if it exists
      if(state.projects && state.projects.length > 0 && action.payload['projectId']) {
        let selected = state.projects != null ? state.projects.find(x => x.uuid['value'] === action.payload['projectId']['value']) : null;
        state.selected = selected && selected !== undefined ? selected : null;
      } else {
        state.selected = null;
      } 

      // Toggle archived projects if the selected project is archived
      if(!state.selected && state.archivedProjects && state.archivedProjects.length > 0 && action.payload) {
        let selected = state.archivedProjects != null ? state.archivedProjects.find(x => x.uuid['value'] === action.payload['projectId']['value']) : null;
        state.selected = selected && selected !== undefined ? selected : null;
        state.archivedProjectsOpen = state.selected ? true : false;
      } else {
        state.archivedProjectsOpen = false;
      } 
  
      // Toggle all projects if the selected project is at the end
      let allProjectsOpen = state.allProjectsOpen;
      if(state.selected) {
        let index = state.projects.indexOf(state.selected!);
        if(index > 9) {
          allProjectsOpen = true;
        }
      }

      // Set the selected tool
      if(state.selected && state.selected.tools && action.payload['toolId']) {
        for(let i = 0; i < state.selected.tools.length; i++) {
          if(state.selected.tools[i].uuid['value'] === action.payload['toolId']['value']) {
            state.selectedTool = state.selected.tools[i];
            break;
          }
        }
      }

      return {
        ...state,
        allProjectsOpen: allProjectsOpen
      };
  
    case 'projects/list-archived-projects-loading':

      return {
        ...state,
        listArchivedProjectsStatus: Status.LOADING,
        listArchivedProjectsError: null
      };

    case 'projects/list-archived-projects-error':

      return {
        ...state,
        listArchivedProjectsStatus: Status.FAILED,
        listArchivedProjectsError: action.payload
      };

    case 'projects/list-archived-projects-success':

      return {
        ...state,
        archivedProjects: action.payload.projects,
        totalArchivedProjects: action.payload.total,
        listArchivedProjectsStatus: Status.SUCCESS,
        listArchivedProjectsError: null
      };

    case 'projects/list-example-projects-loading':

      return {
        ...state,
        listExampleProjectsStatus: Status.LOADING,
        listExampleProjectsError: null
      };

    case 'projects/list-example-projects-error':

      return {
        ...state,
        listExampleProjectsStatus: Status.FAILED,
        listExampleProjectsError: action.payload
      };

    case 'projects/list-example-projects-success':

      return {
        ...state,
        exampleProjects: action.payload.projects,
        totalExampleProjects: action.payload.total,
        listExampleProjectsStatus: Status.SUCCESS,
        listExampleProjectsError: null
      };

    case 'projects/toggle-add-project':

      return {
        ...state,
        addProjectOpened: !state.addProjectOpened,
        addProjectError: state.addProjectOpened ? state.addProjectError : null
      };

    case 'projects/add-project-loading':

      return {
        ...state,
        addProjectStatus: Status.LOADING,
        addProjectError: null
      };

    case 'projects/add-project-error':

      return {
        ...state,
        addProjectStatus: Status.FAILED,
        addProjectError: action.payload
      };

    case 'projects/add-project-success':

      let projects = state.projects.slice();
      let totalProjects = state.totalProjects + 1;

      projects.unshift(action.payload);

      return {
        ...state,
        projects,
        totalProjects,
        addProjectStatus: Status.SUCCESS,
        addProjectError: null
      };

    case 'tools/edit-tool-success':

      let projectsToUpdate = state.projects.slice();

      for(var i = 0; i < projectsToUpdate.length; i++) {
        if(projectsToUpdate[i].uuid['value'] === action.payload['projectId']['value']) {
          for(var j = 0; i < projectsToUpdate[i].tools.length; j++) {
            if(projectsToUpdate[i].tools[j].uuid['value'] === action.payload['uuid']['value']) {
              projectsToUpdate[i].tools[j] = action.payload;
              break;
            }
          }
          break;
        }
      }

      return {
        ...state,
        projects: projectsToUpdate
      };


    case 'tools/clone-tool-success':

      let projectsToUpdate2 = state.projects.slice();

      for(var k = 0; k < projectsToUpdate2.length; k++) {
        if(projectsToUpdate2[k].uuid['value'] === action.payload['projectId']['value']) {
          projectsToUpdate2[k].tools.unshift(action.payload);
          break;
        }
      }

      return {
        ...state,
        projects: projectsToUpdate2
      };

    case 'projects/toggle-all-projects':

      return {
        ...state,
        allProjectsOpen: !state.allProjectsOpen
      };


    case 'projects/toggle-archived-projects':

      return {
        ...state,
        archivedProjectsOpen: !state.archivedProjectsOpen
      };

    case 'projects/toggle-edit-project-notes':

      return {
        ...state,
        editProjectNotesOpened: !state.editProjectNotesOpened,
        editProjectNotesError: state.editProjectNotesOpened ? state.editProjectNotesError : null
      };

    case 'projects/edit-project-notes-loading':

      return {
        ...state,
        editProjectNotesStatus: Status.LOADING,
        editProjectNotesError: null
      };

    case 'projects/edit-project-notes-error':

      return {
        ...state,
        editProjectNotesStatus: Status.FAILED,
        editProjectNotesError: action.payload
      };

    case 'projects/edit-project-notes-success':

      let projectsToUpdateForNotes = state.projects.slice();

      for(var l = 0; l < projectsToUpdateForNotes.length; l++) {
        if(projectsToUpdateForNotes[l].uuid['value'] === action.payload['uuid']['value']) {
          projectsToUpdateForNotes[l].projectNotes = action.payload['projectNotes'];
          break;
        }
      }

      return {
        ...state,
        projects: projectsToUpdateForNotes,
        editProjectNotesStatus: Status.SUCCESS,
        editProjectNotesError: null
      };

    case 'projects/toggle-archive-project':

      return {
        ...state,
        archiveProjectOpened: !state.archiveProjectOpened,
        archiveProjectError: state.archiveProjectOpened ? state.archiveProjectError : null
      };

    case 'projects/archive-project-loading':

      return {
        ...state,
        archiveProjectStatus: Status.LOADING,
        archiveProjectError: null
      };

    case 'projects/archive-project-error':

      return {
        ...state,
        archiveProjectStatus: Status.FAILED,
        archiveProjectError: action.payload
      };

    case 'projects/archive-project-success':

      let projectsToUpdateForArchive = state.projects.slice();
      let archivedProjectsToUpdateForArchive = state.archivedProjects.slice();

      let project: IProject | null = null;

      for(var m = 0; m < projectsToUpdateForArchive.length; m++) {
        if(projectsToUpdateForArchive[m].uuid['value'] === action.payload['uuid']['value']) {
          project = projectsToUpdateForArchive[m];
          project.archived = true;
          projectsToUpdateForArchive.splice(m, 1);
          break;
        }
      }

      if(project !== null) {
        archivedProjectsToUpdateForArchive.unshift(project);
      }

      return {
        ...state,
        projects: projectsToUpdateForArchive,
        totalProjects: projectsToUpdateForArchive.length,
        archivedProjects: archivedProjectsToUpdateForArchive,
        totalArchivedProjects: archivedProjectsToUpdateForArchive.length,
        selected: project !== null ? project : state.selected,
        archiveProjectStatus: Status.SUCCESS,
        editProjectNotesError: null
      };

      case 'projects/select-tool':

        return {
          ...state,
          selectedTool: action.payload
        };
  
  }

  return state;
};

export default projectsReducer;
