import { Status } from '../shared/status';
import { ClientsAction, ClientsState } from './types';

const initialState: ClientsState = {
  clients: [],
  totalClients: 0,
  selected: null,
  listClientsStatus: Status.IDLE,
  listClientsError: null,
  addClientOpened: false,
  addClientStatus: Status.IDLE,
  addClientError: null,
  selectClientOpened: false
};

const clientsReducer = (state: ClientsState = initialState, action: ClientsAction): ClientsState => {

  switch (action.type) {

    case 'clients/list-clients-loading':

      return {
        ...state,
        listClientsStatus: Status.LOADING,
        listClientsError: null
      };

    case 'clients/list-clients-error':

      return {
        ...state,
        listClientsStatus: Status.FAILED,
        listClientsError: action.payload
      };

    case 'clients/list-clients-success':

      return {
        ...state,
        clients: action.payload.clients,
        totalClients: action.payload.total,
        listClientsStatus: Status.SUCCESS,
        listClientsError: null
      };

    case 'clients/select-client':

      if(state.clients && state.clients.length > 0) {
        if(action.payload) {
          let selected = state.clients != null ? state.clients.find(x => x.uuid['value'] === action.payload['value']) : null;
          state.selected = selected && selected !== undefined ? selected : state.clients[0];

        } else {
          let nonExampleClients = state.clients.filter(x => x.example === false);
          state.selected = nonExampleClients.length === 0 ? state.clients[0] : nonExampleClients[0];
        } 
      } else {
        state.selected = null;
      }
  
      return {
        ...state
      };

    case 'clients/toggle-add-client':

      return {
        ...state,
        addClientOpened: !state.addClientOpened,
        addClientError: state.addClientOpened ? state.addClientError : null
      };

    case 'clients/add-client-loading':

      return {
        ...state,
        addClientStatus: Status.LOADING,
        addClientError: null
      };

    case 'clients/add-client-error':

      return {
        ...state,
        addClientStatus: Status.FAILED,
        addClientError: action.payload
      };

    case 'clients/add-client-success':

      let clients = state.clients.slice();
      let totalClients = state.totalClients + 1;

      clients.unshift(action.payload);

      return {
        ...state,
        clients,
        totalClients,
        addClientStatus: Status.SUCCESS,
        addClientError: null
      };

    case 'clients/toggle-select-client':
      return {
        ...state,
        selectClientOpened: !state.selectClientOpened,
      };
  
  }
  return state;
};

export default clientsReducer;
