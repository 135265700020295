import * as React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";
import { getQueryParams } from "../../shared/queryString";
import { State } from "../../store/shared/state";

export const ProjectsTitle: React.FC = () => {

  const { t } = useTranslation();

  const history = useHistory();

  const selectedClient = useSelector((state: State) => state.clients.selected);
  const selectedProject = useSelector((state: State) => state.projects.selected);

  const goToTemplates = () => {
    let params = getQueryParams();

    params['client'] = selectedClient ? selectedClient!.uuid : null;
    params['project'] = selectedProject ? selectedProject!.uuid : null;
    history.push({ pathname: '/templates', search: '?' + params.toString() });
  }

  const gotToProjectSettings = () => {
    let params = getQueryParams();

    params['client'] = selectedClient ? selectedClient!.uuid : null;
    params['project'] = selectedProject ? selectedProject!.uuid : null;
    history.push({ pathname: '/project-settings', search: '?' + params.toString() });
  }

  const goToToolkit = () => {
    let params = getQueryParams();

    params['client'] = selectedClient ? selectedClient!.uuid : null;
    params['project'] = selectedProject ? selectedProject!.uuid : null;
    history.push({ pathname: '/toolkit', search: '?' + params.toString() });
  }

  return (
    <div className="row justify-content-end">
      <div className="col-12 col-xl">
        <h1 className="list-title">{selectedProject?.name}</h1>
        {selectedProject && <h2 className="list-sub-title">{t('projects.jobNumber')}: {selectedProject?.jobNumber}</h2>}
      </div>
      <div className="col-12 col-xl-auto buttons">
        {!selectedClient?.example && <button className="add yellow mr" onClick={goToToolkit}>
          <i className="fas fa-plus-circle"></i>
          <span>{t('projects.tool')}</span>
        </button>}
        <button className="button red mr" onClick={goToTemplates}>
          {t('projects.templates')}
        </button>
        <button className="edit blue icon" onClick={gotToProjectSettings}>
          <i className="fas fa-cog"></i>
          <span>{t('projects.settings')}</span>
        </button>
      </div>
    </div>
  );
};

export default ProjectsTitle;
