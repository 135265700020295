import React from "react";
import { Modal } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { addClient, toggleAddClient } from "../../store/clients/actions";
import { useDispatch } from "react-redux";
import { Guid } from "guid-typescript";
import { State } from "../../store/shared/state";
import { Status } from "../../store/shared/status";
import { useForm, Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { IUser } from "../../store/users/types";
import Select from 'react-select'

type FormData = {
  name: string;
  projectName: string;
  leaderId: string | undefined;
  jobNumber: string;
};

export const AddClient: React.FC = () => {

  const { t } = useTranslation();
  const { register, handleSubmit, control, formState: { errors }, reset, setValue } = useForm<FormData>();

  const addClientOpened = useSelector((state: State) => state.clients.addClientOpened);
  const addClientStatus = useSelector((state: State) => state.clients.addClientStatus);
  const addClientError = useSelector((state: State) => state.clients.addClientError);

  const users = useSelector((state: State) => state.users.users.map((x: IUser) => {
    return {'value': x.uuid['value'], 'label': x.firstName + ' ' + x.lastName}
  }));

  const defaultValues = { name: '', projectName: '', jobNumber: '' , leader: undefined};

  const submitButtonText = addClientStatus !== Status.LOADING 
    ? ( <span>{t('addClient.submit')} <i className="fas d-none d-sm-inline fa-chevron-right"></i></span> )
    : ( <span>{t('addClient.processing')} <i className="fas d-none d-sm-inline fa-spin fa-circle-notch"></i></span> );

  const dispatch = useDispatch();

  const onSubmit = (data: any, errors: any) => {
    addClient(
      dispatch, 
      { 
        uuid: Guid.create(), 
        agencyId: Guid.create(), 
        name: data.name, 
        example: false 
      },
      { 
        uuid: Guid.create(), 
        dateCreated: new Date(),
        dateModified: new Date(),
        dateCommenced: new Date(),
        agencyId: Guid.create(), 
        clientId: Guid.create(), 
        name: data.projectName, 
        leaderId: data.leaderId, 
        jobNumber: data.jobNumber, 
        projectNotes: '',
        archived: false,
        tools: [],
        competitors: [],
        business: null,
        businessMetrics: null
      }
    );
  }

  const onErrors = (errors: any) => console.error(errors);

  return (

    <Modal className="modal form-modal blue-modal"
      show={addClientOpened}
      animation={false}
      onShow={() => reset(defaultValues)}
      onHide={() => dispatch(toggleAddClient())}
      centered
    >

      <Modal.Header>
        <Modal.Title>{t('addClient.title')}</Modal.Title>
        <button type="button" data-bs-dismiss="modal" aria-label="Close" onClick={() => { dispatch(toggleAddClient()) }}><i className="fas fa-times"></i></button>
      </Modal.Header>

      <Modal.Body>
        <form onSubmit={handleSubmit(onSubmit, onErrors)}>
          <fieldset>
            <label>
              <div className="row">
                <div className="col-12 col-lg-6">{t('addClient.clientName')}</div>
                <div className="col-12 col-lg-6 error">{errors.name?.type === 'required' && t('form.required', { field: t('form.name') })}</div>
              </div>
            </label>

            <input type="text" className="mb-4" {...register("name", { required: true, maxLength: 255 })} /> 

            <label>
              <div className="row">
                <div className="col-12 col-lg-6">{t('addProject.projectName')}</div>
                <div className="col-12 col-lg-6 error">{errors.name?.type === 'required' && t('form.required', { field: t('addProject.projectName') })}</div>
              </div>
            </label>
            <input type="text" {...register("projectName", { required: true, maxLength: 255 })} /> 

            <div className="row">
              <div className="col-12 col-xl-6">
                <label>
                  <div className="row">
                    <div className="col-12 col-lg-6">{t('addProject.leader')}</div>
                    <div className="col-12 col-lg-6 error">{errors.leaderId?.type === 'required' && t('form.required', { field: t('addProject.leader') })}</div>
                  </div>
                </label>
                
                <Controller
                    control={control}
                    name="leaderId"
                    rules={{ required: true }}
                    render={({ field }) => (
                        <Select
                          key={`leader-id-${field.value}`}
                            classNamePrefix="select"
                            options={users}
                            value={users.find(x => x.value === field.value)}
                            onChange={value => {
                              setValue("leaderId", value ? value.value : '', {
                                shouldDirty: true
                              });
                            }}
                        />
                    )}
                />

              </div>
              <div className="col-12 col-xl-6">
                <label>
                  <div className="row">
                    {(errors.jobNumber === null || errors.jobNumber === undefined) && <div className="col-12">{t('addProject.jobNumber')} {t('addProject.jobNumberNote')}</div>}

                    {errors.jobNumber?.type === 'unique' &&
                      <>
                        <div className="col-12 col-lg-5">{t('addProject.jobNumber')}</div>
                        <div className="col-12 col-lg-7 error">{errors.jobNumber?.type === 'unique' && t('form.uniqueGeneric') }</div>
                      </>
                    }

                  </div>
                </label>
                <input type="text" {...register("jobNumber", { required: false, maxLength: 255 })} /> 
              </div>
            </div>
            <p className="mb-5">{t('addProject.note')}</p>

            <div className="server-error">{t(addClientError)}</div>

          </fieldset>
          <div className="actions">
            <button type="submit" disabled={addClientStatus === Status.LOADING}>{submitButtonText}</button>
          </div>
        </form>

      </Modal.Body>
    </Modal>

  );
};
export default AddClient;