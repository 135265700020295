import { Guid } from 'guid-typescript';
import { AxiosResponse } from 'axios';
import axios from '../../shared/http';

import { normaliseErrorCode, transformList, transformObjectFromString } from '../shared/transform';
import { IProject, IProjectsResponse } from './types';
import { getValueFromQueryString, setValueInQueryString } from '../../shared/queryString';
import { toast } from 'react-toastify';
import i18next from 'i18next';
import { ITool } from '../tools/types';
import { loadImageData } from '../images/actions';

// Action creators
const addProjectLoading = () => ({ type: 'projects/add-project-loading' });
const addProjectSuccess = (payload: any) => ({ type: 'projects/add-project-success', payload});
const addProjectError = (payload: any) => ({ type: 'projects/add-project-error', payload });

const listProjectsLoading = () => ({ type: 'projects/list-projects-loading' });
const listProjectsSuccess = (payload: any) => ({ type: 'projects/list-projects-success', payload});
const listProjectsError = (payload: any) => ({ type: 'projects/list-projects-error', payload });

const listArchivedProjectsLoading = () => ({ type: 'projects/list-archived-projects-loading' });
const listArchivedProjectsSuccess = (payload: any) => ({ type: 'projects/list-archived-projects-success', payload});
const listArchivedProjectsError = (payload: any) => ({ type: 'projects/list-archived-projects-error', payload });

const listExampleProjectsLoading = () => ({ type: 'projects/list-example-projects-loading' });
const listExampleProjectsSuccess = (payload: any) => ({ type: 'projects/list-example-projects-success', payload});
const listExampleProjectsError = (payload: any) => ({ type: 'projects/list-example-projects-error', payload });

const editProjectNotesLoading = () => ({ type: 'projects/edit-project-notes-loading' });
const editProjectNotesSuccess = (payload: any) => ({ type: 'projects/edit-project-notes-success', payload});
const editProjectNotesError = (payload: any) => ({ type: 'projects/edit-project-notes-error', payload });

const archiveProjectLoading = () => ({ type: 'projects/archive-project-notes-loading' });
const archiveProjectSuccess = (payload: any) => ({ type: 'projects/archive-project-success', payload});
const archiveProjectError = (payload: any) => ({ type: 'projects/archive-project-error', payload });

export const toggleAddProject = () => ({ type: 'projects/toggle-add-project' });
export const toggleEditProjectNotes = () => ({ type: 'projects/toggle-edit-project-notes' });
export const toggleArchiveProject = () => ({ type: 'projects/toggle-archive-project' });

export const selectProject = (payload: any) => {

  let projectId = getValueFromQueryString('project');
  let toolId = getValueFromQueryString('tool') ? getValueFromQueryString('tool') : null;

  if(!projectId || projectId['value'] !== payload['value']) {
    setValueInQueryString('project', payload);
  }

  return { type: 'projects/select-project', payload: {projectId: projectId, toolId: toolId} }
};

export const selectTool = (payload: ITool) =>  ({ type: 'projects/select-tool', payload });
export const toggleAllProjects = () => ({ type: 'projects/toggle-all-projects' });
export const toggleArchivedProjects = () => ({ type: 'projects/toggle-archived-projects' });

export const listProjects = async (dispatch: any, clientId: Guid) => {

  dispatch(listProjectsLoading());

  axios.get<IProjectsResponse>(
    `${process.env.REACT_APP_PROJECTS_PATH}?clientId=${clientId['value']}&sort=dateLastActivity&order=desc&active=true`,
    { transformResponse: (data: string) => transformList<IProject>(data, 'projects') })
    .then((response: AxiosResponse<IProjectsResponse>) => {

      loadCompetitorImages(dispatch, response.data);

      dispatch(listProjectsSuccess(response.data));

      let projectId = getValueFromQueryString('project') ? getValueFromQueryString('project') : null;
      if(projectId) {
        dispatch(selectProject(projectId));
      } else if(response.data.total > 0) {
        dispatch(selectProject(response.data.projects[0].uuid));
      }

    })
    .catch((error: any) => {
      // console.log(error);
      dispatch(listProjectsError(error));
    });
};

export const listArchivedProjects = async (dispatch: any, clientId: Guid) => {

  dispatch(listArchivedProjectsLoading());

  axios.get<IProjectsResponse>(
    `${process.env.REACT_APP_PROJECTS_PATH}?clientId=${clientId['value']}&sort=dateLastActivity&order=desc&active=false`,
    { transformResponse: (data: string) => transformList<IProject>(data, 'projects') })
    .then((response: AxiosResponse<IProjectsResponse>) => {

      dispatch(listArchivedProjectsSuccess(response.data));

      let projectId = getValueFromQueryString('project') ? getValueFromQueryString('project') : null;
      if(projectId) {
        dispatch(selectProject(projectId));
      }
    })
    .catch((error: any) => {
      // console.log(error);
      dispatch(listArchivedProjectsError(error));
    });
};

export const listExampleProjects = async (dispatch: any, clientId: Guid | undefined) => {

  if(clientId === undefined) {
    return;
  }

  dispatch(listExampleProjectsLoading());

  axios.get<IProjectsResponse>(
    `${process.env.REACT_APP_PROJECTS_PATH}?clientId=${clientId['value']}&sort=dateLastActivity&order=desc&active=true`,
    { transformResponse: (data: string) => transformList<IProject>(data, 'projects') })
    .then((response: AxiosResponse<IProjectsResponse>) => {
      dispatch(listExampleProjectsSuccess(response.data));
    })
    .catch((error: any) => {
      // console.log(error);
      dispatch(listExampleProjectsError(error));
    });
};

export const addProject = async (dispatch: any, data: IProject) => {

  dispatch(addProjectLoading());

  axios.post(
    `${process.env.REACT_APP_PROJECTS_PATH}`, 
    { agencyId: data.agencyId['value'], clientId: data.clientId['value'], name: data.name, leaderId: data.leaderId, jobNumber: data.jobNumber },
    { transformResponse: (data: string) => transformObjectFromString<IProject>(data) })
    .then((response: any) => {

      dispatch(addProjectSuccess(response.data));
      dispatch(toggleAddProject());

      dispatch(selectProject(response.data.uuid));

    })
    .catch((error: any) => {
      // console.log(error);
      dispatch(addProjectError(normaliseErrorCode(error, 'projects.project.create')));
    });
};

export const editProject = async (dispatch: any, project: IProject | null, data: any) => {

  //
  // dispatch(editToolLoading());

  let payload = data;

  if(!project) {
  //   dispatch(editToolError(normaliseErrorCode('Error', 'projects.tool.edit')));
    return;
  }
  
  axios.patch(
    `${process.env.REACT_APP_PROJECTS_PATH}/${project.uuid['value']}`, 
    payload,
    { transformResponse: (data: string) => transformObjectFromString<IProject>(data) })
    .then((response: any) => {

      // toast.success(i18next.t('editTool.success'));

      // dispatch(editToolSuccess(response.data));
      // dispatch(toggleEditTool());
    })
    .catch((error: any) => {
      // console.error(error);
      // dispatch(editToolError(normaliseErrorCode(error, 'projects.tool.edit')));
    });
};


export const editProjectNotes = async (dispatch: any, project: IProject | null, data: string) => {

  dispatch(editProjectNotesLoading());

  let payload = { projectNotes: data };

  if(!project) {
    dispatch(editProjectNotesError(normaliseErrorCode('Error', 'projects.projectNotes.edit')));
    return;
  }

  axios.patch(
    `${process.env.REACT_APP_PROJECTS_PATH}/${project.uuid['value']}`, 
    payload,
    { transformResponse: (data: string) => transformObjectFromString<any>(data) })
    .then((response: any) => {

      response.data['uuid'] = project.uuid;

      toast.success(i18next.t('editProjectNotes.success'));

      dispatch(editProjectNotesSuccess(response.data));
      dispatch(toggleEditProjectNotes());
    })
    .catch((error: any) => {
      // console.error(error);
      dispatch(editProjectNotesError(normaliseErrorCode(error, 'projects.projectNotes.edit')));
    });
};


export const archiveProject = async (dispatch: any, project: IProject | null) => {

  dispatch(archiveProjectLoading());

  if(!project) {
    dispatch(archiveProjectError(normaliseErrorCode('Error', 'projects.project.archive')));
    return;
  }

  let payload = { archived: !project!.archived };

  axios.patch(
    `${process.env.REACT_APP_PROJECTS_PATH}/${project.uuid['value']}`, 
    payload,
    { transformResponse: (data: string) => transformObjectFromString<any>(data) })
    .then((response: any) => {

      response.data['uuid'] = project.uuid;

      if(!project.archived) {
        toast.success(i18next.t('archiveProject.success'));
      } else {
        toast.success(i18next.t('archiveProject.successAlt'));
      }

      dispatch(archiveProjectSuccess(response.data));
      dispatch(toggleArchiveProject());
    })
    .catch((error: any) => {
      // console.error(error);
      dispatch(archiveProjectError(normaliseErrorCode(error, 'projects.project.archive')));
    });
};

const loadCompetitorImages = (dispatch: any, response: IProjectsResponse) => {
  
  for(let project of response.projects) {
    if(!project.tools) {
      continue;
    }

    for(let tool of project.tools) {

      if(!tool['data'] || !tool['data']['selectedCompetitors']) {
        continue;
      }

      for(let competitor of tool['data']['selectedCompetitors']) {
        if(competitor.logo && competitor.logo.length === 36) {
          loadImageData(dispatch, competitor.logo);
        }
      }
    }
  }

}