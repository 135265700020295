import { Guid } from 'guid-typescript';
import { AxiosResponse } from 'axios';
import axios from '../../shared/http';
import { transformList } from '../shared/transform';
import { IUser, IUsersResponse } from './types';

// Action creators
const listUsersLoading = () => ({ type: 'users/list-users-loading' });
const listUsersSuccess = (payload: any) => ({ type: 'users/list-users-success', payload});
const listUsersError = (payload: any) => ({ type: 'users/list-users-error', payload });

export const listUsers = async (dispatch: any, agencyId: Guid) => {

  dispatch(listUsersLoading());

  axios.get<IUsersResponse>(
    `${process.env.REACT_APP_USERS_PATH}?agencyId=${agencyId['value']}&active=true&sort=name&order=asc&page=0&pageSize=1000`,
    { transformResponse: (data: string) => transformList<IUser>(data, 'users') })
    .then((response: AxiosResponse<IUsersResponse>) => {
      dispatch(listUsersSuccess(response.data));
    })
    .catch((error: any) => {
      // console.log(error);
      dispatch(listUsersError(error));
    });
};

export const updateUserContactStage = async (dispatch: any, userId: Guid, stage: string) => {

  axios.post(
    `${process.env.REACT_APP_USERS_PATH}/${userId['value']}/update-contact-stage`,
    {stage: stage}
    )
    .then((response: AxiosResponse) => {
      console.error(response);
    })
    .catch((error: any) => {
    });
};