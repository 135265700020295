import * as React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { formatDate } from "../../shared/dates";
import { deleteReport } from "../../store/reports/actions";
import { IReport } from "../../store/reports/types";
import { State } from "../../store/shared/state";
import { Status } from "../../store/shared/status";

type Props = {
  typeKey: string
  reports: IReport[]
};

export const BrandStrategyDownloadsList: React.FC<Props> = ({ typeKey, reports}) => {

  const { t } = useTranslation();
  const dispatch = useDispatch();

  const selectedTool = useSelector((state: State) => state.projects.selectedTool);
  const deleteReportStatus = useSelector((state: State) => state.reports.deleteReportStatus);

  const doDeleteReport = (report: IReport) => {
    deleteReport(dispatch, selectedTool!.uuid, report.uuid);
  }

  const pdfsBlock = reports.filter(x => x.fileType === 'PDF').length > 0 ? (
    <div>
      <h1 className="list-title">{t('downloads.pdfFormat')}</h1>
      <h2 className="list-sub-title">{t('downloads.pdfFormatExplanation')}</h2>

      <div className="table-container compact">
        <table>
          <tbody>
      
            {reports.filter(x => x.fileType === 'PDF').map((report: IReport) => (
              <tr key={report.uuid['value'].toString()}>
                <td>
                  <a href={`${process.env.REACT_APP_REPORTS_DOWNLOAD_PATH}${report.file}`} target="_blank" rel="noreferrer">{t('downloads.workshopReport')}</a>
                  {t('downloads.dateGenerated', { dateCreated: formatDate(report.dateCreated)})}
                </td>
                <td className="actions">
                  {deleteReportStatus !== Status.LOADING && <button className="d-none d-sm-inline-block open single"  onClick={() => doDeleteReport(report)}>{t('downloads.delete')}</button>}
                  {deleteReportStatus !== Status.LOADING && <button className="d-inline-block d-sm-none open single"  onClick={() => doDeleteReport(report)}><i className="fas fa-times"></i></button>}
                  {deleteReportStatus === Status.LOADING && <button className="open single"><i className="fas fa-spin fa-circle-notch"></i><span className="d-none d-sm-inline">{t('downloads.processing')}</span></button>}
                </td>
              </tr>
            ))}

          </tbody>
        </table>
      </div>
    </div>
  ) : null;

  const docsBlock = reports.filter(x => x.fileType === 'DOC').length > 0 ? (
    <div>
      <h1 className="list-title">{t('downloads.docFormat')}</h1>
      <h2 className="list-sub-title">{t('downloads.docFormatExplanation')}</h2>

      <div className="table-container compact">
        <table>
          <tbody>
      
            {reports.filter(x => x.fileType === 'DOC').map((report: IReport) => (
              <tr key={report.uuid['value'].toString()}>
                <td>
                  <a href={`${process.env.REACT_APP_REPORTS_DOWNLOAD_PATH}${report.file}`} target="_blank" rel="noreferrer">{t('downloads.workshopReport')}</a>
                  <a className="d-block d-sm-none" href={`${process.env.REACT_APP_REPORTS_DOWNLOAD_PATH}${report.notesFile}`}>{t('downloads.workshopNotes')}</a>
                  {t('downloads.dateGenerated', { dateCreated: formatDate(report.dateCreated)})}
                </td>
                <td className="d-none d-sm-table-cell">
                  <a href={`${process.env.REACT_APP_REPORTS_DOWNLOAD_PATH}${report.notesFile}`} target="_blank" rel="noreferrer">{t('downloads.workshopNotes')}</a>
                  {t('downloads.dateGenerated', { dateCreated: formatDate(report.dateCreated)})}
                </td>
                <td className="actions">
                  {deleteReportStatus !== Status.LOADING && <button className="d-none d-sm-inline-block open single"  onClick={() => doDeleteReport(report)}>{t('downloads.delete')}</button>}
                  {deleteReportStatus !== Status.LOADING && <button className="d-inline-block d-sm-none open single"  onClick={() => doDeleteReport(report)}><i className="fas fa-times"></i></button>}
                  {deleteReportStatus === Status.LOADING && <button className="open single"><i className="fas fa-spin fa-circle-notch"></i><span className="d-none d-sm-inline">{t('downloads.processing')}</span></button>}
                </td>
              </tr>
            ))}

          </tbody>
        </table>
      </div>
    </div>
  ) : null;


  return (
    <React.Fragment>
      {pdfsBlock}
      {docsBlock}
    </React.Fragment>
  );
};

export default BrandStrategyDownloadsList;
