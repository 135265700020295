import React from "react";
import { Modal } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import { State } from "../../store/shared/state";
import { Status } from "../../store/shared/status";
import { useForm, } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { editTool, toggleEditTool } from "../../store/tools/actions";

type FormData = {
  name: string;
};

export const EditTool: React.FC = () => {

  const { t } = useTranslation();
  const { register, handleSubmit, formState: { errors }, reset } = useForm<FormData>();

  const selectedClient = useSelector((state: State) => state.clients.selected);
  const selectedProject = useSelector((state: State) => state.projects.selected);
  const selectedTool = useSelector((state: State) => state.projects.selectedTool);

  const editToolOpened = useSelector((state: State) => state.tools.editToolOpened);
  const editToolStatus = useSelector((state: State) => state.tools.editToolStatus);
  const editToolError = useSelector((state: State) => state.tools.editToolError);

  const defaultValues = { name: selectedTool?.name};
   
  const submitButtonText = editToolStatus !== Status.LOADING 
    ? ( <span>{t('editTool.submit')} <i className="fas fa-chevron-right"></i></span> )
    : ( <span>{t('editTool.processing')} <i className="fas fa-spin fa-circle-notch"></i></span> );

  const dispatch = useDispatch();

  const typeName = t('tools.types.' + selectedTool?.type.toString());

  const onSubmit = (data: any, errors: any) => {
    editTool(dispatch, selectedTool, { name: data.name, editType: 'settings' });
  }
  
  const onErrors = (errors: any) => console.error(errors);

  return (

    <Modal className="modal form-modal yellow-modal"
      show={editToolOpened}
      animation={false}
      onShow={() => reset(defaultValues)}
      onHide={() => dispatch(toggleEditTool())}
      centered
    >

      <Modal.Header>
        <Modal.Title>
          {t('editTool.title', { type: typeName })}
          <span>{selectedClient?.name} &gt; {selectedProject?.name}</span>
          </Modal.Title>
        <button type="button" data-bs-dismiss="modal" aria-label="Close" onClick={() => { dispatch(toggleEditTool()) }}><i className="fas fa-times"></i></button>
      </Modal.Header>

      <Modal.Body>
        <form onSubmit={handleSubmit(onSubmit, onErrors)}>
          <fieldset>
            <label>
              <div className="row">
                <div className="col-12 col-lg-6">{t('addTool.name', { type: typeName })}</div>
                <div className="col-12 col-lg-6 error">{errors.name?.type === 'required' && t('form.required', { field: t('form.name') })}</div>
              </div>
            </label>
            <input type="text" {...register("name", { required: true, maxLength: 255 })} /> 

            <div className="server-error">{t(editToolError)}</div>

          </fieldset>
          <div className="actions">
            <button type="submit" disabled={editToolStatus === Status.LOADING}>{submitButtonText}</button>
          </div>

        </form>

      </Modal.Body>
    </Modal>

  );
};
export default EditTool;