import { GeneralAction, GeneralState } from "./types";

const initialState: GeneralState = {
  mobileMenuOpened: false,
  accountMenuOpened: false
};

const generalReducer = (state: GeneralState = initialState, action: GeneralAction): GeneralState => {

  switch (action.type) {

    case 'general/toggle-mobile-menu':

      return {
        ...state,
        mobileMenuOpened: !state.mobileMenuOpened,
      };

    case 'general/toggle-account-menu':

      return {
        ...state,
        accountMenuOpened: !state.accountMenuOpened,
      };
  }

  return state;
};

export default generalReducer;
