import React from "react"
import ContentLoader from 'react-content-loader'

const DashboardExamplesLoader: React.FC = () => {
  return (
    <ContentLoader width={"100%"} height={280}>
      
      <rect x="5" y="45" rx="5" ry="5" width="15%" height="20" />
      <rect x="20%" y="30" rx="5" ry="5" width="16%" height="20" />
      <rect x="20%" y="60" rx="5" ry="5" width="16%" height="20" />
      <rect x="42%" y="30" rx="5" ry="5" width="16%" height="50" />
      <rect x="64%" y="30" rx="5" ry="5" width="16%" height="20" />
      <rect x="64%" y="60" rx="5" ry="5" width="16%" height="20" />
      <rect x="85%" y="30" rx="5" ry="5" width="15%" height="50" />

      <rect x="5" y="115" rx="5" ry="5" width="15%" height="20" />
      <rect x="20%" y="100" rx="5" ry="5" width="16%" height="20" />
      <rect x="20%" y="130" rx="5" ry="5" width="16%" height="20" />
      <rect x="42%" y="100" rx="5" ry="5" width="16%" height="50" />
      <rect x="64%" y="100" rx="5" ry="5" width="16%" height="20" />
      <rect x="64%" y="130" rx="5" ry="5" width="16%" height="20" />
      <rect x="85%" y="100" rx="5" ry="5" width="15%" height="50" />

      <rect x="5" y="185" rx="5" ry="5" width="15%" height="20" />
      <rect x="20%" y="170" rx="5" ry="5" width="16%" height="20" />
      <rect x="20%" y="200" rx="5" ry="5" width="16%" height="20" />
      <rect x="42%" y="170" rx="5" ry="5" width="16%" height="50" />
      <rect x="64%" y="170" rx="5" ry="5" width="16%" height="20" />
      <rect x="64%" y="200" rx="5" ry="5" width="16%" height="20" />
      <rect x="85%" y="170" rx="5" ry="5" width="15%" height="50" />

    </ContentLoader>
  );
};

export default DashboardExamplesLoader;
  