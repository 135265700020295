import React, { useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import Header from '../shared/Header';
import { State } from '../../store/shared/state';
import Footer from '../shared/Footer';
import { useTranslation } from "react-i18next";
import { listUsers, updateUserContactStage } from '../../store/users/actions';
import { allowAdmin, getAgencyIdFromToken, getUserIdFromToken } from '../../shared/auth';
import './ToolkitComponent.scss';
import { useHistory } from 'react-router';
import AddTool from './AddTool';
import { showAddTool, showSelectResearchType } from '../../store/tools/actions';
import { listTemplates } from '../../store/templates/actions';
import { ITool, ToolType } from '../../store/tools/types';
import SelectResearchType from './SelectResearchType';
import SendNielsenEnquiry from './SendNielsenEnquiry';
import SubscriptionBanner from '../shared/SubscriptionBanner';
import SendPureSpectrumEnquiry from './SendPureSpectrumEnquiry';

export const ToolkitComponent: React.FC = () => {

  const { t } = useTranslation();

  const dispatch = useDispatch();

  const history = useHistory();

  const selectedProject = useSelector((state: State) => state.projects.selected);
  const subscription = useSelector((state: State) => state.agencies.mySubscription);

  useEffect(() => {
    const agencyId = getAgencyIdFromToken();
    if(agencyId) {
      listUsers(dispatch, agencyId);
      listTemplates(dispatch, agencyId);
    }
    const userId = getUserIdFromToken();
    if(userId) {
      updateUserContactStage(dispatch, userId, "Complex");
    }
    // eslint-disable-next-line 
  }, [])

  const goBack = () => {
    history.goBack();
  }

  const addTool = (type: ToolType) => {
    dispatch(showAddTool(type));
  }

  const selectResearchType = () => {
    dispatch(showSelectResearchType());
  }

  const getBrandStrategyTool = () : ITool | null => {

    if(selectedProject) {
      for (let x of selectedProject.tools) {
        if(x.type === ToolType.BRAND_STRATEGY) {
          return x;
        }
      }
    }

    return null;
  }

  const brandStrategyExists = () : boolean => getBrandStrategyTool() != null;

  const locked = (type: ToolType) => {
    if(type === ToolType.RESEARCH || type === ToolType.MEDIA_BRIEF || type === ToolType.CREATIVE_BRIEF) {
      return false;
    }
    return subscription && !subscription.active && !subscription.freeTrial;
  }

  const unlocked = (type: ToolType) => {
    if(type === ToolType.RESEARCH || type === ToolType.MEDIA_BRIEF || type === ToolType.CREATIVE_BRIEF) {
      return true;
    }
    return !subscription || subscription.active || subscription.freeTrial;
  }
  
  const hoverBlock = (
    <React.Fragment>
      <p className="hover">
        {t('toolkit.currentPlan')}
      </p>
      <p className="hover">
        {!allowAdmin() && <label>{t('toolkit.contactAdmin')}</label>}
        {allowAdmin() && <label><a href={`${process.env.REACT_APP_LEGACY_BASE_URL}/agency/billing`}>{t('toolkit.changeYourPlan')}</a> {t('toolkit.accessFeatures')}</label>}
      </p>
    </React.Fragment>
  );

  return (
    <section className="container-fluid yellow">
      <Header />
      <SubscriptionBanner />
      <section className="container container-xxxl container-body">
        <div className="row">
          <div className="col-12 col-md-4 col-lg-3 left-column">
            <h4 className="back" onClick={goBack}>
              <i className="fas fa-chevron-left"></i>
              {t('general.back')}
            </h4>
          </div>
          <div className="col-xs-12 col-md-8 col-lg-9 right-column">
            <h1>{selectedProject?.name} {t('toolkit.title')}</h1>
          </div>
        </div>
        <div className="row">
          <div className="col-12 col-md-4 col-lg-3 left-column">
            <h4 className="no-margin">{t('toolkit.comingSoon')}</h4>

            <ul className="text-list">
              <li>{t('toolkit.productionSchedule')}</li>
              <li>{t('toolkit.businessPlan')}</li>
              <li>{t('toolkit.proposal')}</li>
              <li>{t('toolkit.meetingMinutes')}</li>
              <li>{t('toolkit.surveyMonkey')}</li>
              <li>{t('toolkit.kahoot')}</li>
              <li>{t('toolkit.brandBeliefSystem')}</li>
              <li>{t('toolkit.brandManifesto')}</li>
              <li>{t('toolkit.brandBenefits')}</li>
              <li>{t('toolkit.hierachyOfNeeds')}</li>
              <li>{t('toolkit.swotAnalysis')}</li>
            </ul>

          </div>
          <div className="col-xs-12 col-md-8 col-lg-9 right-column">

            <AddTool />
            <SelectResearchType />
            <SendNielsenEnquiry />
            <SendPureSpectrumEnquiry />
            
            <div className="toolkit row gx-5">
              <div className="col-12 col-xl-6">
              <div className={locked(ToolType.BRAND_STRATEGY) ? 'locked tool no-template' : 'tool no-template'}>
                  <div className="no-template">
                    <h2>{t('toolkit.brandStrategyTitle')}</h2>
                    {locked(ToolType.BRAND_STRATEGY) && <div className="lock"><i className="fas fa-lock"></i></div>}
                    {!brandStrategyExists() && unlocked(ToolType.BRAND_STRATEGY) && <button onClick={() => { addTool(ToolType.BRAND_STRATEGY) }}>{t('toolkit.add')}</button>}
                    {brandStrategyExists() && unlocked(ToolType.BRAND_STRATEGY) && <label className="sign">{t('toolkit.inUse')}</label>}
                  </div>
                  {hoverBlock}
                  <p className="static">
                    {t('toolkit.brandStrategyDescription')}
                  </p>
                </div>
              </div>
              <div className="col-12 col-xl-6">
                <div className="tool no-template">
                  <div className="no-template">
                    <h2>{t('toolkit.creativeBriefTitle')}</h2>
                    <button onClick={() => { addTool(ToolType.CREATIVE_BRIEF) }}>{t('toolkit.add')}</button>
                  </div>
                  <p>
                    {t('toolkit.creativeBriefDescription')}
                  </p>
                </div>
              </div>
            </div>

            <div className="toolkit row gx-5">
              <div className="col-12 col-xl-6">
                <div className="tool no-template">
                  <div className="no-template">
                    <h2>{t('toolkit.mediaBriefTitle')}</h2>
                    <button onClick={() => { addTool(ToolType.MEDIA_BRIEF) }}>{t('toolkit.add')}</button>
                  </div>
                  <p>
                    {t('toolkit.mediaBriefDescription')}
                  </p>
                </div>
              </div>
              <div className="col-12 col-xl-6">
                <div className={locked(ToolType.CREATIVE_COMPETITIVE_OVERVIEW) ? 'locked tool no-template' : 'tool no-template'}>
                  <div className="no-template">
                    <h2>{t('toolkit.creativeCompetitiveOverviewTitle')}</h2>
                    {locked(ToolType.CREATIVE_COMPETITIVE_OVERVIEW) && <div className="lock"><i className="fas fa-lock"></i></div>}
                    {unlocked(ToolType.CREATIVE_COMPETITIVE_OVERVIEW) && <button onClick={() => { addTool(ToolType.CREATIVE_COMPETITIVE_OVERVIEW) }}>{t('toolkit.add')}</button>}
                  </div>
                  {hoverBlock}
                  <p className="static">
                    {t('toolkit.creativeCompetitiveOverviewDescription')}
                  </p>
                </div>
              </div>
            </div>
            <div className="toolkit row gx-5">
              <div className="col-12 col-xl-6">
              <div className={locked(ToolType.COMPETITIVE_MAPPING) ? 'locked tool no-template' : 'tool no-template'}>
                  <div className="no-template">
                    <h2>{t('toolkit.competitiveMappingTitle')}</h2>
                    {locked(ToolType.COMPETITIVE_MAPPING) && <div className="lock"><i className="fas fa-lock"></i></div>}
                    {unlocked(ToolType.COMPETITIVE_MAPPING) && <button onClick={() => { addTool(ToolType.COMPETITIVE_MAPPING) }}>{t('toolkit.add')}</button>}
                  </div>
                  {hoverBlock}
                  <p className="static">
                    {t('toolkit.competitiveMappingDescription')}
                  </p>
                </div>
              </div>
              <div className="col-12 col-xl-6">
              <div className={locked(ToolType.SCRIPT_TEMPLATE) ? 'locked tool no-template' : 'tool no-template'}>
                  <div className="no-template">
                    <h2>{t('toolkit.scriptTemplateTitle')}</h2>
                    {locked(ToolType.SCRIPT_TEMPLATE) && <div className="lock"><i className="fas fa-lock"></i></div>}
                    {unlocked(ToolType.SCRIPT_TEMPLATE) && <button onClick={() => { addTool(ToolType.SCRIPT_TEMPLATE) }}>{t('toolkit.add')}</button>}
                  </div>
                  {hoverBlock}
                  <p className="static">
                    {t('toolkit.scriptTemplateDescription')}
                  </p>
                </div>
              </div>
            </div>

            <div className="toolkit row gx-5">
              <div className="col-12">
                <div className="tool no-template wide">
                  <div className="no-template images">
                    <h2>{t('toolkit.researchTitle')}</h2>
                    <button onClick={() => { selectResearchType() }}>{t('toolkit.add')}</button>
                  </div>
                  <div className="images row">
                    <div className="col-12 col-md-4">
                      <img alt="Roy Morgan logo" src="/app/images/research/roy-morgan-logo.png" />
                    </div>
                    <div className="col-12 col-md-4">
                      <img className="australia-post" alt="Australia Post logo" src="/app/images/research/australia-post-logo.png" />
                    </div>
                    <div className="col-12 col-md-4">
                      <img className="australia-post" alt="PureSpectrum logo" src="/app/images/research/pure-spectrum-logo.png" />
                    </div>
                  </div>
                  <p>
                    {t('toolkit.researchDescription')}
                  </p>
                </div>
              </div>
            </div>

          </div>
        </div>

      </section>
      <Footer />
    </section>
  );
}

export default ToolkitComponent;