import { Suspense, FunctionComponent } from 'react';
import { Route, Switch } from 'react-router-dom';
import './App.scss';
import { history } from './store/configure/configure';
import { ConnectedRouter } from 'connected-react-router'
import Dashboard from './components/dashboard/DashboardComponent';
import ProjectsComponent from './components/projects/ProjectsComponent';

import './i18n';
import ToolkitComponent from './components/toolkit/ToolkitComponent';
import TemplatesComponent from './components/templates/TemplatesComponent';
import { canActivateAdminRoute, canActivateRoute } from './shared/auth';
import ProjectSettingsComponent from './components/project-settings/ProjectSettingsComponent';
import CompetitorsComponent from './components/competitors/CompetitorsComponent';
import DownloadsComponent from './components/downloads/DownloadsComponent';
import ReportingComponent from './components/reporting/ReportingComponent';

type Props = {
  exact: boolean;
  path: string;
  component: FunctionComponent;
  auth: boolean;
};

const GuardedRoute: React.FC<Props> = props => {
  const { exact, path, component, auth } = props;

  if(!auth) {
    let loginScreen = `${process.env.REACT_APP_LEGACY_BASE_URL}/auth/login`;
    return (
      <Route path={path} component={() => { 
        window.location.href = loginScreen;
        return null;
      }}/>
    )
  }

  return ( exact ? <Route path={path} exact component={component} /> : <Route path={path} component={component} /> );
};

function App() {

  return (
    <ConnectedRouter history={history}>
      <Suspense fallback={  
        <div id="loader-wrapper">
			    <div id="loader"></div>
		    </div>
        }>
        <Switch>
          <GuardedRoute exact={true} path="/" component={Dashboard} auth={canActivateRoute()} />
          <GuardedRoute exact={false} path="/templates" component={TemplatesComponent} auth={canActivateRoute()} />
          <GuardedRoute exact={false} path="/toolkit" component={ToolkitComponent} auth={canActivateRoute()} />
          <GuardedRoute exact={false} path="/projects" component={ProjectsComponent} auth={canActivateRoute()} />
          <GuardedRoute exact={false} path="/reporting" component={ReportingComponent} auth={canActivateAdminRoute()} />
          <GuardedRoute exact={false} path="/project-settings" component={ProjectSettingsComponent} auth={canActivateRoute()} />
          <GuardedRoute exact={false} path="/competitors" component={CompetitorsComponent} auth={canActivateRoute()} />
          <GuardedRoute exact={false} path="/downloads" component={DownloadsComponent} auth={canActivateRoute()} />
        </Switch>
      </Suspense>
    </ConnectedRouter>
  );
}

export default App;
