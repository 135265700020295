import React, { useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import Header from '../shared/Header';
import { State } from '../../store/shared/state';
import { Status } from '../../store/shared/status';
import Footer from '../shared/Footer';
import ReportingTitle from './ReportingTitle';
import SummaryLoader from './SummaryLoader';
import ReportingSummary from './ReportingSummary';
import SubscriptionBanner from '../shared/SubscriptionBanner';
import './ReportingComponent.scss';
import { getSummary } from '../../store/reporting/actions';

export const ReportingComponent: React.FC = () => {

  const dispatch = useDispatch();

  const summaryStatus = useSelector((state: State) => state.reporting.getSummaryStatus);

  useEffect(() => {
    getSummary(dispatch);
    // eslint-disable-next-line 
  }, [])

  const isLoading = () => summaryStatus === Status.LOADING;

  const loadingBlock = isLoading() ? ( <SummaryLoader />) : null;
  const summaryBlock = !isLoading() && summaryStatus === Status.SUCCESS ? <ReportingSummary /> : null;

  return (
    <section className="container-fluid">
      <Header />
      <SubscriptionBanner />
      <section className="container container-xxxl container-body">
      <div className="row">
        <div className="col-12 col-lg-3 left-column">
        </div>
        <div className="col-12 col-lg-9 right-column">
          <ReportingTitle />
        </div>
      </div>

      <div className="row">
        <div className="col-12 col-lg-3 left-column">
        </div>
        <div className="col-12 col-lg-9 right-column">
          {loadingBlock}
          {summaryBlock}
        </div>
      </div>

      </section>
      <Footer />
    </section>
  );
}

export default ReportingComponent;