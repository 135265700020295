import * as React from "react";
import { useDispatch } from "react-redux";
import { selectProject } from "../../store/projects/actions";
import { IProject } from "../../store/projects/types";
import { hideToolMenu } from "../../store/tools/actions";

type Props = {
  project: IProject;
  selected: any | null;
};

export const Project: React.FC<Props> = ({ project, selected }) => {

  const dispatch = useDispatch();
  
  let className = selected && project.uuid['value'] === selected.uuid['value'] ? 'project active' : 'project';

  return (
    <div className={className}>
      <div className="entry" onClick={() => { dispatch(selectProject(project.uuid)); dispatch(hideToolMenu()); }}>
        <span>{project.name}</span>
      </div>
    </div>
  );
};

export default Project;
