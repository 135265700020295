import { Guid } from 'guid-typescript';
import { AxiosResponse } from 'axios';
import axios from '../../shared/http';

import { normaliseErrorCode, transformList, transformObjectFromString } from '../shared/transform';
import { IReport, IReportsResponse } from './types';

// Action creators
const listReportsLoading = () => ({ type: 'reports/list-reports-loading' });
const listReportsSuccess = (payload: any) => ({ type: 'reports/list-reports-success', payload});
const listReportsError = (payload: any) => ({ type: 'reports/list-reports-error', payload });

const deleteReportLoading = () => ({ type: 'reports/delete-report-loading' });
const deleteReportSuccess = (payload: any) => ({ type: 'reports/delete-report-success', payload});
const deleteReportError = (payload: any) => ({ type: 'reports/delete-report-error', payload });

export const generateReportLoading = () => ({ type: 'reports/generate-report-loading' });
export const generateReportSuccess = (payload: any) => ({ type: 'reports/generate-report-success', payload});
export const generateReportError = (payload: any) => ({ type: 'reports/generate-report-error', payload });
const closeGenerateReport = () => ({ type: 'reports/close-generate-report' });

export const toggleGenerateReport = () => ({ type: 'reports/toggle-generate-report' });

export const listReports = async (dispatch: any, toolId: Guid | null) => {

  if(!toolId) {
    dispatch(listReportsError(normaliseErrorCode('Error', 'projects.tool.downloads')));
    return;
  }
  
  dispatch(listReportsLoading());

  axios.get<IReportsResponse>(
    `${process.env.REACT_APP_PROJECTS_PATH}/${toolId['value']}/reports`,
    { transformResponse: (data: string) => transformList<IReport>(data, 'reports') })
    .then((response: AxiosResponse<IReportsResponse>) => {
      dispatch(listReportsSuccess(response.data));
    })
    .catch((error: any) => {
      // console.log(error);
      dispatch(listReportsError(error));
    });
};


export const deleteReport = async (dispatch: any, toolId: Guid, reportId: Guid) => {

  dispatch(deleteReportLoading());

  axios.delete<IReportsResponse>(
    `${process.env.REACT_APP_PROJECTS_PATH}/${toolId['value']}/reports/${reportId}`)
    .then((response: AxiosResponse) => {
      dispatch(deleteReportSuccess(reportId));
    })
    .catch((error: any) => {
      // console.log(error);
      dispatch(deleteReportError(error));
    });
};


export const generateReport = async (dispatch: any, projectId: Guid, toolId: Guid, data: IReport) => {

  dispatch(generateReportLoading());

  axios.post(
    `${process.env.REACT_APP_PROJECTS_PATH}/${toolId['value']}/reports/generate/${data.fileType}`, 
    null,
    { transformResponse: (data: string) => transformObjectFromString<IReport>(data) })
    .then((response: any) => {

      setTimeout(() => {
        dispatch(generateReportSuccess(response.data));
        dispatch(closeGenerateReport());
      }, 10000);
  
    })
    .catch((error: any) => {
      // console.log(error);
      dispatch(generateReportError(normaliseErrorCode(error, 'projects.report.generate')));
    });
};



export const saveReport = async (dispatch: any, toolId: Guid, type: any, data: any) => {

  dispatch(generateReportLoading());

  axios.post(
    `${process.env.REACT_APP_PROJECTS_PATH}/${toolId['value']}/reports/upload`, 
    data,
    { 
      headers: {
        'Content-Type': 'application/pdf; charset=UTF-8'
      },
      transformResponse: (data: string) => transformObjectFromString<IReport>(data) 
    })
    .then((response: any) => {

      dispatch(generateReportSuccess(response.data));
      dispatch(closeGenerateReport());
  
    })
    .catch((error: any) => {
      // console.log(error);
      dispatch(generateReportError(normaliseErrorCode(error, 'projects.report.generate')));
    });
};