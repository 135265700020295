import { Status } from "../shared/status";
import { UsersAction, UsersState } from "./types";

const initialState: UsersState = {
  users: [],
  totalUsers: 0,
  listUsersStatus: Status.IDLE,
  listUsersError: null,
};

const usersReducer = (state: UsersState = initialState, action: UsersAction): UsersState => {

  switch (action.type) {

    case 'users/list-users-loading':

      return {
        ...state,
        listUsersStatus: Status.LOADING,
        listUsersError: null
      };

    case 'users/list-users-error':

      return {
        ...state,
        listUsersStatus: Status.FAILED,
        listUsersError: action.payload
      };

    case 'users/list-users-success':

      return {
        ...state,
        users: action.payload.users,
        totalUsers: action.payload.total,
        listUsersStatus: Status.SUCCESS,
        listUsersError: null
      };

    }

    return state;
};

export default usersReducer;
