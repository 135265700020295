import * as React from "react";
import { Trans, useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import { getQueryString } from "../../shared/queryString";

type Props = {
  example: boolean;
};

export const DashboardToolkit: React.FC<Props> = ({ example }) => {

  const { t } = useTranslation();

  const history = useHistory();
  
  const goToProjects = () => history.push({ pathname: '/projects', search: getQueryString()});
  const goToToolkit = () => history.push({ pathname: '/toolkit', search: getQueryString()});

  return example ? (
    <div className="call-to-action yellow-spacemen" onClick={goToProjects}>
      <h2>{t('dashboard.toolkitHeading')}</h2>
      <h3>{t('dashboard.toolkitSubHeading')}</h3>
      <p>
        <Trans i18nKey="dashboard.toolkitBody">
          View the <strong>Example projects</strong> below to see what incredible results Brandvas is capable of. You're sure to be impressed.
        </Trans>
      </p>
    </div>
  ) : (
    <div className="call-to-action small yellow-spacemen" onClick={goToToolkit}>
      <h2>{t('dashboard.toolkitHeadingExisting')}</h2>
    </div>
  );
};

export default DashboardToolkit;
